
define('template!emailView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="mail-popup">\n    <div class="mail-popup-header">\n        <div class="header">\n            <div class="mail-head">\n                <div class="from">\n                    <div class="libelle">' +
((__t = ( _.i18n('common.from') )) == null ? '' : __t) +
':</div>\n                    <div class="from-region"></div>\n                </div>\n                <div class="to">\n                    <div class="libelle">' +
((__t = ( _.i18n('common.to') )) == null ? '' : __t) +
':</div>\n                    <input class="value" type="text" placeholder="...">\n                </div>\n                <div class="cc">\n                    <div class="libelle">' +
((__t = ( _.i18n('common.cc') )) == null ? '' : __t) +
':</div>\n                    <input class="value" type="text"/>\n                </div>\n                <div class="bcc">\n                    <div class="libelle">' +
((__t = ( _.i18n('common.bcc') )) == null ? '' : __t) +
':</div>\n                    <input class="value" type="text"/>\n                </div>\n                <div class="subject">\n                    <div class="libelle">' +
((__t = ( _.i18n('common.subject') )) == null ? '' : __t) +
':</div>\n                    <input class="value" type="text"/>\n                </div>\n            </div>\n        </div>\n        <div class="body">\n            <div class="content">\n                <div class="textarea">\n                    <textarea></textarea>\n                </div>\n\n            </div>\n        </div>\n    </div>\n    <div class="right">\n        <div class="attachments-region"></div>\n        <div class="purchaseOrders-region"></div>\n    </div>\n</div>\n\n<div class="footer">\n    <div class="left">\n        <div class="load">\n            <div class="loader-2"></div>\n            <div class="text"></div>\n        </div>\n    </div>\n    <div class="right">\n        <div class="templates-region"></div>\n        <div class="cancel-region"></div>\n        <div class="send-region"></div>\n    </div>\n</div>\n';

}
return __p
};});

