define('webshopProductsDetailBodyUrlsBodyView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'buttonView',
  'webshopProductsDetailBodyUrlsBodyDomainView',
  'entities/webshop/products'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  ButtonView,
  DomainView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'list-view',
    childView: DomainView,
    childViewOptions: function () {
      return {
        webshopConfig: this.webshopConfig,
        productModel: this.model
      };
    },

    childEvents: {
        'loader': function(view, value) {
            this.trigger('loader', value);
        }
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      App.request('webshop.config').done(_.bind(function (webshopConfig) {
        this.webshopConfig = webshopConfig;
        this.fetch();
      }, this));
    },

    fetch: function () {
      this.collection.reset();

      App.request('webshop.config').done(_.bind(function (config) {
        var locales = [];
        _.each(config.attributes, function (data) {
          locales.push(data.locale);
        });
        App.request('webshop.urls.get', 'product', this.model.get('code'), locales).done(_.bind(function (urls) {
          this.collection.add(urls);
        }, this));
      }, this));
    }
  });
});
