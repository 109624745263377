
define('template!webshopCategoriesDetailBodyCategoryBodyIndexView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="checkbox-region"></div>\n';

}
return __p
};});

