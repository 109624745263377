define('mailsMailHeaderLabelView',[
  'module',
  'windows',
  'marionette',
  'underscore',
  'template!mailsMailHeaderLabelView'
], function (
  Module,
  Windows,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'label-view',

    events: {
      'click': 'onClick'
    },

    serializeData: function () {
      var lbl = this.model.get('label');
      if(lbl) {
        return {
          value: lbl?lbl.name:''
        };
      } else {
        return {
          value: ''
        };
      }
    },

    onRender: function() {
      var lbl = this.model.get('label');
      if(lbl) {
        this.$el.css('background-color', lbl.backgroundColor);
        this.$el.css('color', lbl.textColor);
      }
    },

    onClick: function() {
      this.trigger('delete');
    }
  });
});
  
