define('webshopCategorySelectView',[
  'module',
  'backbone',
  'underscore',
  'buttonView',
  'webshopCategoriesFinderView',
  'template!webshopCategorySelectView'
], function (
  Module,
  Backbone,
  _,
  buttonView,
  CategoriesFinderView,
  Tpl
) {
  'use strict';

  Module.exports = buttonView.extend({
    template: Tpl,
    className: 'webshop-category-select-view ' + buttonView.prototype.className,

    ui: _.extend({}, buttonView.prototype.ui, {
      'searchIcon': '.search-icon',
      'removeIcon': '.remove-icon',
      'code': '.code'
    }),

    modelEvents: {
        'change': 'onRender'
    },

    initialize: function () {
      buttonView.prototype.initialize.apply(this, arguments);

      if(!this.model) {
        this.model = new Backbone.Model({
          id: '',
          code: ''
        });
      }
    },

    attributes: function () {
      return _.extend({}, buttonView.prototype.attributes.apply(this, arguments), {
        'type': 'search',
        'color': 'lightgrey'
      });
    },

    onRender: function() {
      if(this.model.get('id') === '') {
        this.ui.searchIcon.css('display', 'flex');
        this.ui.removeIcon.hide();
        this.ui.code.hide();
      } else {
        this.ui.searchIcon.hide();
        this.ui.removeIcon.css('display', 'flex');
        this.ui.code.css('display', 'flex');
        this.ui.code.html(this.model.get('code'));
        this.ui.code.attr('title', this.model.get('code'));
        this.ui.code.css('display', 'flex');
      }
    },

    onClick: function (e) {
      e.stopPropagation();
      if (!this.active)
        return;

      if(this.model.get('id') === '') {
        CategoriesFinderView.prototype.open({
          title: _.i18n('wehshop.products.list.findDefaultCategory'),
          onSelected: _.bind(function (categories) {
            if (categories.length > 0) {
              var model = new Backbone.Model({
                id: categories[0].get('id'),
                code: categories[0].get('code')
              });

              if(this.options.onChange) {
                this.options.onChange(model).done(_.bind(function() {
                    this.model.set(model.toJSON());
                }, this));
              } else {
                this.model.set(model.toJSON());
              }
            }
          }, this)
        });
      } else {
        var model = new Backbone.Model({
          id: '',
          code: ''
        });

        if(this.options.onChange) {
          this.options.onChange(model).done(_.bind(function () {
            this.model.set(model.toJSON());
          }, this));
        } else {
          this.model.set(model.toJSON());
        }
      }
    }
  });
});
  
