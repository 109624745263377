define('ordersDetailOrderPrintZoneView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'galleryView',
  'underscore',
  'settings',
  'ordersDetailOrderPrintZonesEditView',
  'ordersDetailOrderCompositionCustomPaNameView',
  'ordersDetailOrderCompositionCustomPzNameView',
  'ordersDetailOrderCompositionCustomPzSizeView',
  'template!ordersDetailOrderPrintZoneView',
  'entities/orders'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  GalleryView,
  _,
  Settings,
  EditView,
  CustomPaNameView,
  CustomPzNameView,
  CustomPzSizeView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    className: 'print-zone-view',
    template: Tpl,

    regions: {
      customPaNameRegion: '.custom-pa-name-region',
      customPzNameRegion: '.custom-pz-name-region',
      customPzSizeRegion: '.custom-pz-size-region'
    },

    ui: {
      image: '.image',
      loader: '.loader-ctn'
    },

    events: {
      'click .btn-delete': 'onDelete',
      'click .btn-edit': 'onEdit',
      'click @ui.image': 'onShowGallery'
    },

    onShow: function() {
      this.customPaNameView = new CustomPaNameView({
        model: this.options.order,
        compositionModel: this.model
      });
      this.getRegion('customPaNameRegion').show(this.customPaNameView);

      this.customPzNameView = new CustomPzNameView({
        model: this.options.order,
        compositionModel: this.model
      });
      this.getRegion('customPzNameRegion').show(this.customPzNameView);

        this.customPzSizeView = new CustomPzSizeView({
        model: this.options.order,
        compositionModel: this.model
        });
        this.getRegion('customPzSizeRegion').show(this.customPzSizeView);
    },

    serializeData: function () {
      var templateData = {};
      var pa = _.findWhere(this.options.product.get('printAreas'), {'id': this.model.get('paId')});
      if (!pa) {
        pa = _.findWhere(this.options.product.get('printAreas'), {'code': this.model.get('paCode')});
      }
      if (pa) {
        var pz = _.findWhere(pa.printZones, {'id': this.model.get('pzId')});
        if (!pz) {
          pz = _.findWhere(pa.printZones, {'imppCode': this.model.get('itCode')});
        }
        if (pz) {
          if (pz.id === this.model.get('pzId') && pz.minStrokeWidth) {
            this.minStrokeWidth = pz.minStrokeWidth;
          }

          if (pz.printerName !== this.options.order.get('productOwner') && pz.printerName !== undefined)
            templateData.productOwner = pz.printerName;
          else
            templateData.productOwner = '';
        } else {
          templateData.productOwner = '';
        }
      } else {
        templateData.productOwner = '';
      }

      templateData.printAreaName = this.model.get('paName') ? this.model.get('paName') : this.getPrintAreaCode();

      //---------------   

      templateData.print = '';

      if (this.model.get('itName')) {

        var sPrintZoneName = this.model.get('itName');
        var productPrintArea = _.findWhere(this.options.product.get('printAreas'), {'id': this.model.get('paId')});
        if (productPrintArea) {
          var productPrintZone = _.findWhere(productPrintArea.printZones, {'id': this.model.get('pzId')});

          if (productPrintZone)
            sPrintZoneName = productPrintZone.name;
        }
        if (this.model.get('customData') && this.model.get('customData').itName) {
            sPrintZoneName = this.model.get('customData').itName;
        }

        templateData.print += sPrintZoneName;
      }

      if (templateData.print !== '') {
        templateData.print += ' ';
      }

      templateData.print += '(' + this.model.get('colorType') + ')';

      //---------------
      templateData.size = this.model.get('pzWidth') + ' x ' + this.model.get('pzHeight') + ' mm';
      templateData.colorModeType = this.model.get('colorType');

      var nbrColors = 0;
      if (this.model.get('nbrColors'))
        nbrColors = this.model.get('nbrColors');

      if(nbrColors === 0 && this.model.get('usedColors'))
        nbrColors = this.model.get('usedColors').length;

      templateData.colors = nbrColors;

      if (nbrColors > 1)
        templateData.colors += ' ' + _.i18n('common.colors');
      else
        templateData.colors += ' ' + _.i18n('common.color');

      templateData.imageUrl = this.getPrintAreaImgUrl();


      return templateData;
    },

    onShowGallery: function () {
      var title = 'Printzone: ';
      title += this.options.product.get('code');
      title += ' | ' + (this.model.get('paName') ? this.model.get('paName') : this.getPrintAreaCode());

      GalleryView.prototype.open(
        'product.printzone.image:' + this.options.product.get('code') + '.' + this.model.get('poi') + this.model.get('pzId'),
        title,
        new Backbone.Model({urls: [this.getPrintAreaImgUrl()]}));
    },

    onDelete: function () {
      var value = _.findWhere(this.options.order.get('compositions'), {paId: this.model.get('paId')});

      if (value) {
        this.ui.loader.css('display', 'flex');
        App.request('order:remove-print-zone', this.options.order.get('purchaseOrderId'), value.pzId)
              .done(_.bind(function () {
          this.options.order.fetch();
        }, this));
      }
    },

    onEdit: function () {
      EditView.prototype.open({
        model: this.options.order,
        product: this.options.product,
        title: _.i18n('orders.detail.printzones.update.title'),
        printTechnicId: this.model.get('pzId'),
        nbrColors: this.model.get('nbrColors')
      });
    },

    getPrintAreaImgUrl: function () {
      var params = {};
      if (!this.model.get('useBackgroundColor')) {
        params.nobg = true;
      }
      params.productCode = this.options.order.get('productBaseCode');
      params.pac = this.model.get('paCode');
      if (this.checkStroke) {
        if (this.minStrokeWidth) {
          params.strokeWidth = this.minStrokeWidth;
          params.strokeLength = this.minStrokeWidth;
          params.strokeWidthTexts = this.minStrokeWidth;
          params.strokeLengthTexts = this.minStrokeWidth;
        }
        params.checkStroke = true;
      }

      return Settings.url('x2png', 'compositions/' + this.model.get('secId') + '/snapshot/withMeasure', params);
    },

    getPrintAreaCode: function () {
      if (Settings.get('currentCAccount').displayPaCodeLetter) {
        var letter = this.letters[parseInt(this.model.get('paCode'), 10) - 1];
        return letter ? letter : this.model.get('paCode');
      } else {
        return this.model.get('paCode');
      }
    }
  });
});
