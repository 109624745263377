define('adminNotificationsModificationView',[
  'app',
  'module',
  'underscore', 
  'tableFieldView',
  'template!adminNotificationsModificationView'
], function (
  App,
  Module,  
  _,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'admin-notifications-modification-view table-field-view',

    serializeData: function () {

      var data = {};

      data.modificationTitle = '';
      data.modificationValue = '';

      if (this.model.get('content').type === 'ORDER_STATUS_CHANGED') {
        data.modificationTitle = _.i18n('notifications.orderNewStatus');
        data.modificationValue = this.model.get('content').data.status;
      }

      if (this.model.get('content').type === 'ORDER_ASSIGNED') {
        data.modificationTitle = _.i18n('notifications.orderNewAssigment');

        if(this.model.get('content').data.userpids) {
          _.each(this.model.get('content').data.userpids, function (user) {
            if (data.modificationValue !== '')
              data.modificationValue += '<br>';

            var name= '';
            if(user.firstName)
              name += user.firstName;

            if(user.lastName) {
              if(name !== '')
                name += ' ';

              name += user.lastName;
            }

            data.modificationValue += name;
          }, this);
        }
      }

      if (this.model.get('content').type === 'ORDER_VALIDATED') {
        data.modificationTitle = '';
        data.modificationValue = _.i18n('notifications.orderValidated');
      }

      if (this.model.get('content').type === 'ORDER_INVALIDATED') {
        data.modificationTitle = '';
        data.modificationValue = _.i18n('notifications.orderInvalidated');
      }

      if (this.model.get('content').type === 'ORDER_NEW_COMMENT') {
        data.modificationTitle = '';
        data.modificationValue = _.i18n('notifications.orderNewComment');
      }

      if (this.model.get('content').type === 'ORDER_COMMENT_TAGGED') {
        data.modificationTitle = '';
        data.modificationValue = _.i18n('notifications.orderCommentTagged');
      }

      if (this.model.get('content').type === 'ORDER_COMMENT_MODIFIED_TAGGED') {
        data.modificationTitle = '';
        data.modificationValue = _.i18n('notifications.orderCommentUpdated');
      }

      if (this.model.get('content').type === 'CUSTOMER_COMMENT_TAGGED') {
        data.modificationTitle = '';
        data.modificationValue = _.i18n('notifications.customerCommentTagged');
      }

      if (this.model.get('content').type === 'ORDER_NEW_PAYMENT') {
        data.modificationTitle = '';
        data.modificationValue = _.i18n('notifications.orderNewPayment');
      }

      if (this.model.get('content').type === 'ORDER_SUPPLIER_TRACKING_ADDED') {
        data.modificationTitle = '';
        data.modificationValue = _.i18n('notifications.newSupplierTrackingAdded');
      }

      if (!this.model.get('content').type) {
        data.modificationTitle = _.i18n('notifications.newEmailFrom');
        data.modificationValue = this.model.get('content').data.from;
      }

      //------------

      if (this.model.get('content').type === 'TASK_NEW') {
        data.modificationTitle = '';
        data.modificationValue = _.i18n('notifications.newTask');
      }

      if (this.model.get('content').type === 'TASK_NEW_COMMENT') {
        data.modificationTitle = _.i18n('notifications.newTaskComment');
        data.modificationValue = this.model.get('content').text;
      }

      if (this.model.get('content').type === 'TASK_COMMENT_MODIFIED') {
        data.modificationTitle = _.i18n('notifications.taskCommentModified');
        data.modificationValue = this.model.get('content').text;
      }

      if (this.model.get('content').type === 'TASK_COMMENT_DELETED') {
        data.modificationTitle = _.i18n('notifications.deleteTaskComment');
        data.modificationValue = this.model.get('content').text;
      }

      if (this.model.get('content').type === 'TASK_DONE') {
        data.modificationTitle = '';
        data.modificationValue = _.i18n('notifications.taskDone');
      }

      return data;
    }
  });
});
