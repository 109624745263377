define('webshopProductsDetailBodyUrlsView',[
  'module',
  'settings',
  'headerFooterView',
  'webshopProductsDetailBodyUrlsBodyView',
  'webshopProductsDetailBodyUrlsFooterView',
  'template!webshopProductsDetailBodyUrlsView'
], function (
  Module,
  Settings,
  HeaderFooterView,
  BodyView,
  FooterView,
  Tpl
) {
  'use strict';

  Module.exports = HeaderFooterView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-urls-view ' + HeaderFooterView.prototype.className,
    childView: BodyView,
    childFooterView: FooterView,

    serializeData: function () {
      var data = {};
      var url = Settings.configValue('webshop.url');
      data.adminUrl = url + '/admin/default/jqadm/get/product/' + this.model.getId();
      return data;
    }
  });
});
