define('webshopProductsListCategoryView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'selectView',
  'tableFieldView',
  'modalsConfirmationView',
  'webshopCategorySelectView',
  'template!webshopProductsListCategoryView'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  SelectView,
  FieldView,
  ConfirmationView,
  CategorySelectView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-list-category-view ' + FieldView.prototype.className,

    regions: {
        categorySelectRegion: '.category-select-region'
    },

    modelEvents: {
        'change:defaultCategoryId': 'renderDefaultCategory'
    },

    onRender: function () {
      this.categorySelectView = new CategorySelectView({
        model: new Backbone.Model({
          id: this.model.get('defaultCategoryId'),
          code: this.model.get('defaultCategoryCode')
        }),
        onChange: _.bind(this.onChange, this)
      });
      this.getRegion('categorySelectRegion').show(this.categorySelectView);
    },

    renderDefaultCategory: function () {
      this.categorySelectView.model.set({
          id: this.model.get('defaultCategoryId'),
          code: this.model.get('defaultCategoryCode')
      });
    },

    onChange: function (model) {
      var defer = $.Deferred();

      if(model.get('id') === '') {
        ConfirmationView.prototype.open(
        {
          title: _.i18n('webshop.product.defaultCategory.delete.confirmation.title'),
          text: _.i18n('webshop.product.defaultCategory.delete.confirmation.text').replace('%s', this.model.get('code')),
          onConfirm: _.bind(function () {
            this.update('', defer);
          }, this)
        });
      } else
        this.update(model.get('id'), defer);

      return defer.promise();
    },

    update: function (id, defer) {
      this.categorySelectView.loading(true);
      this.model.setDefaultCategory(id).done(_.bind(function () {
        this.model.fetch().done(_.bind(function () {
          defer.resolve();
        }, this)).fail(_.bind(function () {
          defer.reject();
        }, this)).always(_.bind(function () {
            this.categorySelectView.loading(false);
        }, this));
      }, this)).fail(_.bind(function () {
        defer.reject();
        this.categorySelectView.loading(false);
      }, this));
    }
  });
});
