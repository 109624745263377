define('ordersDetailOrderStatusStatusesView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'settings',
  'marionette',
  'dateUtils',
  'rolesMixin',
  'orderUtils',
  'ordersController',
  'ordersDetailOrderStatusStatusView'
], function (
  Module,
  App,
  Backbone,
  _,
  Settings,
  Marionette,
  DateUtils,
  RolesMixin,
  OrderUtils,
  Controller,
  OrdersDetailOrderStatusStatusView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    tagName: 'select',
    className: 'orders-detail-order-status-status-view',
    childView: OrdersDetailOrderStatusStatusView,  
    
    ui: {
      'empty': '.empty'
    },

    events: {
      'change': 'onSelectChange'
    },

    modelEvents: {
      'change:commandType': 'setCollection',
      'change:status': 'setSelected'
    },

    initialize: function () {     
      this.collection = new Backbone.Collection();      
    },   

    onShow: function () {
      this.setCollection();
    },

    onRender: function () {
      this.setSelected();
    },

    setCollection: function () {
      OrderUtils.getAvailableStatuses(this.model.get('commandType')).done(_.bind(function (statuses) {
        this.collection.reset();
        this.collection.add(statuses);
        this.setSelected();
      }, this));      
    },

    setSelected: function () {
      _.each(this.children._views, _.bind(function (view) {
        if(view.model.get('statusConst') === this.model.get('status'))
          view.setSelected(true);
        else
          view.setSelected(false);
      }, this));
    },

    onSelectChange: function (e) {   
      this.model.updateStatus(e.target.value)
      .done(_.bind(function () {
        this.model.fetch();

        try {
          App.request('orders:statuses', this.model.get('commandType')).done(_.bind(function (statuses) {
            var status = null;
            _.each(statuses.models, _.bind(function (item) {
              if(item.get('statusConst') === e.target.value)
                status = item;
            }, this));

            if(status.get('params')) {
              var params = JSON.parse(status.get('params'));

              if(this.model.get('poi')) {
                var url = this.model.getMailsUrl({pageSize: 3, first: 0});

                var defer = $.Deferred();

                if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_MAILS]) {
                  $.ajax({
                    type: 'GET',
                    url: url,
                    success: _.bind(function (previousEmails) {
                      if(previousEmails && previousEmails.length > 0){
                        params.params.previousEmails = '<br><br><br>';
                        params.params.previousEmails += DateUtils.toMDYHHMMFormat(previousEmails[0].sentDate);
                        params.params.previousEmails += ' &lt;' + previousEmails[0].from[0].address + '&gt; wrote:<br><br>';
                        params.params.previousEmails += previousEmails[0].content;
                      }
                      Controller.sendMail([this.model], params);

                      defer.resolve();
                    }, this)
                  });
                } else {
                  Controller.sendMail([this.model], params);
                  defer.resolve();
                }
                return defer.promise();
              }
            }
          }, this));
        } catch (e) {
          console.log(e);
        }
      }, this))
      .fail(_.bind(function () {
        this.render();
      }, this));
    }
  });
});
