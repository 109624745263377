define('filtersBannerEditView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'events',
  'buttonView',
  'template!filtersBannerEditView'
], function(
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Events,
  ButtonView,
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,       
    className: 'filters-banner-edit-view',
    windowTitle: _.i18n('common.filters'),

    ui: {
      body: '.body',
      footer: '.footer'
    },

    regions: {
      cancelRegion: '.cancel-region',
      confirmRegion: '.confirm-region',
      resetRegion: '.reset-region'
    },

    initialize: function() {
      Events.push(this);
    },

    onDestroy: function () {
      Events.pop(this);
    },

    onShow: function() {
      this.windowView.setTitle(this.windowTitle);

      //----------------
      this.cancelView = new ButtonView({
        type: 'cancel',
        text: _.i18n('filters-banner.cancel'),
        title: _.i18n('filters-banner.cancel.title'),
        tabindex: 1
      });

      this.cancelRegion.show(this.cancelView);
      this.cancelView.on('click', _.bind(this.onCancel, this));

      //----------------
      this.resetView = new ButtonView({
        type: 'reset',
        text: _.i18n('filters-banner.reset'),
        title: _.i18n('filters-banner.reset.title'),
        tabindex: 2
      });

      this.resetRegion.show(this.resetView);
      this.resetView.on('click', _.bind(this.onReset, this));

      //----------------
      this.confirmButtonview = new ButtonView({
        type: 'confirm',
        color: 'orange',
        text: _.i18n('filters-banner.confirm'),
        title: _.i18n('filters-banner.confirm.title'),
        tabindex: 3
      });

      this.confirmRegion.show(this.confirmButtonview);
      this.confirmButtonview.on('click', _.bind(this.onConfirm, this));

      //----------------
      this.ui.body.find('[tabindex]').on('focus', _.bind(this.onInputFocus, this));
    },

    onRender: function() {},

    onConfirm: function() {
      this.trigger('confirm', this.model);
      this.windowView.close();
    },

    onCancel: function() {
      this.windowView.close();
    },

    onReset: function() {},

    onFocus: function() {
      this.bodyTabindex = 0;
      this.footerTabindex = 0;

      var tabs = this.ui.body.find('[tabindex]');

      var sortedTabs = _.sortBy(tabs, function(tab) {
        return parseInt($(tab).attr('tabindex'), 10);
      });

      var nextTab = _.find(sortedTabs, function(tab) {
        return parseInt($(tab).attr('tabindex'), 10) > this.bodyTabindex;
      }, this);

      if(nextTab) {
        this.bodyTabindex = parseInt($(nextTab).attr('tabindex'), 10);
        nextTab.focus();

        if($(nextTab).is(':input[type="text"]') ||
          $(nextTab).is(':input[type="number"]') ||
          $(nextTab).is(':input[type="date"]'))
        nextTab.select();
      }
    },

    onInputFocus: function(e) {
      this.bodyTabindex = parseInt($(e.currentTarget).attr('tabindex'), 10);
      this.footerTabindex = 0;
    },

    onKeyDown: function(e) {
      if (e.which === 13) {
        e.preventDefault();
        e.stopPropagation();
        this.onConfirm();
        return false;
      }

      if ((e.ctrlKey || e.altKey) && e.which === 8) {
        e.preventDefault();
        e.stopPropagation();
        this.onReset();
        return false;
      }

      if (e.which === 9) {
        e.preventDefault();
        e.stopPropagation();
        var bodyTabs = this.ui.body.find('[tabindex]');
        var footerTabs = this.ui.footer.find('[tabindex]');

        var sortedTabs = _.sortBy(bodyTabs, function(tab) {
          return parseInt($(tab).attr('tabindex'), 10);
        });

        var nextTab = _.find(sortedTabs, function(tab) {
          return parseInt($(tab).attr('tabindex'), 10) > this.bodyTabindex;
        }, this);

        if(nextTab)
          this.bodyTabindex = parseInt($(nextTab).attr('tabindex'), 10);

        if(!nextTab) {
          sortedTabs = _.sortBy(footerTabs, function(tab) {
            return parseInt($(tab).attr('tabindex'), 10);
          });

          nextTab = _.find(sortedTabs, function(tab) {
            return parseInt($(tab).attr('tabindex'), 10) > this.footerTabindex;
          }, this);

          if(nextTab)
            this.footerTabindex = parseInt($(nextTab).attr('tabindex'), 10);
          else {
            this.onFocus();
            return false;
          }
        }

        nextTab.focus();
        if($(nextTab).is(':input[type="text"]') ||
            $(nextTab).is(':input[type="number"]') ||
            $(nextTab).is(':input[type="date"]'))
          nextTab.select();

        return false;
      }
    }
  });  
});

