define('horizontalCollapseView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'containerView',
  'template!horizontalCollapseView'
], function (
    App,
    Module,
    Backbone,
    _,
    ContainerView,
    Tpl
) {
  'use strict';

  Module.exports = ContainerView.extend({
    template: Tpl,
    className: 'horizontal-collapse-view',
    childLeftView: null,
    childRightView: null,
    leftView: null,
    rightView: null,
    leftOpened: false,
    rightOpened: false,

    ui: {
      left: '.left',
      right: '.right',
      bodyRegion: '.body-region',
      leftCloseButton: '.left .close-button',
      rightCloseButton: '.right .close-button'
    },

    regions: {
      leftBodyRegion: '> .right > .body-region',
      rightBodyRegion: '> .right > .body-region',
      bodyRegion: '> .body-region'
    },

    events: {
      'click @ui.left': 'onLeftOpen',
      'click @ui.right': 'onRightOpen',
      'click @ui.leftCloseButton': 'onLeftClose',
      'click @ui.rightCloseButton': 'onRightClose'
    },

    onShow: function () {
      var ChildLeftView = this.getChildLeftView();
      if (ChildLeftView)
        this.leftView = new ChildLeftView(this.getChildViewOptions());
      if (this.leftView && this.getRegion('leftBodyRegion'))
        this.getRegion('leftBodyRegion').show(this.leftView);

      var ChildRightView = this.getChildRightView();
      if (ChildRightView)
        this.rightView = new ChildRightView(this.getChildViewOptions());
      if (this.rightView && this.getRegion('rightBodyRegion'))
        this.getRegion('rightBodyRegion').show(this.rightView);

      var ChildView = this.getChildView();
      if (ChildView)
        this.view = new ChildView(this.getChildViewOptions());
      if (this.view && this.getRegion('bodyRegion'))
        this.getRegion('bodyRegion').show(this.view);
    },

    onRender: function () {
      if(this.isDestroyed)
        return;

      if (this.getChildLeftView()) {
        this.ui.left.css('display', 'flex');
        if(this.leftOpened)
          this.ui.left.addClass('opened').removeClass('closed');
        else
          this.ui.left.removeClass('opened').addClass('closed');
      }

      if (this.getChildRightView()) {
        this.ui.right.css('display', 'flex');
        if(this.rightOpened)
          this.ui.right.addClass('opened').removeClass('closed');
        else
          this.ui.right.removeClass('opened').addClass('closed');
      }

    },

    getChildLeftView: function () {
      return this.childLeftView;
    },

    getChildRightView: function () {
      return this.childRightView;
    },

    getLeftView: function () {
        return this.leftView;
    },

    getRightView: function () {
        return this.rightView;
    },

    onLeftOpen: function () {
      if(this.leftOpened)
        return;

      this.leftOpened = true;
      this.onRender();
    },

    onLeftClose: function (e) {
      if(e && typeof e.stopPropagation === 'function') e.stopPropagation();

      if(!this.leftOpened)
        return;

      this.leftOpened = false;
      this.onRender();
    },

    onRightOpen: function () {
      if(this.rightOpened)
          return;

      this.rightOpened = true;
      this.onRender();
    },

    onRightClose: function (e) {
      if(e && typeof e.stopPropagation === 'function') e.stopPropagation();

      if(!this.rightOpened)
        return;

      this.rightOpened = false;
      this.onRender();
    }
  });
});
