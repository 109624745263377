define('tabsViewView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'containerView',
  'tabsViewTabsView',
  'tabsViewContentsView',
  'template!tabsViewView'
], function (
  App,
  Module,
  Backbone,
  _,
  ContainerView,
  TabsView,
  ContentsView,
  Tpl
) {
  'use strict';

  Module.exports = ContainerView.extend({
    template: Tpl,
    className: 'container-tabs-view ' + ContainerView.prototype.className,
    selectedTab: '',

    regions: {
      tabsRegion: '.tabs-region',
      bodyRegion: '.body-region'
    },

    initialize: function () {
      ContainerView.prototype.initialize.apply(this, arguments);

      if (this.options.selectedTab)
        this.selectedTab = this.options.selectedTab;

      this.collection = new Backbone.Collection(this.tabs());
    },

    tabs: function () {
      return [];
    },

    onShow: function() {
      var options = {
        viewOptions: this.getChildViewOptions(),
        collection: this.collection
      };

      var ChildView = this.getChildView();
      if (ChildView) {
        var View = ChildView.extend({childView: ContentsView});
        this.view = new View(options);
        this.getRegion('bodyRegion').show(this.view);
        this.contentsView = this.view.getView();
      } else {
        this.contentsView = new ContentsView(options);
        this.getRegion('bodyRegion').show(this.contentsView);
      }

      this.tabsView = new TabsView({collection: this.collection});
      this.getRegion('tabsRegion').show(this.tabsView);
      this.setSelectedTab(this.selectedTab);
      this.tabsView.on('selected', _.bind(this.setSelectedTab, this));
    },

    setSelectedTab: function(selectedTab) {
      this.selectedTab = selectedTab;
      this.tabsView.selectedTab = selectedTab;
      this.contentsView.selectedTab = selectedTab;

      this.collection.each(_.bind(function (model) {
        model.set('selected', model.get('name') === this.selectedTab);
        if(model.get('name') === this.selectedTab)
            this.onSelected(model);
      }, this));
    },

    onSelected: function () {}
  });
});

