define('ordersDetailSupplierEditView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'events',
  'modalsConfirmationView',
  'buttonView',
  'ordersDetailSupplierEditInvoiceView',
  'ordersDetailSupplierEditProductView',
  'modalsAlertView',
  'template!ordersDetailSupplierEditView',
  'entities/orders'
], function(
  App,
  Module,
  Backbone,
  _,
  Events,
  ModalsConfirmationView,
  ButtonView,
  DeliveryView,
  ProductView,
  AlertView,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'orders-detail-supplier-edit-view ' + ModalsConfirmationView.prototype.className,

    ui: _.extend({
      loader: '.loader-ctn',
      bodyContent: '.body-content'
    }, ModalsConfirmationView.prototype.ui),

    regions: {
      cancelRegion: '.cancel-region',
      sendButtonRegion: '.send-region',
      deliveryRegion: '.delivery-region',
      productRegion: '.product-region'
    },

    onShow: function() {
      Events.push(this);

      //----------------
      this.cancelView = new ButtonView({
        type: 'cancel',
        text: _.i18n('modals.confirmation.cancel'),
        title: _.i18n('modals.confirmation.cancel.title'),
        tabindex: 1
      });
      this.getRegion('cancelRegion').show(this.cancelView);
      this.cancelView.on('click', _.bind(this.onCancel, this));

      //----------------
      this.sendButtonView = new ButtonView({
        type: 'send-mail',
        color: 'orange',
        text: _.i18n('common.send'),
        title: _.i18n('common.send'),
        tabindex: 2
      });
      this.getRegion('sendButtonRegion').show(this.sendButtonView);
      this.sendButtonView.on('click', _.bind(this.onSend, this));

      //----------------
      this.ui.body.find('[tabindex]').on('focus', _.bind(this.onInputFocus, this));
    },

    onRender: function() {
      this.model.prepareSupplierOrder().done(_.bind(function (data) {
        this.supplierOrderModel = new Backbone.Model(data);

        this.deliveryView = new DeliveryView({
          order: this.model,
          model: this.supplierOrderModel
        });
        this.getRegion('deliveryRegion').show(this.deliveryView);

        //----------------
        this.productView = new ProductView({
          order: this.model,
          model: this.supplierOrderModel
        });
        this.getRegion('productRegion').show(this.productView);

        //----------------
        this.ui.loader.hide();
        this.ui.bodyContent.css('display', 'flex');

      }, this)).fail(_.bind(function () {
        this.ui.loader.hide();
        this.ui.bodyContent.css('display', 'flex');
      }, this));
    },

    onSend: function() {
      this.load(true);
      this.sendButtonView.enabled(false);
      this.model.sendOrderToSupplier(this.supplierOrderModel).done(_.bind(function () {
        this.sendButtonView.enabled(true);
        this.load(false);
        this.windowView.close();
        this.options.onConfirm();
      }, this)).fail(_.bind(function (error) {
        this.sendButtonView.enabled(true);
        this.load(false);

        console.log(error);
        AlertView.prototype.open('', error.responseText);
      }, this));
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'ordersDetailSupplierEditView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          title: data.title,
          width: '80%',
          height: '80%'
        });
      });
    }
  });
});
