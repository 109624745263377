/**
 * Created by BFR on 07/02/2020.
 */
define('optionMixEditItemView',['module',
        'underscore',
        'jquery',
        'marionette',
        'template!optionMixEditItemTpl'],
  function(module,
           _,
           $,
           Marionette,
           OptionMixEditItemTpl){

  'use strict';

  var OptionMixEditItemView = Marionette.ItemView.extend({
    tagName: 'tr',
    template: OptionMixEditItemTpl,

    ui: {
        inQty: '.quantity-block'
    },

    events: {
        'click .option-item'  : 'selectOption'
    },

    selectOption: function (e) {
        var cTarget = e.currentTarget,
            catId = $(cTarget).data('category-id');


        if (!$(cTarget).hasClass('selected')) {
            this.$el.find('.option-item[data-category-id="'+ catId +
                '"]').removeClass('selected active-option');
            $(cTarget).addClass('selected active-option');


            this.showChosenOption(cTarget, catId);
        }
    },
    // Display chosen options in the option selector.
    showChosenOption: function (cTarget, catId) {
        var optionId = $(cTarget).data('option-id'),
            cat = _.findWhere(this.model.get('productOptionCategories'), {
                id: catId
            }),
            opt = _.findWhere(cat.productOptions, {id: optionId}),
            optionSelector = this.$el.find('.options-selector[data' + 
                '-category-id="'+ catId +'"]'),
            $color = optionSelector.find('.chosen-color'),
            $text = optionSelector.find('.chosen-option-name');

        $color.removeClass('colorClass2').removeClass('colorClass3')
            .removeClass('colorClass4');
        $color.css('border-color', '');
        if ($(cTarget).hasClass('color')) {
            $color.css('backgroundColor', opt.color1);
            $color.show();

            if (opt.color1 === '#ffffff') {
                $color.addClass('color-white');
            } else {
                $color.removeClass('color-white');
            }

            if (opt.color1 !== -1 && opt.color2 !== -1 && 
                opt.color3 === -1 && opt.color4 === -1) { 
                $color.addClass('colorClass2');
                $color.css('border-color', 'transparent transparent ' + 
                    opt.color2 + ' transparent');
            } else if (opt.color1 !== -1 && opt.color2 !== -1 && 
                opt.color3 !== -1 && opt.color4 === -1) {
                $color.addClass('colorClass3');
                $color.css('border-color', 'transparent transparent ' + 
                    opt.color2 + ' ' + opt.color3);
            } else if (opt.color1 !== -1 && opt.color2 !== -1 && 
                opt.color3 !== -1 && opt.color4 !== -1) {
                $color.addClass('colorClass4');
                $color.css('border-color', 'transparent ' + opt.color2 + 
                    ' ' + opt.color3 + ' ' + opt.color4);
            } else if (opt.color1 !== -1 && opt.color2 === -1 && 
                opt.color3 === -1 && opt.color4 === -1) {
                $color.css('background-color', opt.color1);
            }
        } else {
            $color.hide();
        }
        $text.text(opt.name);
        _.each(this.model.get('mixedOptions'), function (item) {
            var mixOpt = _.findWhere(item.options, {catId: opt.catId});

            if (mixOpt) {
                mixOpt = _.extend(mixOpt, opt);
            }
        });
        cat.selectedOption = opt;
    },

    // Disable option selector while configurator is being loaded.
    disableOptionsSelector: function () {
        this.$el.find('.options-selector').addClass('disabled');
    },

    // Anable option selector after configurator finished loading.
    enableOptionsSelector: function () {
        this.$el.find('.options-selector').removeClass('disabled');
    },

    getMixConfiguration: function () {
        var mix = {
            quantity: Number(this.ui.inQty.val()),
            options: []
        };
        var options = this.$el.find('.selected');
        _.each(options, function(option) {
            mix.options.push($(option).data('option-id'));
        });
        return mix;
    }

  });

  module.exports = OptionMixEditItemView;
});
