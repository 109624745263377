/**
 * Created by Matteo on 19/07/2015.
 */
define('router',[
  'require',
  'exports',
  'jquery',
  'module',
  'logger',
  'settings',
  'marionette',
  'app',
  'loginMain',
  'autoLoginMain',
  'registerMain',
  'forgotMain',
  'resetMain',
  'thankYouMain',
  'selectCaccountMain',
  'contentMain',
  'sidebarMain',
  'pluginMain',
  'productsMain',
  'pimMain',
  'productsSupplierMain',
  'timetrackerMain',
  'caccountsMain',
  'cataloguesMine',
  'cataloguesShared',
  'ordersMainRouter',
  'webshop',
  'tasksRouter',
  'mailsRouter',
  'customersMain',
  'pricesMain',
  'personalizeMain',
  'mailingMain',
  'myWebSiteMain',
  'vectorMain',
  'graphicalRequestAllInOneMain',
  'graphicalRequestKanbanMain',
  'fileConversionMain',
  'removeBackgroundMain',
  'superAdminMain',
  'adminMain',
  'translationsRouter',
  'invitationsRouter',
  'phoneMainRouter',
  'orderStatusMainRouter'
], function (
  require,
  exports,
  $,
  module,
  Logger,
  Settings,
  Marionette,
  App
) {
    'use strict';

    var Router = Marionette.AppRouter.extend({
      appRoutes: {
        '': 'showIndex',
        'unauthorized': 'showUnauthorized'        
      },    

      initialize: function () {
        $(window).ajaxError(function (event, request) {
          switch (request.status) {
            //User doesn't have the correct roles
            case 403:
              Settings.set('lastUrlPageCookie', null);
              App.trigger('unauthorized:show');
              break;
            //The session has expired
            case 401:
              Settings.set('lastUrlPageCookie', App.getCurrentRoute());
              App.clearAuthCookieValues();
              App.trigger('login:show');
              break;
            case 503:
              window.location.href = Settings.serverUrl + 'server-unavailable.html';
              break;
            default:
              Logger.log(arguments);
          }
        });
      }
    });

    var API = {

      showUnauthorized: function () {
        require(['unauthorizedShowController'], function (ShowController) {
          ShowController.showUnauthorized();
        });
      },

      showIndex: function () {
        require(
          ['contentShowController', 'indexController'],
          function (ContentShowController, IndexController) {
            ContentShowController.showContent().done(function () {
              IndexController.show();
            }).fail(function () {
              App.trigger('login:show');
            });
          }
        );
      }
    };

    App.on('unauthorized:show', function () {
      App.navigate('unauthorized');
      API.showUnauthorized();
    });

    App.on('index:show', function () {
      App.navigate('');
      API.showIndex();
    });

    App.on('before:start', function () {
      new Router({
        controller: API
      });
    });

    module.exports = Router;
  });

