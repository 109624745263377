define('mailsMailRightCollapsedAttachmentView',[
  'module',
  'windows',
  'marionette',
  'underscore',
  'template!mailsMailRightCollapsedAttachmentView',
  'emailAttachmentDefaultView',
  'emailAttachmentImageView',
  'emailAttachmentPdfView'
], function (
  Module,
  Windows,
  Marionette,
  _,
  Tpl,
  DefaultView,
  ImageView,
  PdfView
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'attachment-view',
    
    ui: {
      name: '.name',
      remove: '.delete',
      reload: '.reload',
      pdfCanvas: '.pdf-canvas',
      image: '.image',
      loading: '.loading'
    },

    regions: {
      viewRegion: '.view-region'
    },

    serializeData: function () {
      return {
        name: this.model.get('fileName'),
        url: this.options.mailModel.getDowloadUrl(this.options.mailSecId, this.model.get('fileName')),
        fileName: this.model.get('fileName')
      };
    },

    onShow: function() {

      var options = {
        model: this.model,
        url: this.options.mailModel.getDowloadUrl(this.options.mailSecId, this.model.get('fileName')),
        fileName: this.model.get('fileName'),
        size: this.model.get('size')
      };

      if(this.model.get('contentType') === 'application/pdf' || this.model.get('contentType') === 'application/octet-stream')
        this.view = new PdfView(options);

      var imageTypes = [
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/x-png',
          'image/gif'
      ];

      if (imageTypes.includes(this.model.get('contentType')))
        this.view = new ImageView(options);

      if(!this.view)
        this.view = new DefaultView(options);

      this.getRegion('viewRegion').show(this.view);

      this.view.on('click', _.bind(function () {
        if (options.url)
          window.open(options.url, '_blank');
      }, this));

      this.fetch().done(_.bind(function () {
        this.trigger('loaded');
      }, this));
    },

    fetch: function() {
      var defer = $.Deferred();
      if(this.model.get('loaded') === true)
        return defer.resolve();
      else {
        if(this.model.get('url')) {
          var req = new XMLHttpRequest();
          req.addEventListener('load', _.bind(function (result) {
            if (result.currentTarget.status === 200) {
              this.model.set('arrayBuffer', result.currentTarget.response);
              this.model.set('base64', this.arrayBufferToBase64(this.model.get('arrayBuffer')));
              this.model.set('content-disposition', 'attachment; filename=' + this.model.get('name'));
              this.model.set('loaded', true);
            }
            defer.resolve();
          }, this));
          req.open('GET', this.model.get('url'));
          req.responseType = 'arraybuffer';
          req.send();
        }
      }
      return defer.promise();
    },

    arrayBufferToBase64: function (buffer) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }
  });
});
  
