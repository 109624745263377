define('webshopCategoriesDetailBodyUrlsBodyView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'webshopCategoriesDetailBodyUrlsBodyDomainView',
  'entities/webshop/categories',
  'entities/webshop/webshop'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  DomainView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'body-view',
    childView: DomainView,
    childViewOptions: function () {
      return {
        webshopConfig: this.webshopConfig,
        categoryModel: this.model
      };
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      App.request('webshop.config').done(_.bind(function (webshopConfig) {
        this.webshopConfig = webshopConfig;
        this.fetch();
      }, this));
    },

    fetch: function () {
      this.collection.reset();

      var locales = [];
      _.each(this.webshopConfig.attributes, function (data) {
        locales.push(data.locale);
      });
      App.request('webshop.urls.get', 'category', this.model.get('code'), locales).done(_.bind(function (urls) {
        this.collection.add(urls);
      }, this));
    }
  });
});
