define('customersDetailBodyProfileView',[
  'app',
  'module',
  'windows',
  'customersPopupEditView',
  'underscore',
  'jquery',
  'settings',
  'marionette',
  'dateUtils',
  'customerUtils',
  'bootbox',
  'buttonView',
  'customersDetailBodyProfileInfosView',
  'customersDetailBodyProfileAddressesView',
  'customersDetailBodyProfileSirenView',
  'template!customersDetailBodyProfileView'
], function (
  App,
  Module,
  Windows,
  EditView,
  _,
  $,
  Settings,
  Marionette,
  DateUtils,
  CustomerUtils,
  Bootbox,
  ButtonView,
  InfosView,
  AddressesView,
  SirenView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'customers-detail-profile-view',

    regions: {
      infosRegion: '.infos-region',
      addressesRegion: '.addresses-region',
      sirenRegion: '.siren-region',
      editButtonRegion: '.edit-button-region'
    },

    onShow: function () {
      this.infosView = new InfosView({
        model: this.model,
        countries: this.options.countries
      });
      this.getRegion('infosRegion').show(this.infosView);

      //-------------------
      this.addressesView = new AddressesView({
        model: this.model,
        countries: this.options.countries
      });
      this.getRegion('addressesRegion').show(this.addressesView);

      //-------------------
      this.sirenView = new SirenView({
        model: this.model
      });
      this.getRegion('sirenRegion').show(this.sirenView);

      //-------------------
      this.editButtonView = new ButtonView({
        type: 'edit',
        color: 'orange',
        text: _.i18n('common.edit'),
        title: _.i18n('common.edit')
      });
      this.getRegion('editButtonRegion').show(this.editButtonView);
      this.editButtonView.on('click', _.bind(this.onEdit, this));
    },

    onEdit: function () {
      Windows.open({
        view: _.bind(function () {
          return new EditView({
            customerModel: this.model,
            newItem: false,
            countries: this.options.countries.toJSON(),
            onUserSelected: function () {}
          });
        }, this),
        id: 'customer:edit:' + this.model.get('secId'),
        title: _.i18n('customers.edit') + ' | ' + this.model.get('name'),
        configName: 'customerEdit'
      });
    }
  });
});
