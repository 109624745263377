define('webshopLandingpagesDetailBodyUrlsFooterView',[
  'app',
  'module',
  'marionette',
  'buttonView',
  'modalsConfirmationView',
  'modalsAlertView',
  'underscore',
  'template!webshopLandingpagesDetailBodyUrlsFooterView'
], function (
  App,
  Module,
  Marionette,
  ButtonView,
  ConfirmationView,
  AlertView,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'footer-view',

    regions: {
      refreshButtonRegion: '.refresh-button-region',
      updateButtonRegion: '.update-button-region'
    },

    onShow: function() {
      this.refreshButtonView = new ButtonView({
        type: 'reload',
        color: 'lightgrey',
        title: _.i18n('common.refresh')
      });
      this.getRegion('refreshButtonRegion').show(this.refreshButtonView);
      this.refreshButtonView.on('click', _.bind(this.onRefresh, this));

      //---------------------------
      this.updateButtonView = new ButtonView({
        type: 'save',
        color: 'red',
        text: _.i18n('common.update')
      });
      this.getRegion('updateButtonRegion').show(this.updateButtonView);
      this.updateButtonView.on('click', _.bind(this.onUpdate, this));
    },

    onUpdate: function () {
      ConfirmationView.prototype.open(
      {
        title: _.i18n('webshop.urls.update.confirmation.title'),
        text: _.i18n('webshop.urls.update.confirmation.text').replace('%s', this.model.get('code')),
        onConfirm: _.bind(function () {
          this.updateButtonView.loading(true);
          App.request('webshop.urls.updateAll', 'landingpage', this.model.get('code'))
          .done(_.bind(function (response) {
            var message = '<div class="webshop-landingpages-detail-body-urls-view-update-alert">';
            var hasAlert = false;
            _.each(response, function (data) {
              if (data.error !== undefined) {
                hasAlert = true;
                message += '<div class="webshop-landingpages-detail-body-urls-view-update-alert-item">';
                message += '<div class="locale">' + data.locale + ':</div> ';
                message += '<div class="message">' + _.i18n('webshop.landingpage.urls.update.error.' + data.error) + '</div>';
                message += '</div>';
              }
            }, this);

            message += '</div>';

            if (hasAlert)
              AlertView.prototype.open('', message);

            this.onRefresh();
          }, this)).fail(_.bind(function (response) {
            if (response.message)
              AlertView.prototype.open('', response.message);
          }, this)).always(_.bind(function () {
            this.updateButtonView.loading(false);
          }, this));
        }, this)
      });
    },

    onRefresh: function () {
      this.refreshButtonView.loading(true);
      App.request('webshop.config').done(_.bind(function (config) {
        var locales = [];
        _.each(config.attributes, function (data) {
          locales.push(data.locale);
        });
        App.request('webshop.urls.get', 'landingpage', this.model.get('code'), locales)
        .done(_.bind(function (urls) {
          this.options.parentView.getView().collection.reset(urls);
        }, this)).always(_.bind(function () {
          this.refreshButtonView.loading(false);
        }, this));
      }, this));
    }
  });
});
