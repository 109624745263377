define('headerFooterView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'containerView',
  'template!headerFooterView'
], function (
    App,
    Module,
    Backbone,
    _,
    ContainerView,
    Tpl
) {
  'use strict';

  Module.exports = ContainerView.extend({
    template: Tpl,
    className: 'header-footer-view',
    childHeaderView: null,
    childFooterView: null,
    headerView: null,
    footerView: null,

    ui: {
      headerRegion: '.header-region',
      bodyRegion: '.body-region',
      footerRegion: '.footer-region'
    },

    regions: {
      headerRegion: '.header-region',
      bodyRegion: '.body-region',
      footerRegion: '.footer-region'
    },

    onShow: function () {
      var ChildHeaderView = this.getChildHeaderView();
      if (ChildHeaderView) {
        this.headerView = new ChildHeaderView(this.getChildViewOptions());
        this.getRegion('headerRegion').show(this.headerView);
        this.ui.headerRegion.css('display', 'flex');
      }

      var ChildView = this.getChildView();
      if (ChildView) {
        this.view = new ChildView(this.getChildViewOptions());
        this.getRegion('bodyRegion').show(this.view);
        this.ui.bodyRegion.css('display', 'flex');
      }

      var ChildFooterView = this.getChildFooterView();
      if (ChildFooterView) {
        this.footerView = new ChildFooterView(this.getChildViewOptions());
        this.getRegion('footerRegion').show(this.footerView);
        this.ui.footerRegion.css('display', 'flex');
      }
    },

    getChildHeaderView: function () {
      return this.childHeaderView;
    },

    getChildFooterView: function () {
      return this.childFooterView;
    },

    getHeaderView: function () {
      return this.headerView;
    },

    getFooterView: function () {
      return this.footerView;
    }
  });
});
