define('buttonView',[
  'module',
  'underscore',
  'events',
  'inputView',
  'template!buttonView'
], function (
  Module,
  _,
  Events,
  InputView,
  Tpl
) {
  'use strict';

  Module.exports = InputView.extend({
    template: Tpl,
    className: 'button-view ' + InputView.prototype.className,

    attributes: function () {
        return _.extend({}, InputView.prototype.attributes.apply(this, arguments), {
          'title': this.options.title || this.options.text || null,
          'type': this.options.type || null,
          'color': this.options.color || null
        });
    },

    ui: {
      'icon': '.icon',
      'loader': '.loader-2'
    },

    events: {
      'click': 'onClick',
      'dblclick': 'onClick',
      'focus': 'onFocus',
      'blur': 'onBlur',
      'mousedown': 'onMouseDown'
    },

    initialize: function () {
      this.focused = false;
      this.active = true;

      if (this.options.enabled === false)
        this.active = false;

      this.enabled(this.active);
    },

    onDestroy: function () {
      Events.pop(this);
    },

    serializeData: function () {
      var data = {};

      data.type = '';
      if (this.options.type)
        data.type = this.options.type;

      data.text = '';
      if (this.options.text)
        data.text = this.options.text;

      data.icon = '';
      if (this.options.icon)
        data.icon = this.options.icon;

      return data;
    },

    enabled: function (value) {
      this.active = value;
      if (!this.active)
        this.$el.addClass('disabled');
      else
        this.$el.removeClass('disabled');
    },

    onClick: function (e) {
      e.stopPropagation();
      if (!this.active)
        return;

      this.trigger('click', this);
    },

    onFocus: function () {
      Events.push(this);
      this.focused = true;
    },

    onBlur: function () {
      Events.pop(this);
      this.focused = false;
    },

    onMouseDown: function (e) {
      e.stopPropagation();
    },

    onKeyDown: function (e) {
      if ((e.which === 13 || e.which === 32) && this.focused) {
        e.preventDefault();
        e.stopPropagation();
        this.trigger('click', this);
        return false;
      }
    },

    loading: function (value) {
      if (value) {
        this.ui.icon.hide();
        this.ui.loader.css('display', 'flex');
      }
      else {
        this.ui.loader.hide();
        this.ui.icon.css('display', 'flex');
      }
    }
  });
});
  
