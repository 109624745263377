
define('template!webshopCategoriesDetailBodyImagesTypeView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="top-banner">\n  <div class="left">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\n  <div class="right">\n    <div class="add-button-region"></div>\n  </div>\n</div>\n<div class="list-region"></div>';

}
return __p
};});

