define('mailsMailHeaderLabelsView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'marionette',
  'mailsMailHeaderLabelView',
  'mailsMailHeaderLabelsAddView',
  'template!mailsMailHeaderLabelsView'
], function (
  Module,
  App,
  Backbone,
  _,
  Marionette,
  LabelsView,
  AddView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'labels-view',
    childView: LabelsView,
    childViewContainer: '.labels-container',

    childEvents: {
      'delete': 'onDeleteLabel'
    },

    ui: {
      addButton: '.add',
      markReadButton: '.mark-read',
      markUnreadButton: '.mark-unread'
    },

    modelEvents: {
      'change': 'fetch'
    },

    events: {
      'click @ui.addButton': 'onAddLabel',
      'click @ui.markReadButton': 'onMarkRead',
      'click @ui.markUnreadButton': 'onMarkUnread'
    },

    initialize: function() {
        this.collection = new Backbone.Collection();
        this.fetch();
        this.readTimer = null;
    },

    fetch: function() {
      this.collection.reset();

      _.each(this.model.get('labels'), function(label) {
        this.collection.add(new Backbone.Model({label: label}));
      }, this);

      // Do not mark read automatically if user has just marked it manually
      this.shouldMarkReadAutomatically = this.model.get('shouldMarkReadAutomatically') === undefined;

      // Provide the template with a flag to show the unread icon
      this.hasUnreadLabel = (this.model.get('labels') &&
         this.model.get('labels').some(function(label) {
              return label.id === 'UNREAD';
          })) || false;
    },

    serializeData: function () {
      return {
        isUnread: this.hasUnreadLabel
      };
    },

    onAddLabel: function() {
      AddView.prototype.open({
        model: this.model,
        mailSecId: this.options.mailSecId
      });
    },

    onDeleteLabel: function(view) {
      this.model.removeLabel(this.options.mailSecId, view.model.get('label')).done(_.bind(function() {
        var labels = this.model.get('labels').slice();

        var newLabels = labels.filter(function(label) {
          return label.id !== view.model.get('label').id;
        });

        this.model.set('labels', newLabels);
      }, this));
    },

    startReadTimer: function() {
      // If the user reads the e-mail for 10 seconds, mark it as read automatically
      // except if it has just been marked as unread manually.
      if (this.hasUnreadLabel && this.shouldMarkReadAutomatically === true) {
        this.readTimer = setTimeout(_.bind(function() {
          this.onMarkRead();
        }, this), 10000); // 10 seconds
      }
    },
    clearReadTimer: function() {
      if (this.readTimer) {
        clearTimeout(this.readTimer);
        this.readTimer = null;
      }
    },
    onRender: function() {
        this.startReadTimer();
    },
    onBeforeDestroy: function() {
        this.clearReadTimer();
    },

    onMarkRead: function() {
        this.clearReadTimer();
        this.model.removeLabel(this.options.mailSecId, {id:'UNREAD'})
            .done(_.bind(function() {
                this.model.set('labels', this.model.get('labels').filter(function(label) {
                    return label.id !== 'UNREAD';
                }));
                this.render();
            }, this));
    },

    onMarkUnread: function() {
      this.model.addLabel(this.options.mailSecId, this.model.get('folder'),{id:'UNREAD'})
          .done(_.bind(function() {
              this.model.set('labels', this.model.get('labels').concat({id:'UNREAD', name: 'UNREAD'}));
              // This will prevent the automatic mark read
              this.model.set('shouldMarkReadAutomatically', false);
              this.render();
          }, this));
    }
  });
});
