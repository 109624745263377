define('emailAttachmentImageView',[
  'module',
  'marionette',
  'underscore',
  'pdfView',
  'template!emailAttachmentImageView'
], function (
  Module,
  Marionette,
  _,
  PdfView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'email-attachment-preview-image-view',

    ui: {
      image: '.image-ctn img',
      loader: '.loader-2',
      error: '.error'
    },

    modelEvents: {
      'change:base64': 'render',
      'change:error': 'render'
    },

    events: {
      'click': 'onClick'
    },
    
    serializeData: function () {
      var src = '';
      var name = '';
      if(this.model.get('base64')) {
        src = 'data:image/png;base64, ' + this.model.get('base64');
      }
      else if(this.options.url){
        src = this.options.url;
      }

      if(this.options.fileName){
        name = this.options.fileName;
      }

      return {
        src: src,
        name: name
      };
    },

    onRender: function () {
      if(this.options.zoom === true)
        this.$el.addClass('zoom');

      if(this.model.get('base64') || this.options.url) {
        this.ui.image.css('display', 'flex');
        this.ui.loader.hide();
      }

      if (this.model.get('error')) {
        this.ui.loader.hide();
        this.ui.error.css('display', 'flex');
      }

      if (this.model.get('loaded') === false)
        this.ui.loader.css('display', 'flex');
      else
        this.ui.loader.hide();
    },

    onClick: function () {
      this.trigger('click');
    }
  });
});
  
