define('ordersProductTypeSelectorView',[
  'app',
  'module',
  'underscore',
  'modalsConfirmationView',
  'buttonView',
  'template!ordersProductTypeSelectorView'
], function(
  App,
  Module,
  _,
  ModalsConfirmationView,
  ButtonView,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'orders-product-type-selector-view ' + ModalsConfirmationView.prototype.className,

    regions: {
      cancelRegion: '.cancel-region',
      pimProductButtonRegion: '.pim-product-button-region',
      compuzzProductButtonRegion: '.compuzz-product-button-region'
    },

    onShow: function() {
      this.cancelView = new ButtonView({
        type: 'cancel',
        text: _.i18n('modals.confirmation.cancel'),
        title: _.i18n('modals.confirmation.cancel.title'),
        tabindex: 1
      });
      this.getRegion('cancelRegion').show(this.cancelView);
      this.cancelView.on('click', _.bind(this.onCancel, this));

      //----------------
      this.pimProductButtoView = new ButtonView({
        type: 'confirm',
        color: 'orange',
        text: _.i18n('orders.product-type-selector.pim-product-button'),
        tabindex: 2
      });
      this.pimProductButtonRegion.show(this.pimProductButtoView);
      this.pimProductButtoView.on('click', _.bind(this.onPimProduct, this));

      //----------------
      this.compuzzProductButtoView = new ButtonView({
        type: 'confirm',
        color: 'orange',
        text: _.i18n('orders.product-type-selector.compuzz-product-button'),
        tabindex: 3
      });
      this.compuzzProductButtonRegion.show(this.compuzzProductButtoView);
      this.compuzzProductButtoView.on('click', _.bind(this.onCompuzzProduct, this));

      //----------------
      this.ui.body.find('[tabindex]').on('focus', _.bind(this.onInputFocus, this));
    },

    onPimProduct: function() {
      this.windowView.close();
      this.options.onConfirm(true);
    },

    onCompuzzProduct: function() {
      this.windowView.close();
      this.options.onConfirm(false);
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'ordersProductTypeSelectorView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          modal: true,
          maxWidth: 800
        });
      });
    }
  });
});
