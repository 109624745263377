define('headerPhoneView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',   
  'plivoUtils',
  'imageUtils',
  'bootbox',
  'phoneParserView',
  'googlePhoneUtil',
  'template!headerPhoneView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  PlivoUtils,
  ImageUtils,
  Bootbox,
  PhoneParserView,
  GooglePhoneUtil,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'phone-view',  

    ui: {
      phone: '.phone',
      status: '.status',
      users: '.users',
      numberPanel: '.number-panel',
      numberPanelPhoneNumber: '.number-panel .phone-number',
      phoneNumberButton: '.number-panel .numbers .item',
      phoneNumber: '.number-panel .phone-number',
      numberPanelCallButton: '.number-panel .call-button',
      validationPhoneMsg: '.number-panel .validate-phone-message',
      callingPhoneNumber: '.calling .phone-number .number',
      callingInIcon: '.calling .in-out .in',
      callingOutIcon: '.calling .in-out .out',
      callingHangupButton: '.calling .hangup',
      callingForwardButton: '.calling .forward',
      callingMutedButton: '.calling .muted',
      callingUnmutedButton: '.calling .unmuted',
      callingKeyboardButton: '.calling .keyboard-pannel',
      incomingCallPhoneNumber: '.incoming-call .phone-number .number',
      incomingCallRejectButton: '.incoming-call .reject',
      incomingCallIgnoreButton: '.incoming-call .ignore',
      incomingCallAnswerButton: '.incoming-call .answer'
    },

    events: {
      'click @ui.phone': 'onOpenNumberPanel',
      'click @ui.phoneNumberButton': 'onPhoneNumberClick',
      'paste @ui.phoneNumber': 'onPhoneNumberParse',
      'click @ui.numberPanelCallButton': 'onPhoneCall',
      'click @ui.callingHangupButton': 'onHangup',
      'click @ui.callingMutedButton': 'onUnmute',
      'click @ui.callingUnmutedButton': 'onMute',
      'click @ui.callingKeyboardButton': 'openCallingNumberPanel',
      'click @ui.callingForwardButton': 'onForward',
      'click @ui.incomingCallIgnoreButton': 'onIgnore',
      'click @ui.incomingCallRejectButton': 'onReject',
      'click @ui.incomingCallAnswerButton': 'onAnswer'
    },

    onShow: function () {
    
       this.refreshPlivoUsers(true);
       PlivoUtils.eventCall.push(_.bind(function () {         
         this.refreshPlivoUsers(false);
       }, this));

       App.on('phone:update', _.bind(function (value) {      

        if(value.status === 'phone') {
          this.$el.attr('data-status', 'phone');
          this.closeNumberPanel();
        }
        
        if(value.status === 'calling') {
          this.$el.attr('data-status', 'calling');

          if(value.direction === 'in') {
            this.ui.callingInIcon.hide();    
            this.ui.callingOutIcon.show(); 
          }
  
          if(value.direction === 'out') {
            this.ui.callingInIcon.show();    
            this.ui.callingOutIcon.hide(); 
          }

          //this.ui.callingForwardButton.hide();
          this.closeNumberPanel();          

          this.ui.callingMutedButton.removeClass('activated');
          this.ui.callingUnmutedButton.addClass('activated');

          this.ui.callingPhoneNumber.html(value.phoneNumber);   
        }     
        
        if(value.status === 'incoming-call') {
          this.$el.attr('data-status', 'incoming-call');      
                    
          this.ui.incomingCallPhoneNumber.html(value.phoneNumber);   
        }
      }, this));    
      
      App.on('phone:calling:mute', _.bind(function (value) {    
        if(value) {
          this.ui.callingMutedButton.addClass('activated');
          this.ui.callingUnmutedButton.removeClass('activated');
        } else {
          this.ui.callingMutedButton.removeClass('activated');
          this.ui.callingUnmutedButton.addClass('activated');
        } 
      }, this));

      App.on('phone:numbering', _.bind(function (value) {
        if(value.country) {
          var PNF = GooglePhoneUtil.PhoneNumberFormat;
          var phoneUtil = GooglePhoneUtil.PhoneNumberUtil.getInstance();

          var country = 'BE';

          var gglPhone = phoneUtil.parse(value.phoneNumber, country);
          if (phoneUtil.isValidNumberForRegion(gglPhone, country)) {
            this.ui.validationPhoneMsg.hide();
            this.ui.phoneNumber.val(phoneUtil.format(gglPhone, PNF.E164));
          } else {
            this.ui.phoneNumber.val(phoneUtil.format(gglPhone, PNF.E164));
          }
        } else
          this.ui.phoneNumber.val(value.phoneNumber);

        this.openNumberPanel();
      }, this));

      $(document).on('click', _.bind(function(event) {
        //if(this.ui.phone && this.ui.phone.hasClass('activated')) {
          if (!event.target.closest('.number-panel') && !event.target.closest('.keyboard-pannel'))
            this.closeNumberPanel();
      }, this));

      $(document).on('keydown', _.bind(function(event) {
        if (event.keyCode === 27)
          if (!event.target.closest('.number-panel') && !event.target.closest('.keyboard-pannel'))
              this.closeNumberPanel();
      }, this));
    },
  
    onRender: function () { 
      this.$el.attr('data-status', 'phone');
      //this.ui.incomingCallPhoneNumber.html('sdfsdfsdf');   
    },

    onOpenNumberPanel: function(e) {
      e.stopPropagation();

      if(this.ui.phone.hasClass('activated')) {
        this.closeNumberPanel();
      } else {        
        this.openNumberPanel();      
      }     
    },

    openNumberPanel: function() {
      this.ui.validationPhoneMsg.hide();
      this.ui.numberPanelPhoneNumber.show();
      this.ui.numberPanelCallButton.addClass('activated');

      this.ui.numberPanel.addClass('activated');
      this.ui.phone.addClass('activated');
      this.ui.numberPanelPhoneNumber.select().focus();
    },

    openCallingNumberPanel: function() {
      this.ui.validationPhoneMsg.hide();
      this.ui.numberPanelPhoneNumber.hide();
      this.ui.numberPanelCallButton.removeClass('activated');

      this.ui.numberPanel.addClass('activated');  
    },

    closeNumberPanel: function() {
      if (this.ui.numberPanel && this.ui.numberPanel.removeClass) {
        this.ui.numberPanel.removeClass('activated');
      }
      if (this.ui.phone && this.ui.phone.removeClass) {
        this.ui.phone.removeClass('activated');
      }
    },

    onPhoneNumberClick: function (event) {        

      if(this.$el.attr('data-status') === 'phone')
        this.addPhoneNumber($(event.currentTarget).data('id'));
      
      if(this.$el.attr('data-status') === 'calling')
        this.dialPhoneNumberClick(event);      
    },

    onPhoneNumberParse: function(e) {
      e.preventDefault();
      var value = (e.originalEvent.clipboardData || window.clipboardData).getData('text');

      PhoneParserView.prototype.parse({
        number: value,
        onParsed: _.bind(function (value) {
          this.ui.phoneNumber.val(value);
        }, this)
      });
    },

    addPhoneNumber: function (value) {    
      var phoneNumber = this.ui.phoneNumber.val();     
      this.ui.phoneNumber.val(phoneNumber + value);
    },

    dialPhoneNumberClick: function(event) {      
      PlivoUtils.dtmf($(event.currentTarget).data('id'));
    },

    onPhoneCall: function() {      
      var regex = /^\+(?:[\d]*)$/;
      var value = this.ui.phoneNumber.val();
      value = value.replace(/\s/g, '');
      value = value.replaceAll('.', '');
      value = value.replaceAll('/', '');
      if (regex.test(value)) {
        this.ui.validationPhoneMsg.hide();
        PlivoUtils.callNumber(value);
      } else {
        this.ui.validationPhoneMsg.show();
      }
    },

    onHangup: function() {
      PlivoUtils.hangup();
    },

    onMute: function() {
      PlivoUtils.mute();
    },

    onUnmute: function() {
      PlivoUtils.unmute();
    },

    onIgnore: function() {
      PlivoUtils.ignore();
    },

    onReject: function() {
      PlivoUtils.reject();
    },

    onAnswer: function() {
      PlivoUtils.answer();
    },

    onForward: function () {
      PlivoUtils.findConnectedUsers().then(function(users) {
        var select = '<select class="forward-ddl">';
        for (var i = 0 ; i < users.length ; i++) {
          var user = users[i];
          select += '<option value="'+user.plivoUser+'">' + user.firstName + ' ' + user.lastName + '</option>';
        }
        select += '</select>';

        Bootbox.dialog({
          title: _.i18n('call.forward'),
          message: select,
          buttons: {
            success: {
              label: _.i18n('call.forward') ,
              className: 'btn-success',
              callback: function() {
                PlivoUtils.forwardCall(this.find('select').val());
              }
            }
          }
        });
      });
    },

    refreshPlivoUsers: function (recallAfter) {      

      var refrechTimerValue = Settings.configValue('plivo.refrechUserStatusTimout');
      if (!refrechTimerValue) 
        refrechTimerValue = 60000;

      PlivoUtils.findConnectedUsers().then(_.bind(function (users) {
       
        this.ui.users.empty();

        for (var i = 0; i < users.length; i++) {
          var user = users[i],
            fnLs = user.firstName + ' ' + user.lastName;
          var logo = ImageUtils.getLogo(null, fnLs);

          var sHtml = '<div class="item" title="' + fnLs + '">';
            sHtml += '<img src="' + logo + '"/>';
            sHtml += '<div class="busy '+ (user.busy ? 'activated' : '') +'">';
              sHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>'; // jshint ignore:line
            sHtml += '</div>';
          sHtml += '</div>';

          this.ui.users.append(sHtml);

          /*this.ui.status.append('<div title="' + fnLs + '"><img src="' + logo + '" style="width: 20px; height: 16px;"></img>' +
            '<div style="height:4px; width:20px; background-color: ' + (user.busy ? 'red' : 'green') +
            '; position: relative; left:0px; bottom: 0px;"></div></div>');*/
        }
        if (recallAfter) {
          setTimeout(_.bind(this.refreshPlivoUsers, this), refrechTimerValue, true);
        }
      }, this)).fail(_.bind(function() {
        if (recallAfter) {
          setTimeout(_.bind(this.refreshPlivoUsers, this), refrechTimerValue, true);
        }
      }));
    }
  });
});

