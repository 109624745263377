define('emailAttachmentDefaultView',[
  'module',
  'windows',
  'marionette',
  'underscore',
  'pdfView',
  'template!emailAttachmentDefaultView'
], function (
  Module,
  Windows,
  Marionette,
  _,
  PdfView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'email-attachment-preview-default-view',

    events: {
      'click': 'onClick'
    },

    serializeData: function () {
      var fn = '';
      if(this.options.fileName){
        fn = this.options.fileName;
      }
      return {
        name: fn
      };
    },

    onClick: function () {
      this.trigger('click');
    }
  });
});
  
