define('ordersDetailHeaderView',[
  'module',
  'jquery',
  'backbone',
  'imageUtils',
  'marionette',
  'app',
  'ordersController',
  'envHandler',  
  'moment',
  'rolesMixin',
  'underscore',
  'jqgridActionsButtonFormatterActions',
  'jqgridActionsButtonFormatterView',
  'modalsConfirmationView',
  'ordersLabelsView',
  'settings',
  'usersView',
  'compuzzProUtils',
  'ordersDeliveryView',
  'ordersPaymentView',
  'ordersValidationView',
  'ordersDuplicateView',
  'modalsAlertView',
  'emailView',
  'entities/orders',
  'entities/customers/customers',
  'tasksCreatorBehavior',
  'customersPopupSelectView',
  'template!ordersDetailHeaderView'
], function (
  Module,
  $,
  Backbone,
  imageUtils,
  Marionette,
  App,
  Controller,
  EnvironmentHandler,
  moment,
  RolesMixin,
  _,
  availableActions,
  ActionButtonsView,
  ConfirmationView,
  LabelsView,
  Settings,
  UsersView,
  CompuzzProUtils,
  DeliveryView,
  PaymentView,
  ValidationView,
  DuplicateView,
  AlertView,
  EmailView,
  Orders,
  Customers,
  TasksCreatorBehavior,
  CustomersPopupSelectView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-header-view',

    regions: {
      actions: '.js-action-buttons-region',
      usersRegion: '.users-region',
      labelsRegion: '.labels-region',
      deliveryRegion: '.delivery-region',
      paymentRegion: '.payment-region',
      validationRegion: '.validation-region'  
    },

    ui: {
      date: '.date',
      productName: '.product-name',
      companyName: '.company-name',
      supplier: '.supplier',
      supplierName: '.supplier .name',
      supplierPhoneIcon: '.supplier .phone',
      supplierMailIcon: '.supplier .mail',
      supplierInfo: '.supplier-info',
      expressDelivery: '.express-delivery',
      vip: '.vip',
      quantity: '.quantity',
      locale: '.locale',
      statusType: '.status .type',
      statusValue: '.status .value',
      tags: '.tags',
      poi: '.poi',
      poiContainer: '.poi-ctn',
      messages: '.messages',
      ukrMessages: '.ukr-messages',
      mail: '.mail',
      bank: '.bank',
      outsideEurope: '.outsideEurope',
      printer: '.printer',
      warningPrice: '.warning-price',
      validatedPrice: '.validated-price',
      pim: '.pim',
      archived: '.archived',
      copyPastMail: '.copy-past-mail',
      changeCustomer: '.change-customer',
      sameOrder: '.same-order',
      sameOrderCount: '.same-order .count',
      proofsheetButton: '.tools .proofsheet'
    },

    events: {
      'change .js-upload-proofsheet': 'onUploadCustomProofsheet',
      'change .js-upload-export': 'onUploadCustomExport',     
      'click .messages': 'onActionRequiredClick',
      'click .ukr-messages': 'onActionRequiredClick',
      'click @ui.companyName': 'onClickEditClient',
      'click @ui.copyPastMail': 'onClickCopyPastMail',
      'click @ui.changeCustomer': 'onChangeCustomer',
      'click @ui.poiContainer': 'onClickCopyPoi',
      'click @ui.archived': 'unArchiveOrder',
      'click @ui.supplierPhoneIcon': 'onPhoneSupplier',
      'click @ui.supplierMailIcon': 'onMailSupplier'
    },

    initialize: function () {
      this.supplierMail = '';
      this.supplierPhone = '';
    },

    behaviors: function () {
      return {
        CreateTask: {
          behaviorClass: TasksCreatorBehavior,
          refCommandLine: this.options.model.get('order')
        }
      };
    },

    destroy: function () {
      App.off('showHidePriceWarning');
    },

    onShow: function () {
      this.paymentView = new PaymentView({
        model: this.model.get('order')
      });
      this.getRegion('paymentRegion').show(this.paymentView);

      //--------------------
      if(Settings.configValue('orders.showValidationButton') === true) {
        this.validationView = new ValidationView({
          model: this.model.get('order')
        });
        this.getRegion('validationRegion').show(this.validationView);
      }

      //--------------------
      this.labelsView = new LabelsView({
        model: this.model.get('order')
      });
      this.getRegion('labelsRegion').show(this.labelsView);

      //--------------------
      this.deliveryView = new DeliveryView({
        model: this.model.get('order')
      });
      this.getRegion('deliveryRegion').show(this.deliveryView);

      //--------------------
      this.usersView = new UsersView({
        model: new Backbone.Model({userpids: this.model.get('order').get('userpids')}),
        onSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          this.model.get('order').assignUser(user.get('secId')).done(_.bind(function () {
            this.model.get('order').fetch();
            deferred.resolve();
          }, this));
          return deferred;
        }, this),
        onUnSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          this.model.get('order').unassignUser(user.get('secId')).done(_.bind(function () {
            this.model.get('order').fetch();
            deferred.resolve();
          }, this));
          return deferred;
        }, this)
      });
      this.getRegion('usersRegion').show(this.usersView);

      //--------------------     
      
      this.listenTo(this.model.get('order'), 'change', _.bind(function(model) {
        if (model.hasChanged('status'))
          this.renderStatus();

        if (model.hasChanged('archived'))
          this.renderArchived();

        if (model.hasChanged('productBaseCode'))
          this.renderProductName();

        if (model.hasChanged('productOwner') || 
            model.hasChanged('supplierNumber'))
            this.renderSupplier();

        if (model.hasChanged('name') || 
            model.hasChanged('firstName') || 
            model.hasChanged('lastName') || 
            model.hasChanged('endUserId'))
          this.renderCompanyName();

        if (model.hasChanged('createDate') || 
            model.hasChanged('productOwner') || 
            model.hasChanged('supplierNumber') || 
            model.hasChanged('name') || 
            model.hasChanged('firstName') || 
            model.hasChanged('lastName') ||
            model.hasChanged('endUserId') ||
            model.hasChanged('commandType') ||
            model.hasChanged('pim') ||
            model.hasChanged('actionRequiredInternal') ||
            model.hasChanged('actionRequiredUkr') ||
            model.hasChanged('commandType'))
          this.renderContent();

        if (model.hasChanged('price') || 
            model.hasChanged('quantity'))
          this.renderSupplierInfo();

        if (model.hasChanged('userpids'))
          this.renderUsers();

        if (model.hasChanged('expressShip'))
          this.renderExpressDelivery();
      }, this));

      this.listenTo(this.model.get('product'), 'change', _.bind(function(model) {
        if (model.hasChanged('baseCode'))
          this.renderProductName();
      }, this));
     
      this.listenTo(this.options.customer, 'change', _.bind(function(model) {
        if (model.hasChanged('locales') || 
          model.hasChanged('countryCode'))
        this.renderProofsheetButton();

        if (model.hasChanged('vip'))
          this.renderVip();
      }, this));

      this.renderLocale();

      //--------------------

      App.on('showHidePriceWarning', this.showHidePriceWarning, this);
    },

    onRender: function () {
      this.renderContent();
      this.renderProductName();
      this.renderStatus();
      this.renderProofsheetButton();
      this.renderArchived();
      this.renderSupplier();
      this.renderCompanyName();
      this.renderSupplierInfo();
      this.renderExpressDelivery();
      this.renderVip();
    },

    renderSupplierInfo: function () {
      this.ui.supplierInfo.hide();

      this.model.get('order').fetchListData().done(_.bind(function (data) {
        this.renderPrice(data.buyingPrice);
        if(!data || !data.supplier)
          return;

        App.request('caccounts:get-share-by-id', data.supplier.secId).done(_.bind(function (supplier) {
          var enableSupplierOrder = false;
          if(supplier) {
            this.supplierMail = supplier.orderEmail;
            this.supplierPhone = supplier.phone;

            if (supplier.autoOrderWSPim && this.model.get('order').get('pim')) {
              enableSupplierOrder = true;
            }
            if(supplier.comment && supplier.comment !== '') {
              this.ui.supplierInfo.css('display', 'flex');
              this.ui.supplierInfo.attr('title', supplier.comment);
            }

            if(this.supplierPhone !== '') {
              this.ui.supplier.attr('title', this.supplierPhone);
              this.ui.supplierPhoneIcon.css('display', 'flex');
            }

            if(this.supplierMail !== '') {
              this.ui.supplierMailIcon.css('display', 'flex');
            }
          }
        }, this));
      }, this));
    },

    renderExpressDelivery: function () {
      if (this.model.get('order').get('expressShip'))
        this.ui.expressDelivery.css('display', 'flex');
      else
        this.ui.expressDelivery.hide();

      var title = _.i18n('order.expressDelivery');
      if(this.model.get('order').get('savedPrice') && this.model.get('order').get('savedPrice').expressShippingCost)
        title += ' (€' + this.model.get('order').get('savedPrice').expressShippingCost + ')';

      this.ui.expressDelivery.attr('title', title);
    },

    renderVip: function () {
      if (this.options.customer.get('vip') === true)
        this.ui.vip.css('display', 'flex');
      else
        this.ui.vip.hide();
    },
  
    renderContent: function () {

      if(this.model.get('order').get('createDate'))
        this.ui.date.html(moment(this.model.get('order').get('createDate')).format('DD/MM/YYYY HH:mm'));
      else
        this.ui.date.html('-');

      //--------------------

      this.ui.statusType.html('-');

      var statusType = '';

      if(this.model.get('order').get('commandType'))
        statusType += _.i18n('common.' + (this.model.get('order').get('commandType') === 'offer' ? 'offer' : 'command'));

      if(statusType !== '')
        this.ui.statusType.html(statusType);

      //--------------------

      this.ui.poi.html(this.model.get('poi'));    

      //--------------------

      var actionsButtonView = this.getActionButtonsView();
      this.listenTo(actionsButtonView, 'action', this.onAction);
      this.getRegion('actions').show(actionsButtonView);

      //--------------------

      if (this.model.get('order').get('actionRequiredInternal'))
        this.ui.messages.show();
      else
        this.ui.messages.hide();

      //--------------------

      if (this.model.get('order').get('actionRequiredUkr'))
        this.ui.ukrMessages.show();
      else
        this.ui.ukrMessages.hide();

      //--------------------

      if (Settings.configValue('orders.showPimIcon') === true) {
        if (this.model.get('order').get('pim'))
          this.ui.pim.addClass('activated');
        else
          this.ui.pim.removeClass('activated');
      }      
    
      //--------------------
      
      if (this.model.get('order').get('outsideEurope'))
        this.ui.outsideEurope.show();

      //--------------------  

      if (Settings.configValue('orders.detail.showValidatedPriceStatus') === true) {
        if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_PRICE]) {
          var savedPrice = this.model.get('order').get('savedPrice');
          if (savedPrice && savedPrice.total) {
            var requestRs = this.model.get('order').getPrice(true, false);
            $.when(requestRs).done(_.bind(function (rsPrice) {
              this.model.get('order').checkPimData().done(_.bind(function () {
                if (rsPrice.fromPimData)
                  this.ui.validatedPrice.show();
                else
                  this.ui.validatedPrice.hide();              
                this.showHidePriceWarning(Number(rsPrice.total), Number(savedPrice.total));
              }, this)).error(_.bind(function () {
                this.ui.validatedPrice.hide();
                this.showHidePriceWarning(Number(rsPrice.total), Number(savedPrice.total));
              }, this));
            }, this));
          }
        }
      }
      
      //--------------------

      var nbrOrderSameCustomer = this.model.get('order').get('nbrOrderSameCustomer');

      if (nbrOrderSameCustomer > 0) {
        this.ui.sameOrder.addClass('open');
        this.ui.sameOrderCount.html(nbrOrderSameCustomer);
      } else
        this.ui.sameOrder.removeClass('open');
    },

    renderUsers: function () {
      this.usersView.allCollection.reset(this.model.get('order').get('userpids'));
      this.usersView.renderContent();
    },

    renderStatus: function () {
      App.request('orders:statuses', this.model.get('order').get('commandType')).done(_.bind(function (statuses) {
        this.ui.statusValue.html('-');

        var statusValue = '';

        if(this.model.get('order').get('status')) {
          var status = null;

          status = statuses.find(_.bind(function (item) {
            return item.get('statusConst') === this.model.get('order').get('status');
          }, this));

          if (!status)
            statusValue = _.i18n('commandstatus.' + this.model.get('order').get('status').toLowerCase());
          else
            statusValue = status.get('status');
        }

        if(statusValue !== '')
          this.ui.statusValue.html(statusValue);
      }, this));
    },

    renderProductName: function () {
      this.ui.productName.html('-');

      var productName = '';

      if(this.model.get('order').get('productBaseCode'))
        productName += this.model.get('order').get('productBaseCode');
      
      if(this.model.get('product').get('baseCode'))
        productName += ' (' + this.model.get('product').get('baseCode') + ')';

      if(productName !== '') {
        this.ui.productName.html(productName);
        if (this.model.get('order').get('pim')) {
          this.ui.productName.attr('href', '/#pim/products/' + this.model.get('order').get('productSecId'));
        } else {
          this.ui.productName.attr('href', '/#product/' + this.model.get('order').get('productSecId'));
        }
      }
    },

    renderPrice: function (buyingPrice) {
      this.ui.quantity.html('-');
      var quantity = '';

      var numberLocale = 'fr-BE';
      var configNumberLocale = Settings.configValue('priceLocale');
      if (configNumberLocale)
        numberLocale = configNumberLocale;
      if(this.model.get('order').get('price')) {
        quantity += '<b>€' + new Intl.NumberFormat(numberLocale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2}).format(this.model.get('order').get('price')) + '</b>';

        if (buyingPrice) {
          if(quantity !== '')
            quantity += '&nbsp;-&nbsp;';
          var margin = Math.round(((this.model.get('order').get('price') - buyingPrice) /
              this.model.get('order').get('price')) * 100);
          if (margin < 20) {
            quantity += '<span style="color:red;">' + margin + '%' + '</span>';
          } else {
            quantity += margin + '%';
          }
        }
      }

      if(this.model.get('order').get('quantity')) {
        if(quantity !== '')
          quantity += '&nbsp;-&nbsp;';

        var value = new Intl.NumberFormat(numberLocale).format(this.model.get('order').get('quantity')) + ' ' + _.i18n('common.pcs');

        if(this.model.get('product').get('minimumPacksPerOrder') &&
            this.model.get('product').get('minimumPacksPerOrder') > this.model.get('order').get('quantity'))
          quantity += '<p>' + value + '</p>';
        else
          quantity += value;
      }     

      if(quantity !== '')
        this.ui.quantity.html(quantity);     
    },

    renderProofsheetButton: function () {
      var showProofsheetLink = true;

      if (Settings.configValue('showProofsheetLink'))
        showProofsheetLink = Settings.configValue('showProofsheetLink');

      this.ui.proofsheetButton.css('display', 'none');
        
      if(showProofsheetLink) {
        this.ui.proofsheetButton.css('display', 'flex');
          
        var locale = 'fr';
        var countryCode = '';

        if (this.options.customer.get('locales') && (this.options.customer.get('locales')).length >= 2)
          locale = (this.options.customer.get('locales')).substring(0, 2);

        if (this.options.customer.get('countryCode') && (this.options.customer.get('countryCode')).length >= 2)
          countryCode = (this.options.customer.get('countryCode')).substring(0, 2);

        var shopUrl = Settings.getWebshopUrl(locale, countryCode);
        var proofsheetUrl = Settings.configValue('orderDetail.webShopProofSheetUrl');

        if (shopUrl && proofsheetUrl) {
          var url = shopUrl + proofsheetUrl.replace('{poi}', this.model.get('poi'));
          this.ui.proofsheetButton.attr('href', url);
          this.ui.poi.attr('href', url);
        }
      }
    },

    renderArchived: function () {
      if(this.model.get('order').get('archived'))
        this.ui.archived.css('display', 'flex');
      else
        this.ui.archived.hide();  
    },

    renderSupplier: function () {
      this.ui.supplierName.html('-');

      var supplier = '';
      
      if(this.model.get('order').get('productOwner'))
        supplier += this.model.get('order').get('productOwner');

      if(supplier !== '')
        this.ui.supplierName.html(supplier);
    },

    renderCompanyName: function () {
      this.ui.companyName.html('-');
        
      var companyName = '';

      if(this.options.customer.get('name'))
        companyName += this.options.customer.get('name');
  
      var clientName = '';
  
      if(this.options.customer.get('firstName'))
        clientName += this.options.customer.get('firstName');

      if(this.options.customer.get('lastName')) {
        if(clientName !== '')
          clientName += ' ';

        clientName += this.options.customer.get('lastName');
      }
  
      if (clientName !== '') {
        if (companyName !== '')
          companyName += ' - ';

        companyName += clientName;
      }
  
      if(this.options.customer.get('endUserId')) {  
        if (companyName !== '')
          companyName += ' - ';

        companyName += this.options.customer.get('endUserId');
      }

      if(companyName !== '')
        this.ui.companyName.html(companyName);
    },

    renderLocale: function () {
      if (this.model.get('order').get('webShopLocale')) {
        this.ui.locale.css('display', 'flex');
        this.ui.locale.html(this.model.get('order').get('webShopLocale'));
      } else
        this.ui.locale.hide();
    },

    showHidePriceWarning: function (price1, price2) {
      if (price1 !== null && price2 !== null) {
        if (price1 !== price2) {
          this.ui.warningPrice.show();
        }
        /*if (price1 > price2) {
          this.ui.warningPrice.css('color', 'red');
        } else {
          this.ui.warningPrice.css('color', '#929292');
        }*/
      } else {
        this.ui.warningPrice.hide();
      }
    },

    onPhoneSupplier: function (e) {
      e.stopPropagation();
      if(this.supplierPhone !== '')
        App.trigger('phone:numbering', {
          phoneNumber: this.supplierPhone,
          country: ''
        });
    },

    onMailSupplier: function (e) {
      e.stopPropagation();

      if(this.supplierMail !== '') {
        var locale = 'FR';
        if (Settings.configValue('customerDefaultMailLanguage'))
          locale = Settings.configValue('customerDefaultMailLanguage');
        if (this.options.customer.get('locales'))
          locale = this.options.customer.get('locales');

        var from = App.request('mails:getFromMail', Settings.configValue('mails.supplier.from'), locale);

        App.request('mails:checkFrom', from).done(_.bind(function () {
          EmailView.prototype.open({
            from: from,
            to: this.supplierMail,
            allowFromSelection: Settings.configValue('mails.invoice.allowFromSelection'),
            id: 'supplier.mail.' + this.supplierMail,
            title: _.i18n('supplier.mail') + ' | ' + this.supplierMail
          });
        }, this)).fail(_.bind(function () {
          if (!from)
            AlertView.prototype.open('', _.i18n('common.googleFromEmailEmpty'));
          else
            AlertView.prototype.open('', _.i18n('common.googleFromEmailError').replace('%s', from));
        }, this));
      }

    },

    onClickCopyPastMail: function () {
      var $temp = $('<input>');
      $('body').append($temp);
      $temp.val(this.options.customer.get('endUserId')).select();
      document.execCommand('copy');
      $temp.remove();
    },

    onChangeCustomer: function () {
      var userDialog = new CustomersPopupSelectView({
        onUserSelected: _.bind(function(model) {
          userDialog.hide();
          this.model.get('order').changeCustomer(model.get('secId')).done(_.bind(function() {

            this.model.get('order').fetch().done(_.bind(function() {
              this.options.customer = App.request('customers:entity:end-user-id', this.model.get('order').get('endUserId'));
              this.options.customer.fetch().done(_.bind(function() {
                this.options.parentView.render();
              }, this));
            }, this));
          }, this));
        }, this)
      });

      userDialog.show({title: _.i18n('dialog.offer.selectCustomer')});
    },

    onClickCopyPoi: function () {
      var $temp = $('<input>');
      $('body').append($temp);
      $temp.val(this.model.get('poi')).select();
      document.execCommand('copy');
      $temp.remove();
    },

    onUploadCustomProofsheet: function (e) {
      $('.loading-block').show();
      var target = e.target,
        fileInfo;

      if (e && e.target && e.target.files && e.target.files[0]) {
        fileInfo = imageUtils.getFileInfo(target, 0);

        if (fileInfo) {
          App.request('order:upload-custom-proofsheet', this.model.get('order').get('purchaseOrderId'), fileInfo).then(function () {
            CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
            $('.loading-block').hide();
          });
        } else {
          $('.loading-block').hide();
        }
      } else {
        $('.loading-block').hide();
      }
    },

    onUploadCustomExport: function (e) {
      $('.loading-block').show();
      var target = e.target,
        fileInfo;

      if (e && e.target && e.target.files && e.target.files[0]) {
        fileInfo = imageUtils.getFileInfo(target, 0);

        if (fileInfo) {
          App.request('order:upload-custom-export', this.model.get('order').get('purchaseOrderId'), fileInfo).then(function () {
            CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
            $('.loading-block').hide();
          });
        }
      }
    },

    onClickEditClient: function () {

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS]) {
        if (Settings.configValue('orders.detail.editCustomerInNewTab')) {
          var baseURL = window.location.protocol + '//' + window.location.hostname;
          if (window.location.port) {
            baseURL += ':' + window.location.port;
          }
          var url = baseURL + '#customers/' + this.options.customer.get('secId');
          window.open(url);
        } else {
          App.navigate('customers/' + this.options.customer.get('secId'), {trigger: true});
        }        
      }
    },

    getActionButtonsView: function () {
      var actions = [];
      var actionsCollection = new Backbone.Collection();

      if (Settings.configValue('orders.detail.contextMenu.showExport'))
        actions.push('export');

      if (Settings.configValue('orders.detail.contextMenu.showProductSheet'))
        actions.push('productSheet');

      actions.push('duplicate');
      
      actions.push('uploadCustomProofsheet');

      if (Settings.configValue('orders.detail.contextMenu.showUploadExport'))
        actions.push('uploadCustomExport');      

      if (!this.model.get('order').get('pim'))
        actions.push('edit');
      
      actions.push('toInvoice');

      if (Settings.configValue('orders.detail.contextMenu.showConvertPimToCompuzz') && 
      this.model.get('order').get('pim'))
        actions.push('convertPimToCompuzz');      

      if (Settings.dl) {
        actions.push('downloadProforma');
        actions.push('downloadDeliveryNote');
      }

      actions.push('downloadProofsheet');
      
      if(this.model.get('order').get('archived') && this.model.get('order').get('archived') === true)
        actions.push('unarchive');
      else
        actions.push('archive');     
        
      actions.push('delete');

      _.each(actions, function (action) {
        actionsCollection.push(availableActions[action]);
      });

      return new ActionButtonsView({
        collection: actionsCollection,
        dontShowIcons: true
      });
    },

    onAction: function (action) {
      switch (action) {
        case 'edit':
          App.request('session:create-session-from-poi', {webServiceUrl: 'compuzz', returnUrl: location.href},
            this.model.get('poi')).done(_.bind(function (session) {
            window.location.href = EnvironmentHandler.getValue(Settings.get('environment'), 'editOrderUrl') +
              '#customization/oloSessionId/' +
              session.sessionId + '/productCode/' +
              this.model.get('order').get('productCode') +
              '/purchaseOrderId/' + this.model.get('poi');
          }, this));
          break;
        case 'export':
          var pdfUrl = Settings.url('pdf', '', {
            poi: this.model.get('poi'),
            docType: 'pzi'
          }, true);
          window.open(pdfUrl, '_blank');
          break;
        case 'productSheet':

          var pdfRUrl = Settings.url('pdf', '', {
            poi: this.model.get('poi'),
            docType: 'prs'
          }, true);
          window.open(pdfRUrl, '_blank');

          break;
        case 'duplicate':
          this.duplicateOrder();
          break;
        case 'archive':
          this.archiveOrder();
          break;
        case 'unarchive':
          this.unArchiveOrder();
          break;
        case 'delete':
          this.deleteOrder();
          break;
        case 'uploadCustomProofsheet':
          this.$('#order-proofsheet-input').trigger('click');
          break;
        case 'uploadCustomExport':
          this.$('#order-export-input').trigger('click');
          break;
        case 'toInvoice':
          this.toInvoice();
          break;
        case 'downloadProforma':
          this.downloadProforma();
          break;
        case 'downloadDeliveryNote':
          this.downloadDeliveryNote();
          break;
        case 'convertPimToCompuzz':
          this.convertPimToCompuzz();
          break;
        case 'downloadProofsheet':
          var fileName = this.model.get('order').getProofsheetFilename(this.options.customer);
          var url = this.model.get('order').getProofsheetDownloadUrl(fileName, this.options.customer);
          window.open(url, '_blank');
          break;        
        default :
          throw 'Action is not supported';
      }
    },

    onActionRequiredClick: function () {
      Orders.showOrder(this.model.get('poi'), 'internal_messages');
    },

    duplicateOrder: function () {
      DuplicateView.prototype.open(
        this.model.get('order')
      );
    },

    archiveOrder: function () {
      Controller.archive([this.model.get('order')]);
    },

    unArchiveOrder: function () {
      Controller.unarchive([this.model.get('order')]);
    },

    deleteOrder: function () {
      Controller.deleteOrders([this.model.get('order')]);
    },

    toInvoice: function () {
      Controller.createSendInvoiceMail([this.model.get('order')], this.model.get('order').get('endUserId')).done(_.bind(function () {
        this.model.get('order').fetch();
        this.model.get('order').trigger('invoices:new');
      }, this));
    },

    convertPimToCompuzz: function () {
      Controller.pimToCompuzz([this.model.get('order')]);
    },

    orderToSupplier: function() {
      Controller.orderToSupplier([this.model.get('order')]);
    },

    downloadProforma: function () {
      if(this.options.customer.get('vatNumber') && this.options.customer.get('vatNumber') !== '') {
        if (this.options.customer.get('vatNumberChecked') === true) {
          if (this.options.customer.get('vatNumberValid') === false) {
            ConfirmationView.prototype.open({
              text: _.i18n('orders.vat.not-valid'),
              onConfirm: _.bind(function () {
                window.location = Settings.url('dl', 'proforma', {
                  pois: this.model.get('poi')
                });
              }, this)
            });
          } else {
            window.location = Settings.url('dl', 'proforma', {
              pois: this.model.get('poi')
            });
          }
        } else {
          this.options.customer.checkVat().done(_.bind(function (valid) {
            if (!valid) {
              ConfirmationView.prototype.open({
                text: _.i18n('vat.not-valid'),
                onConfirm: _.bind(function () {
                  window.location = Settings.url('dl', 'proforma', {
                    pois: this.model.get('poi')
                  });
                }, this)
              });
            } else {
              window.location = Settings.url('dl', 'proforma', {
                pois: this.model.get('poi')
              });
            }
          }, this)).fail(_.bind(function () {
            ConfirmationView.prototype.open({
              text: _.i18n('vat.checkVatError'),
              onConfirm: _.bind(function () {
                window.location = Settings.url('dl', 'proforma', {
                  pois: this.model.get('poi')
                });
              }, this)
            });
          }, this));
        }
      } else {
        ConfirmationView.prototype.open({
          text: _.i18n('vat.empty-vat'),
          onConfirm: _.bind(function () {
            window.location = Settings.url('dl', 'proforma', {
              pois: this.model.get('poi')
            });
          }, this)
        });
      }
    },

    downloadDeliveryNote: function () {
      window.location = this.model.get('order').getDeliveryFileUrl(
        [this.model.get('order').get('purchaseOrderId')],
        this.options.customer);
    }
  });
});
