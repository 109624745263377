define('webshopCategoriesDetailBodyUrlsV1DomainView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'settings',
  'buttonView',
  'modalsAlertView',
  'template!webshopCategoriesDetailBodyUrlsV1DomainView',
  'entities/webshop/webshop'
], function (
  App,
  Module,
  Marionette,
  _,
  Settings,
  ButtonView,
  AlertView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'domain-view',

    modelEvents: {
        'change': 'render'
    },

    serializeData: function () {
      var data = {};
      data.domain = this.model.get('domain');
      data.url = this.model.get('url');
      return data;
    }
  });
});
