define('webshopProductsListImageView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'tableFieldView',
  'imageButtonView'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  FieldView,
  ImageButtonView
) {
  'use strict';

  Module.exports = ImageButtonView.extend({
    className: 'webshop-products-list-image-view ' + FieldView.prototype.className +
                ' ' + ImageButtonView.prototype.className,

    modelEvents: {
      'change:medias': 'render'
    },

    initialize: function() {
      this.url = this.model.getPreviewImageUrl();
      this.galleryId = 'webshop.product.gallery.' + this.model.getCode();
      this.gelleryTitle = _.i18n('webshop.product.gallery.title') + ' | ' + this.model.getName();
      this.galleryUrls = this.model.getImageUrls();
    }
  });
});
