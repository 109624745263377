define('emailAttachmentPdfView',[
  'module',
  'marionette',
  'underscore',
  'pdfView',
  'template!emailAttachmentPdfView'
], function (
  Module,
  Marionette,
  _,
  PdfView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'email-attachment-preview-pdf-view',    

    ui: {
      canvasContainer: '.canvas-ctn',
      canvas: '.canvas-ctn .canvas',
      loader: '.loader-2',
      error: '.error',
      icon: '.icon'
    },

    regions: {
        sendRegion: '.upload-button-region'
    },

    modelEvents: {
      'change:arrayBuffer': 'render',
      'change:error': 'renderError',
      'change:loaded': 'renderLoader'
    },

    events: {
      'click': 'onClick'
    },

    initialize: function () {
      if(!this.model.get('loaded'))
        this.model.set('loaded', false, {silent: true});
    },
    
    onRender: function () {
      this.renderAttachment();
      this.renderLoader();
      this.renderError();
    },

    renderAttachment: function() {
      if (this.options.zoom === true)
        this.$el.addClass('zoom');

      var pdfDocumentURL= null;
      var shouldLoadDocument = false;

      // If the attachment has already been loaded
      // e.g. when sending a proof sheet
      if(this.model.get('arrayBuffer')) {
        pdfDocumentURL = this.model.get('arrayBuffer');
        shouldLoadDocument = true;
      }
      // Else if we don't have the binaries but an URL and size
      // e.g. when viewing a GoogleAPI attachment not yet downloaded
      else if(this.options.url) {
        pdfDocumentURL = this.options.url;
        if(this.options.size < 4000000)
          shouldLoadDocument = true;
        else
          shouldLoadDocument = false;
      }

      if (pdfDocumentURL && shouldLoadDocument ) { // 4 MB
        // Loaded via <script> tag, create shortcut to access PDF.js exports.
        var pdfjsLib = window['pdfjs-dist/build/pdf'];

        // The workerSrc property shall be specified.
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.worker.min.js';

        // Asynchronous download of PDF
        var loadingTask = pdfjsLib.getDocument(pdfDocumentURL);
        loadingTask.promise.then(_.bind(function (pdf) {
          // Fetch the first page
          var pageNumber = 1;
          pdf.getPage(pageNumber).then(_.bind(function (page) {
          if (this.ui && this.ui.loader && typeof this.ui.loader.hide === 'function') {
              this.ui.loader.hide();
          }

            var scale = 0.2;

            if (this.options.zoom === true)
              scale = 2;
            var viewport = page.getViewport({scale: scale});

            // Prepare canvas using PDF page dimensions
            var canvas = this.ui.canvas[0];
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              //console.log('Page rendered');
            });

            this.ui.canvasContainer.css('display', 'flex');
          }, this));
        }, this), _.bind(function (reason) {
          console.error(reason);
        }, this));
      } else {
        // The PDF was not loaded
        if(this.ui) {
          this.ui.canvasContainer.hide();
          if (this.ui.loader) {
            this.ui.loader.hide();
          }
          // Display the filename and size instead
          if (this.ui.icon) {
            this.ui.icon.css('display', 'flex');
          }
        }
      }
    },

    renderLoader: function () {
      if (this.model.get('loaded') === false) {
        this.ui.canvasContainer.hide();
        this.ui.error.hide();
        // Display the filename and size instead
        if(this.ui.icon && this.options.size > 4000000){ // 4MB
          this.ui.icon.css('display', 'flex');
        }
      }
      else {
        this.ui.loader.hide();
      }
    },

    renderError: function () {
      if (this.model.get('error')) {
        this.ui.canvasContainer.hide();
        this.ui.loader.hide();
        this.ui.error.css('display', 'flex');
      } else {
        this.ui.error.hide();
      }
    },

    serializeData: function () {
      var fn = '';
      if(this.options.fileName){
        fn = this.options.fileName;
      }

      var filesize = function(size) {
        var cutoff, i, selectedSize, selectedUnit, unit, units, _i, _len;
        units = ['TB', 'GB', 'MB', 'KB', 'b'];
        selectedSize = selectedUnit = null;
        for (i = _i = 0, _len = units.length; _i < _len; i = ++_i) {
          unit = units[i];
          cutoff = Math.pow(1000, 4 - i) / 10;
          if (size >= cutoff) {
            selectedSize = size / Math.pow(1000, 4 - i);
            selectedUnit = unit;
            break;
          }
        }
        selectedSize = Math.round(10 * selectedSize) / 10;
        return selectedSize + ' ' + selectedUnit;
      };

      var size = 0;
      if(this.options.size){
        size = filesize(this.options.size);
      }
      return {
        name: fn,
        size: size
      };
    },

    onClick: function () {
      this.trigger('click');
    }
  });
});
  
