define('ordersDetailBodyView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'customerInternalCommentsView',
  'ordersDetailBodyTabsView',
  'template!ordersDetailBodyView',
  'entities/orders'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  CustomerInternalCommentsView,
  OrdersDetailBodyTabsView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-body-view',  

    ui: {      
      loading: '.loading'
    },  

    regions: {
      tabsRegion: '.tabs-region',
      detailsRegion: '.details.region',
      detailsOldRegion: '.details_old.region',
      trackingRegion: '.tracking.region',
      callsRegion: '.calls.region',
      attachmentsRegion: '.attachments.region',
      priceRegion: '.price.region',
      priceV2Region: '.pricev2.region',
      invoicesRegion: '.invoices.region',
      mailsRegion: '.mails.region',
      compositionRegion: '.composition.region',
      customerMessageRegion: '.customer_messages.region',
      customerInternalMessageRegion: '.internal_messages.region',
      customerAllMessageRegion: '.all_customer_messages.region',
      proofsheetRegion: '.order_proofsheet_history.region',
      supplierRegion: '.order_supplier.region',
      customerCommentsRegion: '.customer-internal-comments-region'
    },

    initialize: function () {
      this.tabName = this.options.tabName;
    },
  
    loading: function(value) {
      if(value)
        this.ui.loading.addClass('activated');
      else
        this.ui.loading.removeClass('activated');
    },

    onShow: function() {
      this.tabsView = new OrdersDetailBodyTabsView({
        model: this.model.get('order')
      });
      this.getRegion('tabsRegion').show(this.tabsView);

      //-------------------
      var customerInternalCommentsView = new CustomerInternalCommentsView({
        secId: this.options.customer.get('secId')
      });
      this.getRegion('customerCommentsRegion').show(customerInternalCommentsView);
    },

    onRender: function() {
      this.renderContent();
    },

    renderContent: function() {
      switch (this.tabName) {
        case 'details':
          require([            
            'ordersDetailOrderView'
          ], _.bind(function (View) {
            if(!this.detailsView) {              
              this.detailsView = new View({              
                model: this.model,  
                customer: this.options.customer
              });
              this.getRegion('detailsRegion').show(this.detailsView); 
            }           
                       
          }, this));
        break;

        case 'details_old':
          require([
            'orderDetailOldDetailController'
          ], _.bind(function (controller) {
            controller.showDetailsTab(this.getRegion('detailsOldRegion'), 
            this.model.get('order'), this.options.customer, this.model.get('product'));
          }, this));
        break; 

        case 'price':
          require([
            'ordersDetailPriceController'
          ], _.bind(function (Controller) {
            Controller.showPrice(this.getRegion('priceRegion'), this.model.get('order'));
          }, this));          
          break;

        case 'pricev2':
          require([
            'ordersDetailPriceV2View'
          ], _.bind(function (View) {
            if(!this.priceV2View) {              
              this.priceV2View = new View({              
                model: this.model.get('order'),
                product: this.model.get('product')
              });

              this.getRegion('priceV2Region').show(this.priceV2View); 
            }   
          }, this));
        break; 

        case 'composition':
          require([
            'orderdDetailCompositionController'
          ], _.bind(function (controller) {
            controller.showCompositionTab(
              this.getRegion('compositionRegion'), 
              this.model.get('order'), this.options.customer, this.model.get('product'));
          }, this));
        break;

        case 'attachments':
          require([
            'ordersDetailAttachmentsView'
          ], _.bind(function (View) { 
            if(!this.attachmentsView) {           
              this.attachmentsView = new View({
                model: this.model.get('order'), 
                customer: this.options.customer
              });
              this.getRegion('attachmentsRegion').show(this.attachmentsView);  
            }          
          }, this));
          break;

        case 'tracking':
          require([
            'ordersDetailTrackingListView'
          ], _.bind(function (View) {
            if(!this.trackingView) {   
              this.trackingView = new View({              
                model: this.model.get('order'),
                poi: this.model.get('poi'),
                customer: this.options.customer
              });            
              this.getRegion('trackingRegion').show(this.trackingView);  
            }    
          }, this));
          break;

        case 'calls':
          require([
            'customersDetailBodyCallsView'
          ], _.bind(function (View) {
            if(!this.callsView) {
              this.callsView = new View({
                model: this.options.customer
              });
              this.getRegion('callsRegion').show(this.callsView);
            }
          }, this));
          break;

        case 'mails':
          require([
            'ordersDetailMailsView'
          ], _.bind(function (View) {
            if(!this.mailsView) {
              this.mailsView = new View({
                model: this.model.get('order')
              });
              this.getRegion('mailsRegion').show(this.mailsView);
            }
          }, this));
          break;


        case 'internal_messages':
          require([
            'internalMessageTabController'
          ],_.bind(function (controller) {
            var csMsg = this.tabName === 'internal_messages';
            controller.showTab(this.model.get('order'), this.getRegion('customerInternalMessageRegion'), csMsg, this.options.customer);
          }, this));
        break;

        case 'invoices':
          require([
            'ordersDetailInvoicesView'
          ], _.bind(function (View) {
            if(!this.invoicesView) {   
              this.invoicesView = new View({              
                model: this.model.get('order')
              });            
              this.getRegion('invoicesRegion').show(this.invoicesView);  
            }    
          }, this));
        break;

        case 'order_proofsheet_history':
          require([
            'orderProofsheetHistoryTabController'
          ], _.bind(function (controller) {
            controller.showTab(this.getRegion('proofsheetRegion'), this.model.get('order'));
          }, this));
        break;

        case 'order_supplier':
          require([
            'ordersDetailSupplierView'
          ], _.bind(function (View) {
            if(!this.supplierView) {
              this.supplierView = new View({
                model: this.model.get('order')
              });
              this.getRegion('supplierRegion').show(this.supplierView);
            }
          }, this));
        break;
      }
    },

    selectTab: function(tabName) {
      this.tabName = tabName;      
      if(this.tabsView)
        this.tabsView.setSelected(tabName);
      this.renderContent();
      this.$el.find('.region').removeClass('selected');
      this.$el.find('.' + tabName + '.region').addClass('selected');
    }
  });
});

