define('webshopCategoriesDetailBodyUrlsView',[
  'module',
  'settings',
  'headerFooterView',
  'webshopCategoriesDetailBodyUrlsBodyView',
  'webshopCategoriesDetailBodyUrlsFooterView',
  'template!webshopCategoriesDetailBodyUrlsView'
], function (
    Module,
    Settings,
    HeaderFooterView,
    BodyView,
    FooterView,
    Tpl
) {
  'use strict';

  Module.exports = HeaderFooterView.extend({
    template: Tpl,
    className: 'webshop-categories-detail-body-urls-view ' + HeaderFooterView.prototype.className,
    childView: BodyView,
    childFooterView: FooterView,

    serializeData: function () {
      var data = {};
      var url = Settings.configValue('webshop.url');
      data.adminUrl = url + '/admin/default/jqadm/get/catalog/' + this.model.getId();
      return data;
    }
  });
});
