
define('template!mailsMailHeaderView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header mail-header-view">\n  <div class="subject"></div>\n  <div class="infos">\n    <div class="left">\n      <div class="from">\n        ' +
((__t = ( _.i18n('common.from') )) == null ? '' : __t) +
':\n        <div class="name"></div>\n        <div class="mail"></div>\n      </div>\n      <div class="to">\n        ' +
((__t = ( _.i18n('common.to') )) == null ? '' : __t) +
':\n        <div class="name"></div>\n        <div class="mail"></div>\n      </div>\n      <div class="cc">\n        ' +
((__t = ( _.i18n('common.cc') )) == null ? '' : __t) +
':\n        <div class="name"></div>\n        <div class="mail"></div>\n      </div>\n    </div>\n    <div class="right">\n      <div class="reply">\n        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">\n          <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->\n          <path d="M205 34.8c11.5 5.1 19 16.6 19 29.2v64H336c97.2 0 176 78.8 176 176c0 113.3-81.5 163.9-100.2 174.1c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-7.5 4.3-14.4 9.8-19.5c9.4-8.8 22.2-26.4 22.2-56.7c0-53-43-96-96-96H224v64c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4z"/>\n        </svg>\n      </div>\n      <div class="date"></div>\n      <a class="customerSecId" style="display: none;" target="_blank" href="#" title="See customer">\n        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 256l64 0c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16L80 384c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm256-32l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>\n      </a>\n    </div>\n  </div>\n  <div class="labels-region"></div>\n</div>\n\n';

}
return __p
};});

