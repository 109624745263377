define('ordersDetailMailsView',[
    'module',
    'backbone',
    'underscore',
    'app',
    'tableView',
    'tableDatetimeFieldView',
    'mailsMailView'
], function (
    Module,
    Backbone,
    _,
    App,
    TableView,
    DatetimeFieldView,
    MailView
) {
    'use strict';

    Module.exports = TableView.extend({
        className: 'orders-detail-mails-view ' + TableView.prototype.className,
        pageSize: 50,
        pageMode: 'pagination',

        setFields: function() {
            var fields = [];

            fields.push({
                name: 'Date',
                label: _.i18n('common.date'),
                bind: 'sentDate',
                className: 'sentDate',
                view: DatetimeFieldView
            });

            fields.push({
                name: 'subject',
                label: _.i18n('common.subject'),
                bind: 'subject',
                className: 'subject'
            });


            return fields;
        },

        fetch: function(first, pageSize) {
            var defer = $.Deferred();

            var params = {};
            params.pageSize = pageSize;
            params.first = first;

            if(this.fetchRequest)
                this.fetchRequest.abort();

            this.fetchRequest = $.ajax({
                type: 'GET',
                url: this.model.getMailsUrl(params),
                success: _.bind(function (items) {
                    var models = [];
                    _.each(items, _.bind(function(item) {
                        var model = App.request('mails:model', item);
                        models.push(model);
                    }, this));
                    defer.resolve(models);
                }, this)
            });

            return defer.promise();
        },

        fetchCount: function() {
            var defer = $.Deferred();

            if(this.fetchCountRequest)
                this.fetchCountRequest.abort();

            this.fetchCountRequest = $.ajax({
                type: 'GET',
                url: this.model.getMailsRowCountUrl({}),
                success: _.bind(function (counts) {
                    defer.resolve(counts.value);
                }, this)
            });

            return defer.promise();
        },

        onRowDoubleClick: function (view) {
            MailView.prototype.open(view.model, view.model.get('emailAddress').secId);
        }

    });
});
