define('containerView',[
  'module',
  'marionette',
  'backbone',
  'underscore'
], function (
    Module,
    Marionette,
    Backbone,
    _
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    className: 'container-view',
    childView: null,
    view: null,

    initialize: function () {
      if (!this.model)
        this.model = new Backbone.Model();
    },

    childViewOptions: function () {
      return {};
    },

    getChildViewOptions: function () {
      return _.extend({}, this.childViewOptions(), this.options, {
        model: this.model,
        parentView: this
      });
    },

    getChildView: function () {
      return this.childView;
    },

    getView: function () {
      return this.view;
    }
  });
});
