define('windowView',[
  'module',
  'marionette',
  'underscore',
  'events',
  'settings',
  'template!windowView'
], function (
  Module,
  Marionette,
  _,
  Events,
  Settings,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'window-view',
    top: 0,
    left: 0,
    //width: 1220,
    //height: 600,

    attributes: function () {
      var att = {};
      if (this.options.config.modal)
        att.modal = true;
      return att;
    },

    ui: {
      header: '.window-header',
      body: '.window-body',
      resizeButton: '.resize-button',
      title: '.window-title',
      minimizeButton: '.minimize-button',
      closeButton: '.close-button',
      fullSizeButton: '.full-size-button',
      smallSizeButton: '.small-size-button'
    },

    regions: {
      header: '.window-header',
      bodyRegion: '.window-body'
    },

    events: {
      'click @ui.minimizeButton': 'minimize',
      'click @ui.closeButton': 'close',
      'click @ui.fullSizeButton': 'onFullSize',
      'click @ui.smallSizeButton': 'onSmallSize',
      'dblclick @ui.header': 'onChangeFullSize',
      'mousedown': 'focus'
    },

    initialize: function () {
      Events.push(this);

      if (!this.options.config.modal) {
        this.$el.on('mousedown', this.onMouseDown.bind(this));
        $(document).on('mouseup', this.onMouseUp.bind(this));
        $(document).on('mousemove', this.onMouseMove.bind(this));
      }

      this.view = this.options.view;
      this.id = this.options.id;
      this.title = this.options.title;
      this.zIndex = this.options.zIndex;
      this.config = this.options.config;
      this.margin = this.config.margin;
      this.windows = this.options.windows;
      this.fullSize = false;
      this.minimized = false;

      if (this.windows.getFocusedView()) {
        this.top = this.windows.getFocusedView().top + 35;
        this.left = this.windows.getFocusedView().left + 5;
      } else {
        this.top = this.options.windows.fullSizeContainer[0].offsetTop + 50;
        this.left = this.options.windows.fullSizeContainer[0].offsetLeft + 50;
      }

      if (typeof this.config.width === 'string' && this.config.width.indexOf('%') > 0) {
        if (!this.config.modal)
          this.width = this.options.windows.fullSizeContainer[0].offsetWidth * (parseInt(this.config.width, 10) / 100);
        else
          this.width = this.options.windows.modalContainer[0].offsetWidth * (parseInt(this.config.width, 10) / 100);
      } else {
        this.width = this.config.width;
      }

      if (typeof this.config.height === 'string' && this.config.height.indexOf('%') > 0) {
        if (!this.config.modal)
          this.height = this.options.windows.fullSizeContainer[0].offsetHeight * (parseInt(this.config.height, 10) / 100);
        else
          this.height = this.options.windows.modalContainer[0].offsetHeight * (parseInt(this.config.height, 10) / 100);
      } else {
        this.height = this.config.height;
      }

      $(window).on('resize', _.bind(this.onWindowResize, this));
      //$(document).on('keydown', _.bind(this.onWindowKeydownHandler, this));
    },

    onDestroy: function () {
      Events.pop(this);
      this.$el.off('mousedown', this.onMouseDown.bind(this));
      $(document).off('mouseup', this.onMouseUp.bind(this));
      $(document).off('mousemove', this.onMouseMove.bind(this));
      $(window).off('resize', this.onWindowResize.bind(this));
      //$(document).off('keydown', this.onWindowKeydownHandler.bind(this));

      if (this.view) {
        //this.view.destroy();
      }
    },

    onRender: function () {
      if (this.options.bodyClass) {
        this.ui.body.addClass(this.options.bodyClass);
      }
      if (this.view && this.getRegion('bodyRegion'))
        this.getRegion('bodyRegion').show(this.view);

      if (this.title)
        this.ui.title.html(this.title);

      if(this.width)
        this.$el.css('width', this.width + 'px');

      if(this.height)
        this.$el.css('height', this.height + 'px');

      if(this.config.minWidth)
        this.$el.css('min-width', this.config.minWidth + 'px');

      if(this.config.maxWidth)
        this.$el.css('max-width', this.config.maxWidth + 'px');

      if(this.config.minHeight)
        this.$el.css('min-height', this.config.minHeight + 'px');

      if(this.config.maxHeight)
        this.$el.css('max-height', this.config.maxHeight + 'px');

      this.$el.css('top', this.top + 'px');
      this.$el.css('left', this.left + 'px');
      this.$el.css('z-index', this.zIndex);

      if (this.margin)
        this.ui.body.css('padding', 15);

      this.updateSize();
    },

    show: function () {
      this.$el.css('display', 'flex');
    },

    hide: function () {
      this.$el.css('display', 'none');
    },

    open: function () {
      this.options.windows.open(this);
    },

    focus() {
      this.options.windows.focus(this);
      this.trigger('focus');
    },

    onKeyDown: function(e) {
      if (e.which === 27 && this.config.modal) {
        e.preventDefault();
        e.stopPropagation();
        this.close();
        return false;
      }
    },

    minimize() {
      if (this.config.modal)
        return;

      this.minimized = true;
      this.hide();

      if (this.options.windows.toolsView)
        this.options.windows.toolsView.render();

      if (this.managmentView)
        this.managmentView.minimize();

      this.options.windows.minimize(this);
    },

    unminimize() {
      if (this.config.modal)
        return;

      this.minimized = false;
      this.show();

      if (this.options.windows.toolsView)
        this.options.windows.toolsView.render();

      if (this.managmentView)
        this.managmentView.unminimize();
    },

    onFullSize: function () {
      if (this.config.modal)
        return;

      this.fullSize = true;
      this.updateSize();

      _.each(this.options.windows.views, function (view) {
        if (view !== this) {
          view.hide();
        }
      }, this);
    },

    onSmallSize: function () {
      if (this.config.modal)
        return;

      this.fullSize = false;
      this.updateSize();

      _.each(this.options.windows.views, function (view) {
        if (view !== this) {
          if (!view.isMinimized())
            view.show();
        }
      }, this);
    },

    onWindowResize: function () {
      if (this.config.modal)
        return;

      if (this.fullSize) {
        this.$el.css('width', this.options.windows.fullSizeContainer[0].offsetWidth + 'px');
        this.$el.css('height', this.options.windows.fullSizeContainer[0].offsetHeight + 'px');
        this.$el.css('top', this.options.windows.fullSizeContainer[0].offsetTop + 'px');
        this.$el.css('left', this.options.windows.fullSizeContainer[0].offsetLeft + 'px');

        //this.ui.body.css('height', this.options.windows.fullSizeContainer[0].offsetHeight - 30 - 50 + 'px');
        //this.ui.body.css('max-height', this.options.windows.fullSizeContainer[0].offsetHeight - 30 - 50 + 'px');
      }
    },

    onChangeFullSize: function () {
      if (this.config.modal)
        return;

      this.fullSize = !this.fullSize;
      this.updateSize();
    },

    updateSize: function () {
      if (this.config.modal)
        return;

      if (this.fullSize) {
        this.ui.fullSizeButton.css('display', 'none');
        this.ui.smallSizeButton.css('display', 'flex');

        this.$el.css('width', this.options.windows.fullSizeContainer[0].offsetWidth + 'px');
        this.$el.css('height', this.options.windows.fullSizeContainer[0].offsetHeight + 'px');
        this.$el.css('top', this.options.windows.fullSizeContainer[0].offsetTop + 'px');
        this.$el.css('left', this.options.windows.fullSizeContainer[0].offsetLeft + 'px');

        this.$el.attr('fullsize', true);

      } else {
        this.ui.fullSizeButton.css('display', 'flex');
        this.ui.smallSizeButton.css('display', 'none');

        this.$el.css('width', this.width + 'px');
        this.$el.css('height', this.height + 'px');
        this.$el.css('top', this.top + 'px');
        this.$el.css('left', this.left + 'px');

        this.$el.attr('fullsize', false);
      }
    },

    onMouseDown: function (event) {
      if (event.which === 3 ||
          event.target.nodeName === 'TEXTAREA' ||
          event.target.nodeName === 'INPUT' ||
          event.target.nodeName === 'P')
        return;

      if (!this.fullSize) {
        if (event.target.closest('.resize-button')) {
          this.startX = event.clientX;
          this.startY = event.clientY;
          this.resizing = true;
        } else {
          if(event.target.closest('.window-header'))
          {
            this.startX = event.clientX;
            this.startY = event.clientY;
            this.dragging = true;
          }
        }
      }
    },

    onMouseMove: function (event) {
      if (this.dragging) {
        this.top += event.clientY - this.startY;
        this.left += event.clientX - this.startX;

        if(this.top < 0)
            this.top = 0;
            
        this.$el.css({
          top: this.top + 'px',
          left: this.left + 'px'
        });

        this.startX = event.clientX;
        this.startY = event.clientY;
      }

      if (this.resizing) {
        this.tmpWidth = this.width + event.clientX - this.startX;
        this.tmpHeight = this.height + event.clientY - this.startY;

        if (this.tmpWidth < this.config.minWidth)
          this.tmpWidth = this.config.minWidth;

        if (this.tmpHeight < this.config.minHeight)
          this.tmpHeight = this.config.minHeight;

        this.$el.css('width', this.tmpWidth + 'px');
        this.$el.css('height', this.tmpHeight + 'px');
      }
    },

    onMouseUp: function () {
      this.dragging = false;

      if (this.resizing) {
        this.resizing = false;
        this.width = this.tmpWidth;
        this.height = this.tmpHeight;
      }
    },

    close: function () {
      this.options.windows.close(this);
    },

    setTitle: function (title) {
      this.title = title;
      this.ui.title.html(title);
    },

    isMinimized: function () {
      return this.minimized;
    }
  });
});  
