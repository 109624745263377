
define('template!ordersDetailSupplierEditProductInfosOptionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="options item">\n  <div class="libelle">\n    <div class="text">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="value">' +
((__t = ( value )) == null ? '' : __t) +
'</div>\n  <div class="quantity">' +
((__t = ( quantity )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

