define('ordersBoardCardView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'moment',
  'settings',
  'entities/orders',
  'usersView',
  'ordersDeliveryView',
  'ordersPaymentView',
  'ordersValidationView',
  'ordersLabelsView',
  'template!ordersBoardCardView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  moment,
  Settings,
  Orders,
  UsersView,
  DeliveryView,
  PaymentView,
  ValidationView,
  LabelsView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'card-view',
    type: 'card',

    ui: {
      cardContent: '.card-content',
      date: '.header .date',
      clientNameContainer: '.client-name',
      clientName: '.client-name .text',
      vip: '.client-name .vip',
      productName: '.order .name',
      poi: '.order .poi',
      price: '.order .price',
      supplierName: '.order .supplier-name',
      productCode: '.order .product-code',
      productPicture: '.product-picture',
      productPictureImage: '.product-picture img',
      messages: '.messages',
      ukrMessages: '.ukr-messages',
      inboxEmails: '.mail',
      inboxEmailsCount: '.mail .count',
      comments: '.comments',
      commentsCount: '.comments .count',
      sameOrder: '.same-order',
      sameOrderCount: '.same-order .count',
      bank: '.bank',
      outsideEurope: '.outsideEurope',
      flag: '.flag',
      pim: '.pim',
      expressDelivery: '.express-delivery',
      country: '.country',
      seperator: '.seperator',
      more: '.more'
    },

    regions: {
      usersRegion: '.users-region',
      labelsRegion: '.labels-region',
      deliveryRegion: '.delivery-region',
      paymentRegion: '.payment-region',
      validationRegion: '.validation-region'
    },

    events: {
      'dblclick': 'onShowOrder',
      'click': 'onSelect',
      'mousedown': 'onMouseDown',
      'click @ui.more': 'onMore'
    },

    modelEvents: {
      'change:priority': 'renderPriority',
      'change:inboxEmailCount': 'renderContent',
      'change:actionRequiredInternal': 'renderContent',
      'change:actionRequiredUkr': 'renderContent',
      'change:hasComment': 'renderContent',
      'change:comments': 'renderContent',
      'change:nbrOrderSameCustomer': 'renderContent',
      'change:outsideEurope': 'renderContent',
      'change:flag': 'renderContent',
      'change:pim': 'renderContent',
      'change:expressShip': 'renderContent',
      'change:customer': 'renderContent',
      'change:productName': 'renderContent',
      'change:productNameDt': 'renderContent',
      'change:productOwner': 'renderContent',
      'change:productBaseCode': 'renderContent',
      'change:snapshotsUids': 'renderImage',
      'change:status': 'updateCards',
      'change:commandType': 'updateCards',
      'change:archived': 'updateCards',
      'change:userpids': 'renderUsers',
      'deleted': 'orderDeleted',
      'change:grouped': 'renderGrouped',
      'change:cardColor': function () {
        this.trigger('change:cardColor');
      },
      'change:minimized': 'renderMinimized'
    },

    attributes: function () {
      return {
        'title': this.renderTitle()
      };
    },

    initialize: function () {
      this.cards = this.options.cards;
      this.observer = this.options.observer;
    },

    onShow: function () {
      this.usersView = new UsersView({
        model: new Backbone.Model({userpids: this.model.get('userpids')}),
        onSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          this.model.assignUser(user.get('secId')).done(_.bind(function () {
            this.model.fetch();
            deferred.resolve();
          }, this));
          return deferred;
        }, this),
        onUnSelected: _.bind(function (user) {
          var deferred = new $.Deferred();
          this.model.unassignUser(user.get('secId')).done(_.bind(function () {
            this.model.fetch();
            deferred.resolve();
          }, this));
          return deferred;
        }, this)
      });
      this.getRegion('usersRegion').show(this.usersView);

      //--------------------
      if(Settings.configValue('orders.showValidationButton') === true) {
        this.validationView = new ValidationView({
          model: this.model,
          askConfirmation: true
        });
        this.getRegion('validationRegion').show(this.validationView);
      }

      //-------------------
      this.paymentView = new PaymentView({
        model: this.model,
        askConfirmation: true
      });
      this.getRegion('paymentRegion').show(this.paymentView);

      //--------------------
      this.deliveryView = new DeliveryView({
        model: this.model
      });
      this.getRegion('deliveryRegion').show(this.deliveryView);

      //--------------------
      this.labelsView = new LabelsView({
        model: this.model
      });
      this.getRegion('labelsRegion').show(this.labelsView);
    },

    onRender: function () {
      this.renderContent();
      this.renderImage();
      this.renderGrouped();
      this.renderPriority();
      this.renderMinimized();
      this.renderZIndex();
    },

    renderZIndex: function () {
      if(this.options.groupCardsView) {
        this.$el.css('z-index', 50 - (this.options.groupCardsView.collection.indexOf(this.model) % 50));
        this.ui.seperator.css('z-index', 50 - (this.options.groupCardsView.collection.indexOf(this.model) % 50));
      }
      else {
        this.$el.css('z-index', 1);
        this.ui.seperator.css('z-index', 1);
      }
    },

    renderMinimized: function () {
      if (this.model.get('minimized')) {
        this.$el.addClass('minimized');

        if(this.options.groupCardsView) {
          if(this.options.groupCardsView.collection.indexOf(this.model) > 2 && this.model.get('minimized') === true)
            this.$el.hide();
          else
            this.$el.css('display', 'flex');

          if(this.options.groupCardsView.collection.indexOf(this.model) === 2)
            this.ui.more.css('display', 'flex');
          else
            this.ui.more.hide();
        }
      }
      else {
        this.$el.removeClass('minimized');
        this.$el.css('display', 'flex');
        this.ui.more.hide();
      }
    },

    renderContent: function () {
      this.ui.messages.attr('data-poi', this.model.get('poi'));
      this.ui.ukrMessages.attr('data-poi', this.model.get('poi'));

      if (Settings.configValue('board.card.messageIcon') !== 'fromComments') {
        if (this.model.get('actionRequiredInternal'))
          this.ui.messages.show();
        else
          this.ui.messages.hide();

        if (this.model.get('actionRequiredUkr'))
          this.ui.ukrMessages.show();
        else
          this.ui.ukrMessages.hide();
      }

      if (Settings.configValue('advancedMode')) {
        if (this.model.get('inboxEmailCount') > 0) {
          this.ui.cardContent.css('background-color', 'rgb(244, 123, 0, 0.50)');
          this.ui.inboxEmails.show();
          this.ui.inboxEmailsCount.html(this.model.get('inboxEmailCount') );
          this.ui.inboxEmails.css('display', 'flex');
        } else {
          this.ui.cardContent.css('background-color', '#fff');
          this.ui.inboxEmails.hide();
        }
      }
      this.ui.comments.attr('data-poi', this.model.get('poi'));

      if (Settings.configValue('board.card.messageIcon') === 'fromComments') {
        var count = 0;
        _.each(this.model.get('comments').models, _.bind(function (comment) {
          if (comment.get('type') === 'order')
            count++;
        }, this));

        if (count > 0) {
          this.ui.commentsCount.html(count);
          this.ui.comments.css('display', 'flex');
        } else
          this.ui.comments.hide();
      }

      //-------------------
      if (this.model.get('nbrOrderSameCustomer') > 0) {
        this.ui.sameOrder.addClass('open');
        this.ui.sameOrderCount.html(this.model.get('nbrOrderSameCustomer'));
      } else {
        this.ui.sameOrder.removeClass('open');
      }

      //-------------------
      if (this.model.get('outsideEurope')) {
        this.ui.outsideEurope.show();
      }

      //-------------------
      if (this.model.get('flag') === 'E')
        this.ui.flag.show();
      else
        this.ui.flag.hide();


      //-------------------
      try {
        var date = this.model.get('createDate');
        if (date) {
          if (typeof (date) === 'object')
            date = moment.unix(date.time / 1000);
          else
            date = moment(date);

          if (Settings.configValue('board.card.fullDate') === true)
            this.ui.date.html(date.format('DD.MM.YY HH:mm'));
          else {
            var today = moment();
            if (date.isSame(today, 'day'))
              this.ui.date.html(date.format('HH:mm'));
            else
              this.ui.date.html(date.format('DD.MM.YY'));
          }
        }
      } catch (err) {}

      //-------------------

      if (this.model.get('webShopLocale')) {
        this.ui.country.css('display', 'flex');
        this.ui.country.html(this.model.get('webShopLocale'));
      }
      else
        this.ui.country.hide();

      //-------------------
      var clientName = this.model.getClientName();
      this.ui.clientName.html(clientName);
      this.ui.clientName.attr('title', clientName);

      //-------------------
      var productName = this.model.getProductName();
      this.ui.productName.html(productName);
      this.ui.productName.attr('title', productName);

      //-------------------
      if (this.model.get('productOwner'))
        this.ui.productName.attr('title', productName + ' (' + this.model.get('productOwner') + ')');

      //-------------------
      this.ui.poi.html(this.model.get('purchaseOrderId'));

      //-------------------
      var price = this.model.get('price');
      var buyingPrice = this.model.get('buyingPrice');
      var qty = this.model.get('quantity');

      var priceLocale = 'fr-BE';

      var configPriceLocale = Settings.configValue('priceLocale');
      if (configPriceLocale)
        priceLocale = configPriceLocale;

      var sPrice = new Intl.NumberFormat(priceLocale, {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(price);
      var html = '';
      if (price)
        html = '€ ' + sPrice;
      if (buyingPrice) {
        if (html !== '')
          html += ' - ';
        var margin = Math.round(((price - buyingPrice) / price) * 100);
        if (margin < 20) {
          html += '<span style="color:red;">' + margin + '%' + '</span>';
        } else {
          html += margin + '%';
        }
      }
      if (html !== '')
        html += ' - ';
      if (qty)
        html += qty + ' ' + _.i18n('common.pcs');
      this.ui.price.html(html);

      //-------------------
      if(this.model.get('productOwner')) {
        this.ui.supplierName.html(this.model.get('productOwner'));
        this.ui.supplierName.attr('title', this.model.get('productOwner'));
      }       

      //-------------------
      if(this.model.get('productBaseCode')) {
        this.ui.productCode.html(this.model.get('productBaseCode'));
        this.ui.productCode.attr('title', this.model.get('productBaseCode'));
      }

      //-------------------
      if (Settings.configValue('orders.showPimIcon') === true) {
        if (this.model.get('pim'))
          this.ui.pim.css('display', 'flex');
        else
          this.ui.pim.css('display', 'none');
      }

      //-------------------
      if (this.model.get('expressShip'))
        this.ui.expressDelivery.css('display', 'flex');
      else
        this.ui.expressDelivery.css('display', 'none');

      //-------------------
      if (this.model.get('customer') && this.model.get('customer').vip === true)
        this.ui.vip.css('display', 'flex');
      else
        this.ui.vip.css('display', 'none');
    },

    renderUsers: function () {
      this.usersView.allCollection.reset(this.model.get('userpids'));
      this.usersView.renderContent();
    },

    renderImage: function () {
      if (!this.model.get('snapshotsUids')) {
        this.ui.productPicture.hide();
        return;
      }

      this.ui.productPictureImage.on('error', _.bind(function() {
        this.ui.productPictureImage.css('display', 'none');
      }, this));
      this.ui.productPictureImage.attr('data-link', Settings.url('file', this.model.get('snapshotsUids'),
          {size: 'thumbnail', 'no-cache': new Date().getTime()}));
      this.ui.productPictureImage.attr('src', Settings.url('file', this.model.get('snapshotsUids'),
          {size: 'thumbnail', 'no-cache': new Date().getTime()}));
    },

    renderGrouped: function () {
      if (this.model.get('grouped'))
        this.$el.addClass('grouped');
      else
        this.$el.removeClass('grouped');

      //-------------------
      if(this.model.get('grouped'))
        this.ui.clientNameContainer.hide();
      else
        this.ui.clientNameContainer.css('display', 'flex');
    },

    renderPriority: function () {
      this.$el.attr('data-priority', this.model.get('priority'));
      this.trigger('change:priority');
    },

    renderTitle: function () {
      return '';
    },

    updateCards: function () {
      if(this.model.get('status') !== this.cards.status ||
          this.model.get('commandType') !== this.options.type ||
          this.model.get('archived') === true)
        this.cards.removeOrder(this.model);
    },

    orderDeleted: function () {
      this.cards.removeOrder(this.model);
    },

    select: function () {
      this.$el.addClass('selected');
      this.options.boardView.headerView.renderUnSelectButton(this.options.kanbanView.getSelectedOrders());
    },

    unSelect: function () {
      this.$el.removeClass('selected');
      this.options.boardView.headerView.renderUnSelectButton(this.options.kanbanView.getSelectedOrders());
    },

    isSelected: function () {
      return this.$el.hasClass('selected');
    },

    onSelect: function (e) {
      e.preventDefault();
      e.stopPropagation();

      this.options.kanbanView.hideSortPanels();
     
      if (this.$el.hasClass('selected'))
        this.unSelect();
      else {
        if (e.ctrlKey || e.altKey)  // alt instead ctrl for mac os
          this.select();
        else {
          this.options.kanbanView.unSelectAll();
          this.select();
        }
      }

      this.options.kanbanView.lastSelected = this;
    },

    onShowOrder: function () {
      this.options.kanbanView.hideSortPanels();
      Orders.showOrder(this.model.get('purchaseOrderId'), '');
    },

    onMouseDown: function (e) {
      this.options.kanbanView.lastSelected = this.model;
      if(e.which === 3) {
        e.stopPropagation();
        if (this.$el.hasClass('selected'))
          return;
        else {
          this.options.kanbanView.unSelectAll();
          this.select();
        }
      }
    },

    onMore: function (e) {
      e.stopPropagation();

      if(this.options.groupView) {
        this.options.groupView.changeMinimized(e);
      }
    }
  });
});
  
