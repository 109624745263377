define('customersDetailView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'headerFooterView',
  'customersDetailHeaderView',
  'customersDetailBodyView',
  'entities/caccounts'
], function (
  App,
  Module,
  Backbone,
  _,
  HeaderFooterView,
  HeaderView,
  BodyView
) {
  'use strict';

  Module.exports = HeaderFooterView.extend({
    className: 'customers-detail-view ' + HeaderFooterView.prototype.className,
    childHeaderView: HeaderView,
    childView: BodyView,

    initialize: function () {
      this.model = App.request('customers:entity', this.options.id);
    },

    onShow: function () {
      this.model.fetch().done(_.bind(function () {
        App.request('caccount:countries').done(_.bind(function (countries) {
          this.options.countries = countries;
          HeaderFooterView.prototype.onShow.call(this);
        }, this));
      }, this));
    }
  });
});

