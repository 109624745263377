
define('template!contentView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header-region"></div>\n\n<section  class="_compuzz-dashboard-body">\n  <section class="hbox stretch">\n    <!-- .aside -->\n    <aside id="sidebar-region" class="aside-md hidden-print hidden-xs"></aside>\n    <!-- /.aside -->\n    <section id="content"  class="_compuzz-dashboard-content" style="resize:horizontal;overflow: auto">\n      <section class="vbox">\n        <div id="gallery-zoom"></div>\n        <section id="content-region" class="scrollable"></section>\n        <div id="content-blinder"></div>\n      </section>\n      <div class="orders-detail-region"></div>\n    </section>\n  </section>\n  <label class="version-label" style="position: absolute; bottom: 5px; left: 10px; color: #999999; font-size: 10px;">F: ' +
((__t = ( version )) == null ? '' : __t) +
' B: ' +
((__t = ( bVersion )) == null ? '' : __t) +
'</label>\n</section>\n\n';

}
return __p
};});

