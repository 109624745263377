define('ordersProofsheetButtonView',[
  'app',
  'module',
  'marionette',
  'backbone',
  'underscore',
  'settings',
  'buttonView'
], function (
  App,
  Module,
  Marionette,
  Backbone, 
  _,
  Settings,
  ButtonView
) {
  'use strict';

  Module.exports = ButtonView.extend({
    className: 'orders-proofsheet-button-view ' + ButtonView.prototype.className,

    attributes: function () {
      return {
        'title': _.i18n('common.proofsheet'),
        'type': 'doc',
        'color': 'green'
      };
    },

    initialize: function(options) {
      this.options.text = _.i18n('common.proofsheet');
      this.options.type = 'doc';
      this.customer = options.customer;
    },

    open: function() {
      var locale = 'fr';
      var countryCode = '';

      if (this.customer.get('locales') && (this.customer.get('locales')).length >= 2)
        locale = (this.customer.get('locales')).substring(0, 2);

      if (this.customer.get('countryCode') && (this.customer.get('countryCode')).length >= 2)
        countryCode = (this.customer.get('countryCode')).substring(0, 2);

      var shopUrl = Settings.getWebshopUrl(locale, countryCode);
      var proofsheetUrl = Settings.configValue('orderDetail.webShopProofSheetUrl');

      if (shopUrl && proofsheetUrl) {
        var url = shopUrl + proofsheetUrl.replace('{poi}', this.options.poi);
        window.open(url, '_blank');
      }
    },

    onClick: function(e) {
      e.stopPropagation();
      e.preventDefault();

      if(this.customer)
        this.open();
      else {
        this.customer = App.request('customers:entity:end-user-id', this.options.endUserId);
        this.customer.fetch().done(_.bind(function() {
          this.open();
        }, this));
      }
    }
  });
});
  
