define('headerView',[
  'module',
  'marionette',
  'settings',
  'headerCompuzzView',
  'headerNotificationsView',
  'headerTasksView',
  'headerAccountView',
  'headerLanguagesView',
  'headerSearchView',
  'headerUserView',
  'windowsToolsView',
  'headerPhoneView',
  'rolesMixin',
  'template!headerView'
], function(
  Module,
  Marionette,
  Settings,
  CompuzzView,
  NotificationsView,
  TasksView,
  AccountView,
  LanguagesView,
  SearchView,
  UserView,
  WindowsToolsView,
  PhoneView,
  RolesMixin,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'main-header-view',
    tagName: 'header',

    regions: {
      compuzzRegion: '.compuzz-region', 
      accountRegion: '.account-region',     
      languagesRegion: '.languages-region', 
      searchRegion: '.search-region',
      notificationsRegion: '.notifications-region',
      tasksRegion: '.tasks-region',
      phoneRegion: '.phone-region',
      userRegion: '.user-region',
      windowsToolsRegion: '.windows-tools-region'
    },

    serializeData: function () {
      return {
        displayTasks: (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_TASKS]),
        displayNotifications: (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_TASKS])
      };
    },

    onShow: function () {
      this.compuzzView = new CompuzzView();     
      this.getRegion('compuzzRegion').show(this.compuzzView);

      this.accountView = new AccountView();     
      this.getRegion('accountRegion').show(this.accountView);

      this.languagesView = new LanguagesView();     
      this.getRegion('languagesRegion').show(this.languagesView);

      this.userView = new UserView();     
      this.getRegion('userRegion').show(this.userView);

      this.searchView = new SearchView();
      this.getRegion('searchRegion').show(this.searchView);

      this.windowsToolsView = new WindowsToolsView();
      this.getRegion('windowsToolsRegion').show(this.windowsToolsView);
    
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_TASKS]) {
        this.notificationsView = new NotificationsView();
        this.getRegion('notificationsRegion').show(this.notificationsView);

        this.tasksView = new TasksView();
        this.getRegion('tasksRegion').show(this.tasksView);
      }

      if (Settings.get('plivoUser') && Settings.get('plivoPassword')) {
        this.phoneView = new PhoneView();
        this.getRegion('phoneRegion').show(this.phoneView);
      }      
    }
  }); 
});
