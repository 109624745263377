define('webshopProductsDetailBodyProductBodyIndexView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'checkboxView'
], function (
  App,
  Module,
  Backbone,
  _,
  CheckboxView
) {
  'use strict';

  Module.exports = CheckboxView.extend({
    className: 'index-view ' + CheckboxView.prototype.className,

    attributes: function () {
      return _.extend({}, CheckboxView.prototype.attributes.apply(this, arguments), {
        'title': this.model.get('lang') || null
      });
    },

    initialize: function () {
      CheckboxView.prototype.initialize.apply(this, arguments);
      this.value = this.model.get('value');
      this.text = this.model.get('lang');
    },

    onChange: function (value) {
      var defer = $.Deferred();
      this.options.product.setIndex(this.model.get('lang'), value).done(_.bind(function () {
        this.options.product.fetch().done(_.bind(function () {
          //this.checkboxView.setValue(this.options.product.getIndex(this.model.get('lang')));
          defer.resolve();
        }, this));
      }, this)).fail(_.bind(function () {
        this.options.product.fetch().done(_.bind(function () {
          defer.reject();
        }, this));
      }, this));
      return defer.promise();
    }

    /*serializeData: function () {
      var data = {};
      data.lang = this.model.get('lang');
      return data;
    },*/

    /*onShow: function () {
      this.checkboxView = new CheckboxView({
        title: this.model.get('lang'),
        text: this.model.get('lang'),
        value: this.model.get('value'),
        onChange: _.bind(function (value) {
          var defer = $.Deferred();
          this.options.product.setIndex(this.model.get('lang'), value).done(_.bind(function () {
            this.options.product.fetch().done(_.bind(function () {
              //this.checkboxView.setValue(this.options.product.getIndex(this.model.get('lang')));
              defer.resolve();
            }, this));
          }, this)).fail(_.bind(function () {
            this.options.product.fetch().done(_.bind(function () {
              defer.reject();
            }, this));
          }, this));
          return defer.promise();
        }, this)
      });
      this.checkboxRegion.show(this.checkboxView);
      this.checkboxView.on('click', _.bind(this.onClick, this));

      //-----------------------------
      this.listenTo(this.options.product, 'change', _.bind(function (model) {
        if (model.hasChanged('seoIndexes')) {
          if(this.options.product.getIndex(this.model.get('lang')) !== this.checkboxView.getValue())
            this.checkboxView.setValue(this.options.product.getIndex(this.model.get('lang')));
        }
      }, this));

    },

    onClick: function (view) {
      this.model.set('value', view.getValue());
    }*/
  });
});
