
define('template!ordersDetailPriceView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div style="display: flex; justify-content: center;">\n            <div>\n                <table class="table">\n                    <tbody>\n                    <tr>\n                        <td>\n                            <label class="p-t-5" style="width: 100%; text-align: right;">Total: </label>\n                        </td>\n                        <td>\n                            <input type="number"\n                                   class="form-control floating-label js-info-input input-b-total-override"\n                                   placeholder="' +
((__t = ( _.i18n('price.override') )) == null ? '' : __t) +
'"\n                                   data-qty=""\n                                   value="" style="width: 150px;">\n                        </td>\n                    </tr>\n                    </tbody>\n                </table>\n            </div>\n            <div>\n                <table class="table">\n                    <tbody>\n                    <tr>\n                        <td>\n                            <label class="p-t-5" style="width: 100%; text-align: right;">Factor: </label>\n                        </td>\n                        <td>\n                            <input type="number"\n                                   class="form-control floating-label js-info-input input-b-total-factor-override"\n                                   placeholder="' +
((__t = ( _.i18n('price.override') )) == null ? '' : __t) +
'"\n                                   data-qty=""\n                                   value="1.43" style="width: 150px;">\n                        </td>\n                    </tr>\n                    </tbody>\n                </table>\n            </div>\n            <div>\n                <table class="table">\n                    <tbody>\n                    <tr>\n                        <td>\n                            <label class="p-t-5" style="width: 100%; text-align: right;">Total: </label>\n                        </td>\n                        <td>\n                            <input type="number"\n                                   class="form-control floating-label js-info-input input-s-total-override"\n                                   placeholder="' +
((__t = ( _.i18n('price.override') )) == null ? '' : __t) +
'"\n                                   data-qty=""\n                                   value="" style="width: 150px;">\n                        </td>\n                    </tr>\n                    </tbody>\n                </table>\n            </div>\n    </div>\n    <div class="col-lg-12 col-md-12 col-xs-12">\n        <table class="table">\n            <thead>\n            <tr>\n                <th></th>\n                <th>' +
((__t = ( _.i18n('price.buyingPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.savedBuyingPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.overridePrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.resellingPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.customPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.overridePrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.marginPrice') )) == null ? '' : __t) +
'</th>\n            </tr>\n            </thead>\n            <tbody>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.expressShipping') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-express-cost"></td>\n                <td class="lbl-saved-express-cost"></td>\n                <td class="lbl-b-express-override"></td>\n                <td class="lbl-rs-express-cost"></td>\n                <td class="lbl-rs-custom-express-cost"></td>\n                <td class="lbl-express-override"></td>\n                <td class="lbl-express-margin"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.shippingCost') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-shipping-cost"></td>\n                <td class="lbl-saved-shipping-cost"></td>\n                <td class="lbl-b-shipping-override">\n                    <input type="number" class="form-control floating-label js-info-input input-b-shipping-override"\n                           placeholder="' +
((__t = ( _.i18n('price.override') )) == null ? '' : __t) +
'"\n                           data-qty=""\n                           value="">\n                </td>\n                <td class="lbl-rs-shipping-cost"></td>\n                <td class="lbl-rs-custom-shipping-cost"></td>\n                <td class="lbl-shipping-override">\n                    <input type="number" class="form-control floating-label js-info-input input-shipping-override"\n                           placeholder="' +
((__t = ( _.i18n('price.override') )) == null ? '' : __t) +
'"\n                           data-qty=""\n                           value="">\n                </td>\n                <td class="lbl-shipping-margin"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.productCost') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-product-cost"></td>\n                <td class="lbl-saved-product-cost"></td>\n                <td class="lbl-b-product-override">\n                    <input type="number" class="form-control floating-label js-info-input input-b-product-override"\n                           placeholder="' +
((__t = ( _.i18n('price.override') )) == null ? '' : __t) +
'"\n                           data-qty=""\n                           value="">\n                </td>\n                <td class="lbl-rs-product-cost"></td>\n                <td class="lbl-rs-custom-product-cost"></td>\n                <td class="lbl-product-override">\n                    <input type="number" class="form-control floating-label js-info-input input-product-override"\n                           placeholder="' +
((__t = ( _.i18n('price.override') )) == null ? '' : __t) +
'"\n                           data-qty=""\n                           value="">\n                </td>\n                <td class="lbl-product-margin"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.totalProduct') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-total-product"></td>\n                <td class="lbl-saved-total-product"></td>\n                <td class="lbl-b-total-product-override"></td>\n                <td class="lbl-rs-total-product"></td>\n                <td class="lbl-rs-custom-total-product"></td>\n                <td class="lbl-total-product-override"></td>\n                <td class="lbl-mg-total-product"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.handlingCost') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-handling-cost"></td>\n                <td class="lbl-saved-handling-cost"></td>\n                <td class="lbl-b-handling-override"></td>\n                <td class="lbl-rs-handling-cost"></td>\n                <td class="lbl-rs-custom-handling-cost"></td>\n                <td class="lbl-handling-override"></td>\n                <td class="lbl-handling-margin"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.setupCost') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-setup-cost"></td>\n                <td class="lbl-saved-setup-cost"></td>\n                <td class="lbl-b-setup-override">\n                    <input type="number" class="form-control floating-label js-info-input input-b-printsetup-override"\n                           placeholder="' +
((__t = ( _.i18n('price.override') )) == null ? '' : __t) +
'"\n                           data-qty=""\n                           value="">\n                </td>\n                <td class="lbl-rs-setup-cost"></td>\n                <td class="lbl-rs-custom-setup-cost"></td>\n                <td class="lbl-setup-override">\n                    <input type="number" class="form-control floating-label js-info-input input-printsetup-override"\n                           placeholder="' +
((__t = ( _.i18n('price.override') )) == null ? '' : __t) +
'"\n                           data-qty=""\n                           value="">\n                </td>\n                <td class="lbl-setup-margin"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.printCosts') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-unit-cost"></td>\n                <td class="lbl-saved-unit-cost"></td>\n                <td class="lbl-b-unit-cost-override">\n                    <input type="number" class="form-control floating-label js-info-input input-b-printunit-override"\n                           placeholder="' +
((__t = ( _.i18n('price.override') )) == null ? '' : __t) +
'"\n                           data-qty=""\n                           value="">\n                </td>\n                <td class="lbl-rs-unit-cost"></td>\n                <td class="lbl-rs-custom-unit-cost"></td>\n                <td class="lbl-unit-cost-override">\n                    <input type="number" class="form-control floating-label js-info-input input-printunit-override"\n                           placeholder="' +
((__t = ( _.i18n('price.override') )) == null ? '' : __t) +
'"\n                           data-qty=""\n                           value="">\n                </td>\n                <td class="lbl-unit-cost-margin"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.totalPrint') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-total-print"></td>\n                <td class="lbl-saved-total-print"></td>\n                <td class="lbl-b-total-print-override"></td>\n                <td class="lbl-rs-total-print"></td>\n                <td class="lbl-rs-custom-total-print"></td>\n                <td class="lbl-total-print-override"></td>\n                <td class="lbl-mg-total-print"></td>\n            </tr>\n            <tr>\n                <td class="bold-label">' +
((__t = ( _.i18n('price.total') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-total bold-label"></td>\n                <td class="lbl-saved-total bold-label"></td>\n                <td class="lbl-b-total-override bold-label"></td>\n                <td class="lbl-rs-total bold-label"></td>\n                <td class="lbl-rs-custom-total bold-label"></td>\n                <td class="lbl-total-override bold-label"></td>\n                <td class="lbl-mg-total bold-label"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.totalNotPrinted') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-total-product"></td>\n                <td class="lbl-saved-total-product"></td>\n                <td class="lbl-b-total-notprinted-override">\n                <td class="lbl-rs-total"></td>\n                <td class="lbl-rs-custom-total"></td>\n                <td class="lbl-total-notprinted-override">\n                </td>\n                <td class="lbl-mg-total-notprinted"></td>\n            </tr>\n            <tr>\n                <td><label class="control-label">' +
((__t = ( _.i18n('price.printOurself') )) == null ? '' : __t) +
'</label></td>\n                <td colspan="2">\n                    <select class="print-ourself">\n                        <option value="no">' +
((__t = ( _.i18n('common.no') )) == null ? '' : __t) +
'</option>\n                        <!--<option value="mumuku">' +
((__t = ( _.i18n('price.mumuku') )) == null ? '' : __t) +
'</option>\n                        <option value="flex">' +
((__t = ( _.i18n('price.flex') )) == null ? '' : __t) +
'</option>-->\n                        <option value="flexDigital">' +
((__t = ( _.i18n('price.flexDigital') )) == null ? '' : __t) +
'</option>\n                        <!--<option value="flexThermo">' +
((__t = ( _.i18n('price.flexThermo') )) == null ? '' : __t) +
'</option>-->\n                    </select>\n                </td>\n                <td></td>\n                <td><label class="control-label">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</label></td>\n                <td colspan="3"><input type="text" class="form-control floating-label add-cost-desc" placeholder="' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'"></td>\n            </tr>\n            <tr>\n                <td></td>\n                <td></td>\n                <td></td>\n                <td></td>\n                <td><label class="control-label">' +
((__t = ( _.i18n('price.addCost') )) == null ? '' : __t) +
'</label></td>\n                <td colspan="3"><input type="number" class="form-control floating-label add-cost" placeholder="' +
((__t = ( _.i18n('price.addCost') )) == null ? '' : __t) +
'" value=""></td>\n            </tr>\n            <tr>\n                <td></td>\n                <td></td>\n                <td><button class="btn btn-primary js-confirm btn-buying-reset">' +
((__t = ( _.i18n('common.reset') )) == null ? '' : __t) +
'</button></td>\n                <td><button class="btn btn-primary js-confirm btn-buying-refresh">' +
((__t = ( _.i18n('common.refresh') )) == null ? '' : __t) +
'</button></td>\n                <td>';
 if (customScales) { ;
__p += '<i class="mdi-action-assignment-late custom-scales-icon big" title="' +
((__t = ( _.i18n('price.customScale') )) == null ? '' : __t) +
'"></i>';
 } ;
__p += '</td>\n                <td><button class="btn btn-primary js-confirm btn-reset">' +
((__t = ( _.i18n('common.reset') )) == null ? '' : __t) +
'</button></td>\n                <td><button class="btn btn-primary js-confirm btn-refresh">' +
((__t = ( _.i18n('common.refresh') )) == null ? '' : __t) +
'</button></td>\n                <td></td>\n            </tr>\n            </tbody>\n        </table>\n    </div>\n    <div class="col-md-8 col-xs-12">\n        <div class="pull-right p-r-15">\n        </div>\n    </div>\n    <div class="">\n        <table class="table">\n            <thead>\n            <tr>\n                <th>' +
((__t = ( _.i18n('common.quantity') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.buyingPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.buyingUnitPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.resellingPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.resellingUnitPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.marginPrice') )) == null ? '' : __t) +
'</th>\n            </tr>\n            </thead>\n            <tbody class="table-scale">\n            </tbody>\n        </table>\n    </div>\n    <div class="loading-block js-loading-block loader"></div>\n</div>';

}
return __p
};});

