
define('template!mailsMailRightCollapsedAttachmentView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="view-region">\n    <a href="' +
((__t = ( url )) == null ? '' : __t) +
'" download="' +
((__t = ( fileName )) == null ? '' : __t) +
'">' +
((__t = ( name )) == null ? '' : __t) +
'</a>\n</div>';

}
return __p
};});

