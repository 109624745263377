define('tabsViewContentView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'template!tabsViewContentView'
], function (
  App,
  Module, 
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'content-view',
    view: null,

    modelEvents: {
      'change': 'renderContent'
    },

    regions: {
      contentRegion: '.content-region'
    },

    onRender: function () {
      this.renderContent();
    },

    renderContent: function () {
      if (this.model.get('selected')) {
        this.$el.css('display', 'flex');

        require([this.model.get('view')], _.bind(function (View) {
          if (!this.view) {
            this.view = new View(this.options.viewOptions);
            this.getRegion('contentRegion').show(this.view);
          }
        }, this));
      } else {
        this.$el.hide();
      }
    }
  });
});
