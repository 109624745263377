
define('template!ordersDetailPriceV2PriceView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (buying || currentBuying) { ;
__p += '\n <th>';
 if (buying.priceCustom) { ;
__p += '<i class="mdi-action-assignment-late custom-scales-icon small" title="' +
((__t = ( _.i18n('price.customScale') )) == null ? '' : __t) +
'"></i>';
 } ;
__p +=
((__t = ( buying?buying.quantity:currentBuying.quantity )) == null ? '' : __t) +
'</th>\n<th>' +
((__t = ( buying?Number(buying.productUnitPrice).toFixed(2):currentBuying.productUnitPrice )) == null ? '' : __t) +
'€</th>\n<th>' +
((__t = ( buying?buying.totalSetupCost:currentBuying.totalSetupCost )) == null ? '' : __t) +
'€</th>\n<th>' +
((__t = ( buying?(buying.totalUnitCost / buying.quantity).toFixed(2):(currentBuying.totalUnitCost /
  currentBuying.quantity).toFixed(2) )) == null ? '' : __t) +
'€\n</th>\n<th>' +
((__t = ( buying?buying.totalHandlingCost||'0':currentBuying.totalHandlingCost||'0' )) == null ? '' : __t) +
'€</th>\n<th>' +
((__t = ( buying?buying.shippingCost:currentBuying.shippingCost )) == null ? '' : __t) +
'€</th>\n<th>' +
((__t = ( buying?buying.expressShippingCost:currentBuying.expressShippingCost )) == null ? '' : __t) +
'€</th>\n<th>' +
((__t = ( buying?buying.smallOrderFee?buying.smallOrderFee:0:currentBuying.smallOrderFee?currentBuying.smallOrderFee:0
  )) == null ? '' : __t) +
'€\n</th>\n<th>\n  <table class="table-noborder">\n    <tr>\n      <th>' +
((__t = ( currentBuying?currentBuying.total:'' )) == null ? '' : __t) +
'€</th>\n    </tr>\n    <tr>\n      <th>\n        ';
 if (buying) { ;
__p += '\n        <input type="text" class="txt-price"\n               style="display: none; height: 20px; width: 50px;"\n               value="' +
((__t = ( buying.total )) == null ? '' : __t) +
'"/>\n        <span class="lbl-price">' +
((__t = ( buying.total )) == null ? '' : __t) +
'€</span>\n        ';
 } ;
__p += '\n    </tr>\n  </table>\n</th>\n<th>\n\n</th>\n';
 } else { ;
__p += '\n<th colspan="9"></th>\n';
 } ;
__p += '\n\n';
 if (selling && currentSelling) { ;
__p += '\n<th>\n  <table class="table-noborder">\n    <tr>\n      <th>' +
((__t = ( currentSelling?Number(currentSelling.totalUnit).toFixed(2)+'€':'' )) == null ? '' : __t) +
'</th>\n    </tr>\n    <tr>\n      <th style="position: relative;">\n        ';
 if (selling) { ;
__p += '\n        <input type="text" class="txt-suprice"\n               style="display: none; height: 20px; width: 50px;"\n               value="' +
((__t = ( Number(selling.totalUnit).toFixed(2) )) == null ? '' : __t) +
'"/>\n        <span class="lbl-suprice">' +
((__t = ( Number(selling.totalUnit).toFixed(2) )) == null ? '' : __t) +
'€</span>\n        ';
 } ;
__p += '\n      </th>\n    </tr>\n  </table>\n</th>\n<th>\n  <table class="table-noborder">\n    <tr>\n      <th>';
 if (currentSelling) { ;
__p +=
((__t = ( Number(currentSelling.total).toFixed(2) )) == null ? '' : __t) +
'€';
 } ;
__p += '</th>\n    </tr>\n    <tr>\n      <th style="position: relative;">\n        ';
 if (selling) { ;
__p += '\n        <input type="text" class="txt-sprice"\n               style="display: none; height: 20px; width: 50px;"\n               value="' +
((__t = ( selling.total )) == null ? '' : __t) +
'"/>\n        <span class="lbl-sprice">' +
((__t = ( Number(selling.total).toFixed(2) )) == null ? '' : __t) +
'€</span>\n        ';
 } ;
__p += '\n      </th>\n    </tr>\n  </table>\n</th>\n<th>\n\n</th>\n<th>\n  <table class="table-noborder">\n    <tr>\n      <th>';
 if (currentSelling) { ;
__p +=
((__t = ( currentSelling.unitMargin )) == null ? '' : __t) +
'€';
 } ;
__p += '</th>\n    </tr>\n    <tr>\n      <th>';
 if (selling) { ;
__p +=
((__t = ( selling.unitMargin )) == null ? '' : __t) +
'€';
 } ;
__p += '</th>\n    </tr>\n  </table>\n</th>\n<th>\n  <table class="table-noborder">\n    <tr>\n      <th>';
 if (currentSelling) { ;
__p +=
((__t = ( currentSelling.totalMargin )) == null ? '' : __t) +
'€';
 } ;
__p += '</th>\n    </tr>\n    <tr>\n      <th>';
 if (selling) { ;
__p +=
((__t = ( selling.totalMargin )) == null ? '' : __t) +
'€';
 } ;
__p += '</th>\n    </tr>\n  </table>\n</th>\n<th>\n  <table class="table-noborder">\n    <tr>\n      <th>';
 if (currentSelling) { ;
__p +=
((__t = ( currentSelling.expressShippingCost )) == null ? '' : __t) +
'€';
 } ;
__p += '</th>\n    </tr>\n    <tr>\n      <th>';
 if (selling) { ;
__p +=
((__t = ( selling.expressShippingCost )) == null ? '' : __t) +
'€';
 } ;
__p += '</th>\n    </tr>\n  </table>\n</th>\n<th colspan="2">\n  <table class="table-noborder">\n    <tr>\n      <th>';
 if (currentSelling) { ;
__p +=
((__t = ( currentSelling.percentMargin )) == null ? '' : __t) +
'%';
 } ;
__p += '</th>\n    </tr>\n    <tr>\n      <th>';
 if (selling) { ;
__p +=
((__t = ( selling.percentMargin )) == null ? '' : __t) +
'%';
 } ;
__p += '</th>\n    </tr>\n  </table>\n</th>\n<th>\n  <table class="table-noborder">\n    <tr>\n      <th>new</th>\n    </tr>\n    <tr>\n      <th>';
 if(selling && selling.priceCustom && selling.priceCustom.totalPrice)
        { ;
__p += 'manual';
 } else { ;
__p += 'saved';
 } ;
__p += '\n      </th>\n    </tr>\n  </table>\n</th>\n';
 } else { ;
__p += '\n<th>\n  <table class="table-noborder">\n    <tr>\n      <th></th>\n    </tr>\n    <tr>\n      <th>' +
((__t = ( selling?Number(selling.totalUnit).toFixed(2)+'€':'' )) == null ? '' : __t) +
'</th>\n    </tr>\n  </table>\n</th>\n<th>\n  <table class="table-noborder">\n    <tr>\n      <th></th>\n    </tr>\n    <tr>\n      <th>';
 if (selling) { ;
__p +=
((__t = ( selling.total )) == null ? '' : __t) +
'€';
 } ;
__p += '</th>\n    </tr>\n  </table>\n</th>\n\n';
 } ;


}
return __p
};});

