define('ordersDetailSupplierEditProductInfosOptionView',[
  'module',
  'marionette',
  'underscore',
  'template!ordersDetailSupplierEditProductInfosOptionView'
], function (
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.ItemView.extend({
    className: 'option-view',
    template: Tpl,

    serializeData: function () {
      var data = {};

      data.name = '';
      data.value = '';
      data.quantity = this.model.get('quantity');

      _.each(this.options.product.get('productOptionCategories'), function (category) {
        _.each(category.productOptions, function(option) {
          if(option.code === this.model.get('code')) {
            if(category.id === option.catId) {
              data.name = category.name;
            }
            data.value = option.name;
          }
          if(this.model.get('sizeCode') && option.code === this.model.get('sizeCode')) {
            if(category.id === option.catId) {
              data.name += ' / ' + category.name;
            }
            data.value += ' / ' + option.name;
          }
        }, this);
      }, this);

      return data;
    }
  });
});
