/**
 * Created by BFR on 24/02/2020.
 */
define('editPimCatView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!editPimCatTpl',
    'dynamicTextView',
    'editPimOptionsView',
    'pimUtil'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl,
    DynamicTextView,
    EditPimOptionsView,
    PimUtil
  ) {
    'use strict';
  
    var EditPimCatView =  Marionette.LayoutView.extend({
      template: viewTpl,
      className: 'cat-item',
  
      regions: {
        editOptions: '.options-region',
        nameRegion: '.cat-name-region'
      },

      events: {
          'change .cbx-enable-mixable': 'onCbxEnableMixableChange'
      },

      onCbxEnableMixableChange: function (event) {
        var cbx = $(event.target);
        if (cbx.is(':checked')) {
            this.model.set('mixable', true);
        } else {
            this.model.set('mixable', false);
        }
      },

      onShow: function () {
          if (this.model.get('name').get('glossaryDt')) {
              this.nameDtView = new DynamicTextView({
                  model: this.model.get('name').get('glossaryDt'),
                  hideGlossary: true,
                  showWebIcon: true
              });
              this.getRegion('nameRegion').show(this.nameDtView);
          }

        this.editPimOptionsView = new EditPimOptionsView({
          pimProduct: this.options.pimProduct, 
          collection: this.model.get('productOptions'),
          enableOnOff: true
        });
        this.getRegion('editOptions').show(this.editPimOptionsView);
      },
  
      serializeData: function () {
        var templateData = {};

        templateData.code = this.model.get('code');

        var aNames = this.model.get('name').get('values');

        templateData.name = PimUtil.getTranslation(aNames);
        templateData.mixable = !!this.model.get('mixable');

        templateData.fromCompuzz = this.model.get('fromCompuzz');

        var currentResProduct = this.options.pimProduct ? this.options.pimProduct.getCurrentResProduct() : null;
        if (currentResProduct) {
          if (currentResProduct.get('pimPoResellers')) {
              _.each(currentResProduct.get('pimPoResellers').models, _.bind(function (poc) {
                  if (poc && poc.get('pimId') === this.model.get('pimId') && poc.get('mixable')) {
                    templateData.mixable = poc.get('mixable');
                  }
              }, this));
          }
        }

        return templateData;
      }
    });
  
    module.exports = EditPimCatView;
  });
