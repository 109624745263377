define('compositeView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'containerView',
  'template!compositeView'
], function (
    App,
    Module,
    Backbone,
    _,
    ContainerView,
    Tpl
) {
  'use strict';

  Module.exports = ContainerView.extend({
    template: Tpl,
    className: 'composite-view',
    childView: null,

    initialize: function () {
      this.collection = new Backbone.Collection();
    },

    regions: _.extend({}, ContainerView.prototype.regions, {
      collectionRegion: '.collection-region'
    }),

    onShow: function () {
      if(!this.childView)
        return;

      var View = Backbone.Marionette.CollectionView.extend({
        childView: this.getChildView(),
        className: 'composite-collection-view'
      });
      this.collectionView = new View({
        collection: this.collection
      });
      this.collectionView.childViewOptions = this.childViewOptions();
      this.getRegion('collectionRegion').show(this.collectionView);
    }
  });
});
