define('emailAttachmentView',[
  'module',
  'windows',
  'marionette',
  'underscore',
  'emailAttachmentDefaultView',
  'emailAttachmentImageView',
  'emailAttachmentPdfView',
  'template!emailAttachmentView'
], function (
  Module,
  Windows,
  Marionette,
  _,
  DefaultView,
  ImageView,
  PdfView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'email-attachment-view',     
    
    ui: {
      name: '.name',
      remove: '.delete',
      reload: '.reload',
      pdfCanvas: '.pdf-canvas',
      image: '.image',
      loading: '.loading'
    },

    events: {
      'click': 'onClick',
      'click @ui.remove': 'onDelete',
      'click @ui.reload': 'onReload'
    },

    regions: {
      viewRegion: '.view-region'
    },

    serializeData: function () {
      return {
        name: this.model.get('name')
      };
    },

    onShow: function() {
      if(this.model.get('content-type') === 'application/pdf')
      {
        this.view = new PdfView({model: this.model});
        this.view.on('click', _.bind(function() {
          require([
            'windows',
            'emailAttachmentPdfView'
          ], _.bind(function (Windows, View) {
            Windows.open({
              view: _.bind(function () {
                return new View({
                  model: this.model,
                  zoom: true
                });
              }, this),
              modal: true,
              title: this.model.get('name'),
              height: '80%',
              width: '60%'
            });
          }, this));
        } , this));
      }

      if(this.model.get('content-type') === 'image/jpeg' ||
          this.model.get('content-type') === 'image/jpg' ||
          this.model.get('content-type') === 'image/png' ||
          this.model.get('content-type') === 'image/x-png')
      {
        this.view = new ImageView({model: this.model});
        this.view.on('click', _.bind(function () {
          require([
            'windows',
            'emailAttachmentImageView'
          ], _.bind(function (Windows, View) {
            Windows.open({
              view: _.bind(function () {
                return new View({
                  model: this.model,
                  zoom: true
                });
              }, this),
              modal: true,
              title: this.model.get('name'),
              height: '80%',
              width: '60%'
            });
          }, this));
        }, this));
      }

      if(!this.view)
        this.view = new DefaultView({model: this.model});

      this.getRegion('viewRegion').show(this.view);

      this.fetch().done(_.bind(function () {
        this.trigger('loaded');
      }, this));
    },

    fetch: function() {
      var defer = $.Deferred();
      if(this.model.get('local') === true) {
        this.model.set('error', null);
        this.ui.reload.hide();
        return defer.resolve();
      }
      else {
        var req  = new XMLHttpRequest();
        req.addEventListener('load', _.bind(function (result) {
          if(result.currentTarget.status === 200) {
            this.model.set('arrayBuffer', result.currentTarget.response);
            this.model.set('base64', this.arrayBufferToBase64(this.model.get('arrayBuffer')));
            this.model.set('content-disposition', 'attachment; filename=' + this.model.get('name'));
            this.model.set('error', null);
          } else {
            this.model.set('error', result.currentTarget.status);
          }

          this.model.set('loaded', true);

          defer.resolve();
        }, this));
        req.open('GET', this.model.get('url'));
        req.responseType = 'arraybuffer';
        req.send();
      }
      return defer.promise();
    },

    onDelete: function () {
      this.trigger('deleted');
    },

    onReload: function () {
      this.model.set('loaded', false);
      this.model.set('error', null);

      setTimeout(_.bind(function() {
        this.fetch().done(_.bind(function () {
          this.trigger('loaded');
        }, this));
      }, this), 1000);
    },

    arrayBufferToBase64: function (buffer) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }
  });
});
  
