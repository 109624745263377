define('mailsMailBodyAndHeaderView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'dateUtils',
  'moment',
  'mailsMailHeaderView',
  'mailsMailMailTextView',
  'template!mailsMailBodyAndHeaderView',
  'entities/mails'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  DateUtils,
  moment,
  EmailHeaderView,
  EmailTextView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'mailBodyAndHeader-view',

    regions: {
      headerRegion: '.header-region',
      bodyRegion: '.body-region'
    },

    onShow: function () {
      this.headerView = new EmailHeaderView({
        model: this.model,
        mailSecId: this.options.mailSecId,
        parentView: this.options.parentView
      });
      this.getRegion('headerRegion').show(this.headerView);

      this.bodyView = new EmailTextView({
        model: this.model,
        mailSecId: this.options.mailSecId
      });
      this.getRegion('bodyRegion').show(this.bodyView);
    }

  });
});

