define('ordersDetailBodyTabsView',[
  'app',
  'module',
  'marionette',
  'backbone',
  'settings',
  'underscore', 
  'rolesMixin',
  'ordersDetailBodyTabView'
], function (
  App,
  Module,
  Marionette,
  Backbone,
  Settings,
  _,
  RolesMixin,
  TabView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'orders-detail-body-tabs-view',
    childView: TabView, 

    childEvents: {
      'select': 'onSelect'
    },

    initialize: function () {
      this.collection = new Backbone.Collection();      
    },

    onShow: function () {

      var test = false;

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DETAIL] || test) {
        this.collection.push({
          name: 'details',
          title: _.i18n('orders.details.tabTitle'),
          role: RolesMixin.DASHBOARD_ORDERS_DETAIL
        });
      }
     
      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DETAIL_OLD] || test) {
          this.collection.push({
          name: 'details_old',
          title: _.i18n('orders.details.tabTitle-OLD'),
          role: RolesMixin.DASHBOARD_ORDERS_DETAIL_OLD
        });
      }

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_PRICE] || test) {
          this.collection.push({
          name: 'pricev2',
          title: _.i18n('orders.pricev2.tabTitle'),
          role: RolesMixin.DASHBOARD_ORDERS_PRICE
        });
      }

      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_PRICE_OLD] || test) {
          this.collection.push({
          name: 'price',
          title: _.i18n('orders.price.tabTitle'),
          role: RolesMixin.DASHBOARD_ORDERS_PRICE_OLD
        });
      }
      
      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_PERSONALIZATION] || test) {
          this.collection.push({
          name: 'composition',
          title: _.i18n('orders.composition.tabTitle'),
          role: RolesMixin.DASHBOARD_ORDERS_PERSONALIZATION
        });
      }
      
      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_ATTACHMENT] || test) {
          this.collection.push({
          name: 'attachments',
          title: _.i18n('orders.attachments.tabTitle'),
          role: RolesMixin.DASHBOARD_ORDERS_ATTACHMENT
        });
      }
      
      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_TRACKING] || test) {
          this.collection.push({
          name: 'tracking',
          title: _.i18n('orders.tracking.tabTitle'),
          role: RolesMixin.DASHBOARD_ORDERS_TRACKING
        });
      }

        if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CALLS] || test) {
            this.collection.push({
                name: 'calls',
                title: _.i18n('endUsersDetail.calls.tabTitle'),
                role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CALLS
            });
        }

        if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_MAILS] || test) {
            this.collection.push({
                name: 'mails',
                title: _.i18n('menu.mails'),
                role: RolesMixin.DASHBOARD_ORDERS_MAILS
            });
        }
      
      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_INTERNAL_MESSAGES] || test) {
          this.collection.push({
          name: 'internal_messages',
          title: _.i18n('tab.messagesCs'),
          role: RolesMixin.DASHBOARD_ORDERS_INTERNAL_MESSAGES
        });
      }
      
      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_INVOICES] || test) {
          this.collection.push({
          name: 'invoices',
          title: _.i18n('cAccountDetail.invoices.tabTitle'),
          role: RolesMixin.DASHBOARD_ORDERS_INVOICES
        });
      }
      
      if(Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_PROOFSHEET_HISTORY] || test) {
          this.collection.push({
          name: 'order_proofsheet_history',
          title: _.i18n('cAccountDetail.proofsheetHistory.tabTitle'),
          role: RolesMixin.DASHBOARD_ORDERS_PROOFSHEET_HISTORY
        });
      }

      var cAccountConfig = Settings.get('cAccountConfig');
      if((Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_TO_SUPPLIER] || test) && cAccountConfig &&
          cAccountConfig.get('supplierWSConfigs') && _.findWhere(cAccountConfig.get('supplierWSConfigs'),
          { supplierCode: this.model.get('productOwner')})) {
          this.collection.push({
            name: 'order_supplier',
            title: _.i18n('cAccountDetail.orderSupplier.tabTitle'),
            role: RolesMixin.DASHBOARD_ORDERS_TO_SUPPLIER
          });
      }
    },

    setSelected: function (tabName) {
      this.collection.each(function (model) {
        model.set('selected', model.get('name') === tabName);
      });
    },

    onSelect: function (view) {
      this.setSelected(view.model.get('name'));

      var currentRoute = App.getCurrentRoute(),
      routeItems = currentRoute.split('/');
     
      this.collection.each(function (model) {
        if(_.indexOf(routeItems, model.get('name')) !== -1) {
          routeItems.pop();
          routeItems.push(view.model.get('name'));    
   
          App.navigate(routeItems.join('/'), {trigger: true});
          return;
        }
      });
    }
  });
});

