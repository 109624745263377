define('inputView',[
  'module',
  'marionette'
], function (
  Module,
  Marionette
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    className: 'input-view',

    attributes: function () {
      return {
        'title': this.options.title || null,
        'tabindex': this.options.tabindex || 0
      };
    }
  });
});
  
