define('webshopCategoriesDetailBodyCategoryView',[
  'module',
  'headerFooterView',
  'webshopCategoriesDetailBodyCategoryBodyView',
  'webshopCategoriesDetailBodyCategoryFooterView'
], function (
  Module,
  HeaderFooterView,
  BodyView,
  FooterView
) {
  'use strict';

  Module.exports = HeaderFooterView.extend({
    childView: BodyView,
    childFooterView: FooterView
  });
});
