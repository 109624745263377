define('ordersDetailTrackingListHeaderToolsView',[
  'app',
  'module',
  'underscore',
  'buttonView',
  'tableHeaderFieldView',
  'ordersDetailTrackingEditView',
  'template!ordersDetailTrackingListHeaderToolsView'
], function (
  App,
  Module,  
  _,
  ButtonView,
  FieldView,
  EditView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-detail-tracking-list-header-tools-view ' + FieldView.prototype.className,

    regions: {
      addRegion: '.add-region'
    },

    onRender: function() {
      this.addButtonView = new ButtonView({
        type: 'add',
        color: 'orange',
        text: _.i18n('orders.tracking.add'),
        title: _.i18n('orders.tracking.add')
      });
      this.addRegion.show(this.addButtonView);
      this.addButtonView.on('click', _.bind(this.onAdd, this));
    },

    onAdd: function() {
      EditView.prototype.open(
        App.request('tracking-tab:new-model'),
        this.tableView.model,
        _.bind(function(model) {
          this.tableView.bodyView.addRow(model);
        }, this));
    }
  });
});
