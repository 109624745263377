
define('template!editPimCatTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="category">\n    <div class="row">\n        <div class="col-xs-4 cat">\n            <div class="cat">\n                <div class="row">               \n                    <div class="col-xs-9">\n                        <div class="cat-code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n                    </div>\n                </div>\n                <div class="row">\n                    <div class="col-xs-12">\n                        <div class="cat-info">\n                            <div class="cat-name">' +
((__t = ( name )) == null ? '' : __t) +
' <div class="glossary-dt-value cat-name-region"></div></div>\n                        </div>                    \n                    </div>\n                </div>\n                ';
 if (!fromCompuzz) { ;
__p += '\n                <div class="row">\n                    <div class="p-l-10 col-xs-12">\n                        <label>' +
((__t = ( _.i18n('common.mixable') )) == null ? '' : __t) +
'</label>\n                        <input type="checkbox" class="cbx-enable-mixable" ' +
((__t = ( mixable===false?'':'checked' )) == null ? '' : __t) +
' />\n                    </div>\n                </div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        <div class="col-xs-8 options">\n            <div class="options">\n                <div class="options-title">Options</div>\n                <div class="row options-header">\n                    <div class="col-xs-2 code">Code</div>\n                    <div class="col-xs-1 onOff">On/Off</div>\n                    <div class="col-xs-4 name">Name</div>\n                    <div class="col-xs-1 color">Color</div>\n                    <div class="col-xs-4 images">Images</div>              \n                </div>\n                <div class="options-region"></div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

