
define('template!ordersDetailBodyView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="tabs-region"></div>\n<div class="body-container">\n  <div class="tabs-body">\n    <div class="details region"></div>\n    <div class="details_old region"></div>\n    <div class="pricev2 region"></div>\n    <div class="price region"></div>\n    <div class="composition region"></div>\n    <div class="attachments region"></div>\n    <div class="tracking region"></div>\n    <div class="calls region"></div>\n    <div class="order_proofsheet_history region"></div>\n    <div class="invoices region"></div>\n    <div class="mails region"></div>\n    <div class="customer_messages region"></div>\n    <div class="internal_messages region"></div>\n    <div class="all_customer_messages region"></div>\n    <div class="order_supplier region"></div>\n  </div>\n  <div class="customer-internal-comments-region"></div>\n</div>\n';

}
return __p
};});

