define('customersDetailBodyView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'customerInternalCommentsView',
  'customersDetailBodyTabsView',
  'template!customersDetailBodyView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  CustomerInternalCommentsView,
  CustomersDetailBodyTabsView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'customers-detail-body-view',

    ui: {      
      loading: '.loading'
    },  

    regions: {
      tabsRegion: '.tabs-region',
      customerCommentsRegion: '.customer-internal-comments-region',
      profileRegion: '.profile.region',
      attachmentsRegion: '.attachments.region',
      callsRegion: '.calls.region',
      mailsRegion: '.mails.region',
      ordersRegion: '.orders.region',
      offersRegion: '.offers.region',
      contactsRegion: '.contacts.region',
      customerMessagesRegion: '.customer-messages.region',
      invoicesRegion: '.invoices.region',
      tasksRegion: '.tasks.region'
    },

    loading: function(value) {
      if(value)
        this.ui.loading.addClass('activated');
      else
        this.ui.loading.removeClass('activated');
    },

    onShow: function() {
      this.tabsView = new CustomersDetailBodyTabsView({
        model: this.model
      });
      this.getRegion('tabsRegion').show(this.tabsView);
      this.tabsView.setSelected(this.options.tabName);
      this.tabsView.on('select', _.bind(this.selectTab, this));
      this.selectTab(this.tabsView.getSelected());

      //-------------------

      var customerInternalCommentsView = new CustomerInternalCommentsView({
        secId: this.model.get('secId')
      });
      this.getRegion('customerCommentsRegion').show(customerInternalCommentsView);
    },

    selectTab: function(tab) {
      this.$el.find('.region').removeClass('selected');
      this.$el.find('.' + tab.get('name') + '.region').addClass('selected');

      this.trigger('selectTab', tab);

      App.navigate('customers/' + this.model.get('secId') + '/' + tab.get('name'));

      switch (tab.get('name')) {
        case 'profile':
          require([            
            'customersDetailBodyProfileView'
          ], _.bind(function (View) {
            if(!this.profileView) {
              this.profileView = new View({
                model: this.model,
                countries: this.options.countries
              });
              this.getRegion('profileRegion').show(this.profileView);
            }
          }, this));
          break;
        case 'attachments':
          require([
            'customersDetailBodyAttachmentsView'
          ], _.bind(function (View) {
            if(!this.attachmentsView) {
              this.attachmentsView = new View({
                model: this.model
              });
              this.getRegion('attachmentsRegion').show(this.attachmentsView);
            }
          }, this));
          break;
        case 'contacts':
          require([
            'customersDetailBodyContactsController'
          ], _.bind(function (Controller) {
            var data = {
              model: this.model,
              id: this.model.get('secId'),
              secId: null,
              tab: 'contacts'
            };
            Controller.showTab(data, this.getRegion('contactsRegion'));
          }, this));
          break;
        case 'orders':
          require([
            'customersDetailBodyOrdersView'
          ], _.bind(function (View) {
            if(!this.ordersView) {
              this.ordersView = new View({
                type: 'command',
                endUserId: this.model.get('endUserId')
              });
              this.getRegion('ordersRegion').show(this.ordersView);
            }
          }, this));
          break;
        case 'offers':
          require([
            'customersDetailBodyOffersView'
          ], _.bind(function (View) {
            if(!this.offersView) {
              this.offersView = new View({
                model: this.model,
                type: 'offer',
                endUserId: this.model.get('endUserId')
              });
              this.getRegion('offersRegion').show(this.offersView);
            }
          }, this));
          break;
        case 'calls':
          require([
            'customersDetailBodyCallsView'
          ], _.bind(function (View) {
            if(!this.callsView) {
              this.callsView = new View({
                model: this.model
              });
              this.getRegion('callsRegion').show(this.callsView);
            }
          }, this));
          break;
        case 'mails':
          require([
            'customersDetailBodyMailsController'
          ], _.bind(function (Controller) {
            var data = {
              model: this.model,
              id: this.model.get('secId'),
              secId: null
            };
            Controller.showTab(data, this.getRegion('mailsRegion'));
          }, this));
          break;

        case 'invoices':
          require([
            'customersDetailBodyInvoicesView'
          ], _.bind(function (View) {
            if(!this.callsView) {
              this.callsView = new View({
                model: this.model
              });
              this.getRegion('invoicesRegion').show(this.callsView);
            }
          }, this));
          break;

        case 'tasks':
          require([
            'customersDetailBodyTasksView'
          ], _.bind(function (View) {
            if (!this.callsView) {
              this.callsView = new View({
                model: this.model
              });
              this.getRegion('tasksRegion').show(this.callsView);
            }
          }, this));
          break;
      }
    }
  });
});

