define('tabsViewTabsView',[
  'app',
  'module',
  'marionette',
  'backbone',
  'underscore',
  'tabsViewTabView'
], function (
  App,
  Module,
  Marionette,
  Backbone,
  _,
  TabsViewTabView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'tabs-view',
    childView: TabsViewTabView,
    selectedTab: '',

    childEvents: {
      'select': 'onSelect'
    },

    onSelect: function (view) {
      this.selectedTab = view.model.get('name');
      this.triggerMethod('selected', view.model.get('name'));
    }
  });
});
