define('ordersListHeaderToolsView',[
  'app',
  'module',
  'underscore',
  'orderUtils',
  'buttonView',
  'tableHeaderFieldView',
  'template!ordersListHeaderToolsView'
], function (
  App,
  Module,  
  _,
  OrderUtils,
  ButtonView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-list-header-tools-view ' + FieldView.prototype.className,

    regions: {
      addRegion: '.add-region'
    },

    onRender: function() {
      var title = _.i18n('orders.createNew');
      if(this.tableView.options.type === 'offer')
        title = _.i18n('offers.new');
      this.addButtonView = new ButtonView({
        type: 'add',
        color: 'orange',
        text: title,
        title: title
      });

      this.addRegion.show(this.addButtonView);
      this.addButtonView.on('click', _.bind(this.onAdd, this));
    },

    onAdd: function() {
      var defer = $.Deferred();
      if(this.tableView.options.endUserId) {
        OrderUtils.openProductTypeDialog(defer, this.tableView.options.type, this.tableView.options.endUserId);
      } else
        OrderUtils.openEndUserDialog(defer, this.tableView.options.type);

      defer.done(_.bind(function() {
        this.tableView.reload();
        this.tableView.footerView.fetch();

        /*if(this.tableView.options.type === 'offer')
          this.model.trigger('change:offers');
        else
          this.model.trigger('change:orders');  */
      }, this));
    }
  });
});
