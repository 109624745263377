/**
 * Created by BFR on 24/02/2020.
 */
define('editPimImageView',[
  'module',
  'marionette',
  'app',
  'jquery',
  'underscore',
  'template!editPimImageTpl',
  'settings',
  'imageUploadBehavior',
  'emptyView'
], function (
  module,
  Marionette,
  App,
  $,
  _,
  viewTpl,
  Settings,
  ImageUploadBehavior,
  EmptyView
) {
  'use strict';

  var EditPimImageView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'upload-image',

    regions: {
      imageLoader: '.image-loader-region',
      imageRegion: '.image-region'
    },

    ui: {
      showImgCatalog: '.image-region'
    },

    behaviors: {
      ImageUpload: {
        behaviorClass: ImageUploadBehavior
      }
    },

    _loaderView: undefined,
    loadImageInMemory: true,

    initialize: function () {
      this.fd = {};
      var resProduct = this.model.getCurrentResProduct();
      if (resProduct) {
        resProduct.bind('change', this.render);
      }
    },

    onRender: function () {
      this._loaderView = new EmptyView();
      this.getRegion('imageLoader').show(this._loaderView);
      this._hideLoader();
    },

    onDestroy: function () {
      var resProduct = this.model.getCurrentResProduct();
      if (resProduct) {
        resProduct.unbind('change');
      }
    },

    onUploading: function () {
      this._showLoader();
    },

    _showLoader: function () {
      this.$el.find('.image-loader-region').show();
      this.$el.find('.image-region').hide();
    },

    _hideLoader: function () {
      this.$el.find('.image-loader-region').hide();
      this.$el.find('.image-region').show();
    },

    onImageUploadConfirm: function (base64Value, fileExt) {
      this.fd.base64file = base64Value;
      this.fd.fileExt = fileExt;

      var formData = new FormData(),
        productRequest;

      _.each(this.fd, function (value, key) {
        formData.append(key, value);
      });

      /* this.ui.showImgCatalog.attr('style',
         'background-image:url(data:image/' + fileExt + ';base64,' + base64Value + ');');*/

      productRequest = App.request('pim:products:update-catalog-picture', this.model.getCurrentResProduct().get('id'), formData);
      $.when(productRequest)
        .done(_.bind(function (response) {
          this.model.getCurrentResProduct().set('catalogPictureUid', response.catalogPictureUid);
          //this.model.set('test', 'test');
          this._hideLoader();
        }, this))
        .fail(_.bind(function () {
          this._hideLoader();
        }, this));
    },

    serializeData: function () {
      var templateData = {};

      templateData.cataloguePictureUrl = '';
      templateData.allowUpload = false;

      if (this.model.getCurrentResProduct() !== null) {
        templateData.allowUpload = true;
      }

      templateData.cataloguePictureUrl = this.model.getImage();

      return templateData;
    }
  });

  module.exports = EditPimImageView;
});
