define('imageButtonView',[
  'module',
  'backbone',
  'underscore',
  'settings',
  'inputView',
  'galleryView',
  'template!imageButtonView'
], function (
  Module,
  Backbone,
  _,
  Settings,
  InputView,
  GalleryView,
  Tpl
) {
  'use strict';

  Module.exports = InputView.extend({
    template: Tpl,
    className: 'image-button-view ' + InputView.prototype.className,

    events: {
      'click': 'onClick'
    },

    initialize: function () {
        this.galleryId = this.options.galleryId;
        this.gelleryTitle = this.options.galleryTitle;
        this.galleryUrls = this.options.galleryUrls;
        this.url = this.options.url;
    },

    serializeData: function () {
      return {
        src: this.url || Settings.get('notFoundImage')
      };
    },

    onClick: function (e) {
      e.stopPropagation();
      if (this.galleryUrls.length === 0)
        return;

      GalleryView.prototype.open(
        this.galleryId,
        this.gelleryTitle,
        new Backbone.Model({urls: [this.galleryUrls]})
      );

      this.trigger('click', this);
    }
  });
});
  
