define('checkboxView',[
  'module',
  'underscore',
  'events',
  'inputView',
  'template!checkboxView'
], function (
  Module,
  _,
  Events,
  InputView,
  Tpl
) {
  'use strict';

  Module.exports = InputView.extend({
    template: Tpl,
    className: 'checkbox-view ' + InputView.prototype.className,

    ui: {
      checked: '.checked',
      unchecked: '.unchecked',
      loading: '.loader-2'
    },

    initialize: function () {
      this.value = this.options.value;
      this.text = this.options.text;
      this.focused = false;

      if(this.options.onChange)
        this.onChange = this.options.onChange;
    },

    onDestroy: function() {
      Events.pop(this);
    },

    events: {
      'click': 'onClick',
      'focus': 'onFocus',
      'blur': 'onBlur'
    },

    serializeData: function () {
      return {
        text: this.text || ''
      };
    },

    onRender: function () {
      this.renderContent();
    },

    renderContent: function () {
      this.ui.loading.hide();

      if(this.value) {
        this.ui.checked.css('display', 'flex');
        this.ui.unchecked.hide();
      } else {
        this.ui.checked.hide();
        this.ui.unchecked.css('display', 'flex');
      }
    },

    onClick: function(e) {
      e.stopPropagation();

      this.ui.checked.hide();
      this.ui.unchecked.hide();
      this.ui.loading.css('display', 'flex');
      var newValue = !this.value;
      this.onChange(newValue).always(_.bind(function () {
        this.value = newValue;
        this.renderContent();
        this.trigger('change', this.value);
      }, this));
    },

    onChange: function () {
      var defer = $.Deferred();
      defer.resolve();
      return defer.promise();
    },

    getValue: function () {
      return this.value;
    },

    setValue: function (value) {
      this.value = value;
      this.renderContent();
    },

    onFocus: function() {
      Events.push(this);
      this.focused = true;
    },

    onBlur: function() {
      Events.pop(this);
      this.focused = false;
    },

    onKeyDown: function(e) {
      if (e.which === 32 && this.focused) {
        e.preventDefault();
        e.stopPropagation();
        this.onClick(e);
        return false;
      }
    }
  });
});
  
