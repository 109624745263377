/**
 * Created by ASK on 28/0/2021.
 */
define('pimProductsListView',[
  'module',
  'jqgridView',
  'jquery',
  'app',
  'settings',
  'underscore',
  'template!pimProductsListTpl',
  'pricePopoverMixin',
  'pimProductFiltersView',
  'buttonView',
  'entities/pim_product',
  'entities/pim_product_search_filters'
], function (
  module,
  JqGridView,
  $,
  App,
  Settings,
  _,
  viewTpl,
  pricePopoverMixin,
  PimProductFiltersView,
  ButtonView
) {
  'use strict';

  var PimProductsView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'pimProductsListFilters',

    regions: {
      filtersRegion: '.product-filters'
    },

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        btnUse: '.cell-use',
        btnShowFilters: '.show-filter-button',
        btnHideFilters: '.hide-filter-button',
        btnApplyFilters: '.filter-button',
        btnClearFilters: '.clear-filter-button',
        btnDelete: '.cell-delete',
        loading: '.loader-3'
      }, this.gridUi);
    },

    initialize: function () {
      this.selectDefault = _.i18n('common.all');
    },

    events: function () {
      return _.extend({
        'click @ui.btnUse': 'onUse',
        'click @ui.btnApplyFilters': 'onApplyFilter',
        'click @ui.btnClearFilters': 'onClearFilters',
        'click @ui.btnShowFilters': 'onShowFilters',
        'click @ui.btnHideFilters': 'onHideFilters',
        'click @ui.btnDelete': 'onDeleteResellerProduct'
      }, this.gridEvents);
    },

    onShowFilters: function () {
      this.filtersCollection = App.request('products-search-filter:collection');
      this.filtersCollection.fetch().done(_.bind(function () {
        this.pimProductFiltersView = new PimProductFiltersView({
          collection: this.filtersCollection
        });
        this.pimProductFiltersView.listenTo(this.pimProductFiltersView, 'applyFilter', this.onApplyFilter);
        this.getRegion('filtersRegion').show(this.pimProductFiltersView);
        this.ui.btnHideFilters.show();
        this.ui.btnApplyFilters.show();
        this.ui.btnClearFilters.show();
      }, this));
      this.ui.btnShowFilters.hide();
    },

    serializeData: function () {
      return {type: 'product'};
    },
    showLoader: function (isShow) {
      if(isShow)
        this.ui.loading.show();
        else
        this.ui.loading.hide();
    },

    onHideFilters: function () {
      if (this.pimProductFiltersView) {
        this.pimProductFiltersView.destroy();
      }
      this.ui.btnShowFilters.show();
      this.ui.btnHideFilters.hide();
      this.ui.btnApplyFilters.hide();
      this.ui.btnClearFilters.hide();
    },

    onApplyFilter: function () {
      this.selectedFilters = [];
      _.each(this.filtersCollection.models, _.bind(function (modelFilter) {
        _.each(modelFilter.get('values').models, _.bind(function (modelFilterValue) {
          if (modelFilterValue.get('selected')) {
            this.selectedFilters.push(modelFilterValue.get('id'));
          }
        }, this));
      }, this));
      this.updatePagination(true);
    },

    onClearFilters: function () {
      this.selectedFilters = [];
      _.each(this.filtersCollection.models, _.bind(function (modelFilter) {
        _.each(modelFilter.get('values').models, _.bind(function (modelFilterValue) {
          modelFilterValue.set('selected', false);
        }, this));
      }, this));
      this.updatePagination(true);
    },

    onUse: function (e) {
      var target = $(e.currentTarget),
        id = target.data('row-id'),
        displayWarning = target.data('display-warning');
      this.trigger('onUse', id, displayWarning);
    },

    onDeleteResellerProduct: function (e) {
      var target = $(e.currentTarget),
        id = target.data('row-id'),
        code = target.data('code');
      this.trigger('onDeleteResellerProduct', id, code);
    },

    onGridFilterChange: function (e) {
      var that = this,
        filters = {},
        value;

      this.triggerClear(e);

      _.each(this.colModel, function (column) {
        if (column.search) {
          if (column.stype === 'select') {
            value = that.$('select[name="' + column.index + '"]').val();
            filters[column.index] = value === that.selectDefault ? '' : value;
          } else if (column.sorttype === 'price') {
            value = that.$('input[name="' + column.index + '"]').val();
            if (column.index === 'prices' && value) {
              var p = pricePopoverMixin.parse(value);
              filters.quantity = p.quantity;
              filters.priceMin = p.priceMin;
              filters.priceMax = p.priceMax;
            }
          } else {
            value = that.$('input[name="' + column.index + '"]').val();
            filters[column.index] = value;
          }
        }
      });

      if (filters.supplierSecId && !Array.isArray(filters.supplierSecId) && filters.supplierSecId.indexOf('_') === -1) {
        delete filters.supplierSecId;
      }

      if (filters.supplierSecId && Array.isArray(filters.supplierSecId)) {
        filters.supplierSecId = _.filter(filters.supplierSecId, function (n) {
          return n.indexOf('_') !== -1;
        });
      }

      this.filters = filters;
      this.filterChange = true;
      this.updatePagination(true);
    },

    gridOptions: function (data) {
      var defaultsOptions, options, that = this;

      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {label: '', name: 'id', key: true, hidden: true},
          {
            label: ' ',
            name: 'data.catalogPictureUrl',
            align: 'left',
            search: false,
            classes: 'product-link image-cell',
            formatter: this.pictureUidFormatter,
            width: 30
          },
          {
            label: _.i18n('productList.productCode'),
            name: 'resellerData',
            search: true,
            index: 'resellerCode',
            sorttype: 'integer',
            classes: 'product-link',
            formatter: this.resellerFormatter
          },
          {
            label: _.i18n('productList.creatDate'),
            name: 'creatDate',
            search: true,
            index: 'creatDate',
            sorttype: 'date',
            classes: 'product-link',
            formatter: this.dateFormatter,
            width: 30
          },
          {
            label: _.i18n('productList.productCodeMan'),
            name: 'data.code',
            search: true,
            index: 'productCode',
            sorttype: 'integer',
            classes: 'product-link',
            formatter: this.defaultFormatter,
            width: 50
          },
          {
            label: _.i18n('productList.productName'),
            name: 'data.name.values',
            search: true,
            index: 'productName',
            classes: 'product-link',
            formatter: this.nameFormatter,
            width: 120
          },
          {
            label: _.i18n('productList.productManufacturer'),
            name: 'supplier',
            search: true,
            index: 'supplierSecId'/*, stype: 'select'*/,
            classes: 'product-link',
            formatter: this.defaultFormatter,
            width: 80,
            stype: 'select',
            searchrules: {select: true},
            searchoptions: {
              multiple: true,
              value: data.manufacturers,
              defaultValue: that.selectDefault
            }
          },
          {
            label: _.i18n('productList.outDated'),
            name: 'outDated',
            classes: 'tickets-link cell-to-be-checked',
            formatter: this.outDatedFormatter,
            index: 'outDated',
            search: true,
            sortable: false,
            width: 30,
            stype: 'select',
            searchrules: {select: true},
            searchoptions: {
              value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
            }
          },
          {
            label: _.i18n('productList.toUpdateFromSupplier'),
            name: 'needCheck',
            classes: 'tickets-link cell-to-be-checked',
            formatter: this.needCheckFormatter,
            index: 'needCheck',
            search: true,
            sortable: false,
            width: 30,
            stype: 'select',
            searchrules: {select: true},
            searchoptions: {
              value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
            }
          },
          {
            label: _.i18n('common.onOff'),
            name: 'showInWebshop',
            classes: 'tickets-link cell-to-be-checked',
            formatter: this.showInWebshopFormatter,
            index: 'showInWebshop',
            search: true,
            sortable: false,
            width: 30,
            stype: 'select',
            searchrules: {select: true},
            searchoptions: {
              value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.on') + ';' + false + ':' + _.i18n('common.off')
            }
          },
          {
            label: _.i18n('pimProductList.addPimProduct'),
            name: '',
            classes: 'use-link js-use-button-font',
            formatter: this.useFormatter,
            search: false,
            width: 30
          },
          {
            label: _.i18n('common.refreshWebshop'),
            name: '',
            classes: 'use-link js-use-button-font',
            formatter: this.refreshFormatter,
            search: false,
            width: 30,
            onClick: _.bind(function(event, rowid) {
              if (event.target.tagName.toLowerCase() !== 'td') {
                this.trigger('onRefreshWebshopData', rowid);
              }
            }, this)
          }
        ],

        serializeGridData: function (postData) {
          if (that.selectedFilters) {
            postData.filters = that.selectedFilters.join(',');
          } else {
            delete postData.filters;
          }
          if (postData.supplierSecId === that.selectDefault) {
            delete postData.supplierSecId;
          }
          if (postData.productCode) {
            postData.code = postData.productCode;
          }

          if (postData.productName) {
            postData.name = postData.productName;
          }

          if (postData.prices) {
            var p = pricePopoverMixin.parse(postData.prices);
            postData.quantity = p.quantity;
            postData.priceMin = p.priceMin;
            postData.priceMax = p.priceMax;
            delete postData.prices;
          } else {
            delete postData.quantity;
            delete postData.priceMin;
            delete postData.priceMax;
          }

          if (postData.supplierSecId && !Array.isArray(postData.supplierSecId) && postData.supplierSecId.indexOf('_') === -1) {
            delete postData.supplierSecId;
          }

          if (postData.supplierSecId && Array.isArray(postData.supplierSecId)) {
            postData.supplierSecId = _.filter(postData.supplierSecId, function (n) {
              return n.indexOf('_') !== -1;
            });
            postData.supplierSecId = postData.supplierSecId.join(',');
          }

          defaultsOptions.serializeGridData.apply(this, arguments);
          return postData;
        },

        onCellSelect: _.bind(function (rowid, iCol, cellContent, event) {
          if (this.colModel[iCol].onClick) {
            this.colModel[iCol].onClick(event, rowid);
          } else if (event && !$(event.target).hasClass('cell-use') && !$(event.target).hasClass('cell-refresh') &&
              !$(event.target).hasClass('cell-delete') &&
            event.type === 'click') {
            var index = 0;
            for (var i = 1; i < event.currentTarget.rows.length; i++) {
              var row = event.currentTarget.rows[i];
              if (row.id === rowid) {
                index = i - 1;
                break;
              }
            }
            var productListData = {
              filters: this.filters,
              rowCount: this.pagination.rowCount,
              currentIndex: (this.pagination.currentPage - 1) * this.pagination.pageSize + index
            };
            Settings.set('pimListData', productListData);
            App.trigger('pim:show', rowid);
          } else if (event && $(event.target).hasClass('mdi-image-flip') && event.type === 'click') {
            App.navigate('pim/' + rowid + '/share', true);
          }

        }, this)
      };

      if (Settings.configValue('showFastDelivery')) {
        options.colModel.push({
          label: _.i18n('common.fastDelivery'),
          name: 'fastDelivery',
          classes: 'tickets-link cell-to-be-checked',
          formatter: this.fastDeliveryFormatter,
          index: 'fastDelivery',
          search: true,
          sortable: false,
          width: 30,
          stype: 'select',
          searchrules: {select: true},
          searchoptions: {
            value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
          }
        });
      }

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    nameFormatter: function (aNames) {
      var sResult = '';
      _.each(aNames, function (value, lang) {
        if (Settings.get('lang')) {
          if (lang === Settings.get('lang') || (lang === 'en' && !sResult)) {
            sResult = value;
          }
        }
      });
      return sResult;
    },

    fastDeliveryFormatter: function (cellvalue, colModel, model) {
      var htmlEl;
      if (model.data && model.data.fastDelivery) {
        htmlEl = '<i class="fa fa-clock-o" style="color: green; font-size: 17pt;" title="' + _.i18n('common.fastDelivery') + '"></i>';
      } else {
        htmlEl = '';
      }
      return htmlEl;
    },

    showImageZoomPopover: function (fileLink, target) {
      var url = fileLink,
        content = '<div class="img-responsive">' +
          '<img class="img-popover" src="' + url + '"/></div>';

      target.attr('data-content', content);
      target.popover({
        trigger: 'hover',
        placement: 'right',
        html: 'true',
        container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content')
      }).data('bs.popover').tip().addClass('jqgrid-popover');
    },

    resellerFormatter: function (aResellers) {
      var sResult = '';
      _.each(aResellers, function (value) {
        if (sResult !== '') {
          sResult += ', ';
        }
        sResult += '<span className="">' + value.code + '<i class="mdi-action-delete cell-delete" ' +
          'title="' + _.i18n('common.delete') + '" data-code="' + value.code + '" ' +
          'data-row-id="' + value.id + '"></i></span>';
      });
      return sResult;
    },

    showInWebshopFormatter: function (showInWebshop, colModel, model) {
      var htmlEl, show = false;
      _.each(model.resellerData, function (value) {
        if (value.showInWebshop) {
          show = true;
        }
      });
      if (show) {
        htmlEl = '<i class="mdi-hardware-computer" title="' + _.i18n('common.onOff') + '"></i>';
      } else {
        htmlEl = '';
      }
      return htmlEl;
    },

    needCheckFormatter: function (needCheck, colModel, model) {
      var htmlEl, show = false;
      _.each(model.resellerData, function (value) {
        if (value.needCheck) {
          show = true;
        }
      });
      if (show) {
        htmlEl = '<i class="mdi-image-flip" title="' + _.i18n('productList.toUpdateFromSupplier') + '"></i>';
      } else {
        htmlEl = '';
      }
      return htmlEl;
    },

    useFormatter: function (cellValue, options, model) {
      return '<span class="mdi mdi-av-my-library-add cell-use js-icon" ' +
        ((model.resellerData && model.resellerData.length > 0) ? 'data-display-warning="true"' : '') + ' ' +
        'data-row-id="' + options.rowId + '"></span>';
    },

    refreshFormatter: function (cellValue, options, model) {
      var button = $('<span class="cell-refresh" style="display:inline-block;s" data-row-id="' + options.rowId + '"></span>');
      if (model.resellerData && model.resellerData.length > 0) {
        var refreshButtonView = new ButtonView({
          type: 'reload',
          color: 'lightgrey',
          title: _.i18n('common.refresh')
        });
        var el = refreshButtonView.render().$el;
        button = button.append(el).prop('outerHTML');
        refreshButtonView.destroy();
      } else {
        button = button.prop('outerHTML');
      }
      return button;
    },

    pictureUidFormatter: function (cellValue, colModel, model) {
      var pimProduct = App.request('pim:products:model', model);
      return '<span><img data-link="' + pimProduct.getDefaultImage() + '"' +
        'class="jq-picture js-image-cell" src="' + pimProduct.getDefaultImage() + '"/></span>';
    }
  });

  module.exports = PimProductsView;
});

