define('webshopProductsDetailBodyView',[
  'app',
  'module',
  'underscore',
  'settings',
  'rolesMixin',
  'tabsViewView',
  'webshopProductsDetailBodyProductView',
  'webshopProductsDetailBodyTextsView',
  'webshopProductsDetailBodySlugsView',
  'webshopProductsDetailBodyCategoriesView',
  'webshopProductsDetailBodyUrlsView',
  'webshopProductsDetailBodyUrlsV1View',
  'webshopProductsDetailBodyImagesView'
], function (
    App,
    Module,
    _,
    Settings,
    RolesMixin,
    TabsView
) {
  'use strict';

  Module.exports = TabsView.extend({
    selectedTab: 'product',

    tabs: function () {
      var items = [];

      items.push({
        name: 'product',
        url: '',
        title: _.i18n('webshop.products.product.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_PRODUCT,
        defaultSelected: true,
        view: 'webshopProductsDetailBodyProductView'
      });

      items.push({
        name: 'texts',
        url: 'texts',
        title: _.i18n('webshop.products.texts.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_TEXTS,
        view: 'webshopProductsDetailBodyTextsView'
      });

      if (Settings.configValue('webshop.urls.mode') === 'old') {
        items.push({
          name: 'slugs',
          url: 'slugs',
          title: _.i18n('webshop.products.slugs.tabTitle'),
          role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_SLUGS,
          view: 'webshopProductsDetailBodySlugsView'
        });
      }

      items.push({
        name: 'categories',
        url: 'categories',
        title: _.i18n('webshop.products.categories.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_CATEGORIES,
        view: 'webshopProductsDetailBodyCategoriesView'
      });

      if (Settings.configValue('webshop.urls.mode') === 'new') {
        items.push({
          name: 'urls',
          url: 'urls',
          title: _.i18n('common.urls'),
          role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_URLS,
          view: 'webshopProductsDetailBodyUrlsView'
        });
      }

      if (Settings.configValue('webshop.urls.mode') === 'old') {
        items.push({
          name: 'urlsV1',
          url: 'urlsV1',
          title: _.i18n('common.urls'),
          role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_URLS,
          view: 'webshopProductsDetailBodyUrlsV1View'
        });
      }

      items.push({
        name: 'images',
        url: 'images',
        title: _.i18n('common.images'),
        role: RolesMixin.DASHBOARD_WEBSHOP_PRODUCTS_IMAGES,
        view: 'webshopProductsDetailBodyImagesView'
      });

      return items;
    },

    onSelected: function (tab) {
      this.url = 'webshop/products/' + this.options.id;
      if (tab.get('url') !== '')
        this.url += '/' + tab.get('url');
      App.navigate(this.url, {trigger: false});
    }
  });
});

