
define('template!mailsMailMailTextView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="body-region">\n  <div class="mail-content">\n    <iframe class="mail-iframe"></iframe>\n  </div>\n</div>\n';

}
return __p
};});

