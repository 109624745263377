define('webshopProductsDetailView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'headerFooterView',
  'webshopProductsDetailHeaderView',
  'webshopProductsDetailBodyView',
  'entities/webshop/webshop',
  'entities/webshop/products'
], function (
  App,
  Module,
  Backbone,
  _,
  HeaderFooterView,
  HeaderView,
  BodyView
) {
  'use strict';

  Module.exports = HeaderFooterView.extend({
    className: 'webshop-products-detail-view ' + HeaderFooterView.prototype.className,
    childHeaderView: HeaderView,
    childView: BodyView,

    modelEvents: {
      'change:texts': function () {
        this.windowView.setTitle(_.i18n('webshop.product.title') + ' | ' + this.model.getName());
      }
    },

    initialize: function () {
      HeaderFooterView.prototype.initialize.apply(this, arguments);
      this.model = App.request('webshop.product.model', {id: this.options.id});
    },

    onShow: function () {
      App.request('webshop.config').done(_.bind(function (webshopConfig) {
        this.model.fetch().done(_.bind(function () {
          this.options.webshopConfig = webshopConfig;
          HeaderFooterView.prototype.onShow.call(this);
        }, this));
      }, this));
    },

    // Static methods
    open: function (id, tabName) {
      require([
        'windows',
        'webshopProductsDetailView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              id: id,
              tabName: tabName
            });
          }, this),
          onOpened: function (windowView) {
            windowView.view.bodyView.tabsView.setSelected(tabName);
            windowView.view.bodyView.selectTab(windowView.view.bodyView.tabsView.getSelected());
          },
          id: 'webshop.product:' + id,
          title: _.i18n('webshop.product.title'),
          width: '80%',
          height: '80%'
        });
      });
    }
  });
});

