define('tabsViewContentsView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'tabsViewContentView'
], function (
  App,
  Module,
  Marionette,
  _,
  TabsViewContentView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'container-tabs-contents-view',
    childView: TabsViewContentView,
    childViewOptions: function () {
      return {
        viewOptions: this.options.viewOptions
      };
    }
  });
});
