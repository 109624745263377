
define('template!pimProductEditsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (allowEditCompuzz) { ;
__p += '\n<button class="btn btn-primary btn-edit-supplier-product"\n        style="padding: 5px 10px 5px 10px;">' +
((__t = ( _.i18n('common.editCompuzzProduct') )) == null ? '' : __t) +
'\n</button>\n';
 } ;
__p += '\n<div class="row">\n  <div class="col-xs-12 page-title">' +
((__t = ( _.i18n('pictures.catalogPicture') )) == null ? '' : __t) +
'</div>\n</div>\n<div class="row">\n  <div class="edit-image col-xs-12"></div>\n</div>\n<div class="row">\n  <div class="images-region"></div>\n</div>\n<div class="row">\n  <div class="col-xs-12 product-edit">\n    <div class="pa-list">\n      <div class="pa-item" style="position: relative;">\n        <div class="pa">\n          <div class="pa-code">' +
((__t = ( data.code )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( description )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.brand') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( data.brand )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.width') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( data.refRootProduct.width )) == null ? '' : __t) +
' mm</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.height') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( data.refRootProduct.height )) == null ? '' : __t) +
' mm</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.deepness') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( data.refRootProduct.deepness )) == null ? '' : __t) +
' mm</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.weight') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( data.refRootProduct.weight )) == null ? '' : __t) +
' gr</div>\n        </div>\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.minimumOrderQuantity') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( data.minimumOrderQuantity )) == null ? '' : __t) +
'</div>\n        </div>\n        ';
 if (data.fastDelivery) { ;
__p += '\n        <div class="col-xs-12">\n          <div class="col-xs-4 text-right">' +
((__t = ( _.i18n('common.fastDelivery') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( data.fastDelivery?_.i18n('common.yes'):'' )) == null ? '' : __t) +
'</div>\n        </div>\n        ';
 } ;
__p += '\n      </div>\n    </div>\n  </div>\n</div>\n<div class="row">\n  <h2 class="col-xs-12 text-left">Print areas</h2>\n</div>\n<div class="row">\n  <div class="edit-printareas col-xs-12"></div>\n</div>\n\n<div class="row">\n  <h2 class="col-xs-12 text-left">Options categories</h2>\n</div>\n<div class="row">\n  <div class="edit-cats col-xs-12"></div>\n</div>\n<div class="row">\n  <div class="pull-right">\n    <button class="btn-save-options-categories">' +
((__t = ( _.i18n('common.save') )) == null ? '' : __t) +
'</button>\n    <br><br>\n    <p class="lblModDate"></p>\n  </div>\n</div>\n\n<div class="row">\n  <h2 class="text-left p-l-15" style="display: inline-block;">Variations</h2>\n</div>\n<div class="row">\n  <div class="edit-variations col-xs-12"></div>\n</div>\n<div class="loading-block js-loading-block loader"></div>';

}
return __p
};});

