
define('template!headerFooterView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header-region"></div>\n<div class="body-region"></div>\n<div class="footer-region"></div>';

}
return __p
};});

