define('ordersDetailOrderProductView',[
  'module',
  'app',
  'marionette',
  'settings',
  'moment',
  'underscore',
  'orderUtils',  
  'ordersDetailOrderProductOptionsView',
  'ordersDetailOrderProductQuantityView',
  'ordersDetailOrderProductStockView',
  'ordersDetailOrderProductCustomNameView',
  'ordersDetailOrderProductCustomDescriptionView',
  'template!ordersDetailOrderProductView',
  'entities/orders'
], function (
  Module,
  App,
  Marionette,
  Settings,
  moment,
  _,   
  OrderUtils, 
  OptionsView,
  QuantityView,
  StockView,
  CustomNameView,
  CustomDescriptionView,
  Tpl
) {
  'use strict';   

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-detail-order-product-view',

    regions: {
      optionsRegion: '.options-region',
      quantityRegion: '.quantity-region',
      customNameRegion: '.custom-name-region',
      customDescriptionRegion: '.custom-description-region',
      stockRegion: '.stock-region'
    },
    
    ui: {
      selectProductButton: '.btn-select-product',
      viewProductButton: '.btn-view-product',
      copyCompanyNameButton: '.btn-copy-product-name',
      copyCustomNameButton: '.btn-copy-custom-name',
      copyProductCodeButton: '.btn-copy-product-code',
      copySupplierProductCodeButton: '.btn-copy-supplier-product-code',
      productName: '.product-name .value', 
      baseCode: '.base-code .value',
      supplierCode: '.supplier-code .value',
      stock: '.stock'
    },
      
    events: {
      'click @ui.selectProductButton': 'onSelectProduct',
      'click @ui.viewProductButton': 'onViewProduct',
      'click @ui.copyCompanyNameButton': 'onCopyProductName',
      'click @ui.copyCustomNameButton': 'onCopyCustomName',
      'click @ui.copyProductCodeButton': 'onCopyProductCode',
      'click @ui.copySupplierProductCodeButton': 'onCopySupplierProductCode'
    },    

    modelEvents: {      
      'change:productName': 'renderContent',
      'change:productJson': 'fetchProduct',
      'change:productSecId': 'fetchProduct'
    },

    renderStock: function() {
      var caccountName = '';
      if (this.model.get('caccount') && this.model.get('caccount').name)
          caccountName = this.model.get('caccount').name;

      this.stockView = new StockView(
         {
          secId:this.options.product.get('secId'),
          pim:this.options.product.get('pim'),
          productCode:this.model.get('productCode'),
          productOwner:this.model.get('productOwner'),
          caccountName:caccountName
      });
      this.getRegion('stockRegion').show(this.stockView);
    },

    onShow: function() {

      this.quantityView = new QuantityView({
        model: this.model,
        product: this.options.product
      });      
      this.getRegion('quantityRegion').show(this.quantityView);

      this.optionsView = new OptionsView({
        model: this.model,
        product: this.options.product
      });      
      this.getRegion('optionsRegion').show(this.optionsView);

      //-------------------

      this.customNameView = new CustomNameView({
        model: this.model
      });      
      this.getRegion('customNameRegion').show(this.customNameView);

      //-------------------

      this.customDescriptionView = new CustomDescriptionView({
        model: this.model
      });
      this.getRegion('customDescriptionRegion').show(this.customDescriptionView);

      //-------------------

      this.listenTo(this.options.product, 'change', _.bind(function(model) {
        if (model.hasChanged('baseCode'))
          this.renderContent();
      }, this));
    },    

    onRender: function() {      
      this.renderContent();    
    },

    renderContent: function() {
      this.renderStock();

      var productName = this.model.getProductName();
      if (productName !== '')
        this.ui.productName.html(productName);
      else
        this.ui.productName.html('-');

      //-------------------

      if (this.model.get('productBaseCode'))
        this.ui.baseCode.html(this.model.get('productBaseCode'));
      else
        this.ui.baseCode.html('-');

      //-------------------

      if (this.options.product.get('baseCode'))
        this.ui.supplierCode.html(this.options.product.get('baseCode'));
      else
        this.ui.supplierCode.html('-');
    },

    onSelectProduct: function() {
      var defer = $.Deferred();

      OrderUtils.openProductTypeDialog(defer, null, null, this.model);

      defer.done(_.bind(function (configuration, product) {
        this.onChanged(configuration, product);
      }, this));
    },

    onChanged: function (configuration, product) {     
      var prom;
      if (product.get('pim')) {
        prom = App.request('order:update-from-configuration-pim',
          this.model.get('purchaseOrderId'),
          configuration
        );
      } else {
        prom = App.request('order:update-from-configuration',
          this.model.get('purchaseOrderId'),
          configuration
        );
      }

      prom.then(_.bind(function () {
        this.model.fetch();
      }, this));       
    },

    onViewProduct: function() {
      App.navigate('product/' + this.options.product.get('secId'), {trigger: true});
    },

    fetchProduct: function() {
      if (this.model.get('pim')) {
        this.options.product.set(this.model.get('productJson'));
      } else {
        this.options.product.set('secId', this.model.get('productSecId'), {silent: true});
        this.options.product.fetch();
      }
    },

    onCopyProductName: function () {
      var $temp = $('<input>');
      this.$el.append($temp);
      $temp.val(this.model.get('productName')).select();
      document.execCommand('copy');
      $temp.remove();
    },

    onCopyCustomName: function () {
      var $temp = $('<input>');
      this.$el.append($temp);
      $temp.val(this.model.get('name')).select();
      document.execCommand('copy');
      $temp.remove();
    },

    onCopyProductCode: function () {
      var value = this.model.get('productBaseCode');  
      var $temp = $('<input>');
      this.$el.append($temp);
      $temp.val(value).select();
      document.execCommand('copy');
      $temp.remove();
    },

    onCopySupplierProductCode: function () {
      var value = this.options.product.get('baseCode');  
      var $temp = $('<input>');
      this.$el.append($temp);
      $temp.val(value).select();
      document.execCommand('copy');
      $temp.remove();
    }  
  });
});

