define('entities/pim_product',[
  'app',
  'jquery',
  'settings',
  'backbone',
  'underscore',
  'dateUtils',
  'bootbox',
  'backboneRelational'
], function (
  App,
  $,
  Settings,
  Backbone,
  _,
  DateUtil,
  bootbox
) {
  'use strict';

  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  //-----------------

  var PimPrintFactors = Backbone.Model.extend({
    defaults: {
      factor: 1.12,
      factorSetup: 1.12,
      factorHandling: 1.12
    },
    idAttribute: 'id'
  });

  app.ns.PimDtTrans = Backbone.RelationalModel.extend({
    defaults: {
    },
    relations: [{
      type: Backbone.HasOne,
      key: 'glossaryDt',
      relatedModel: 'DynamicText'
    }],
    idAttribute: 'id'
  });

  app.ns.PimImprintTechnology = Backbone.RelationalModel.extend({
    defaults: {
    },
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'PimDtTrans'
    }],
    idAttribute: 'id'
  });

  app.ns.PimPrintZone = Backbone.RelationalModel.extend({
    defaults: {
      setupCode: null,
      unitCode: null,
      fastDelivery: false
    },
    relations: [{
      type: Backbone.HasOne,
      key: 'refImprintTechnology',
      relatedModel: 'PimImprintTechnology'
    }],
    idAttribute: 'id'
  });

  app.ns.PimPrintArea = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasMany,
      key: 'printZones',
      relatedModel: 'PimPrintZone',
      collectionType: 'PimPrintZoneCollection'
    }, {
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'PimDtTrans'
    }]
  });

  app.ns.PimProductCodeVariation = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasMany,
      key: 'productOptions',
      relatedModel: 'PimProductOption',
      collectionType: 'PimProductOptionCollection'
    }]
  });

  app.ns.PimProductOption = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'PimDtTrans'
    }]
  });

  app.ns.PimProductOptionCategories = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasMany,
      key: 'productOptions',
      relatedModel: 'PimProductOption',
      collectionType: 'PimProductOptionCollection'
    },{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'PimDtTrans'
    }],
    idAttribute: 'id'
  });

  app.ns.PimProductAttributeCustomFieldValue = Backbone.RelationalModel.extend({});

  app.ns.PimProductAttributeCustomField = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasMany,
      key: 'cfValues',
      relatedModel: 'PimProductAttributeCustomFieldValue'
    }]
  });

  app.ns.PimProductAttribute = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasMany,
      key: 'customFields',
      relatedModel: 'PimProductAttributeCustomField',
      collectionType: 'PimProductAttributesCollection'
    }],
    fetch: function (id) {
      this.url = Settings.url('compuzz2', 'v2/pim/products/' + id + '/resellerInfos');
      return Backbone.Model.prototype.fetch.call(this);
    },
    save: function (id) {
      this.url = Settings.url('compuzz2', 'v2/pim/products/' + id + '/resellerInfos');
      return Backbone.Model.prototype.save.call(this);
    },
    idAttribute: 'supplierId'
  });

  app.ns.PimSupplierProduct = Backbone.RelationalModel.extend({
    defaults: {
      refRootProduct: {}
    },

    relations: [{
      type: Backbone.HasMany,
      key: 'productOptionCategories',
      relatedModel: 'PimProductOptionCategories',
      collectionType: 'PimProductOptionCategoryCollection'
    }, {
      type: Backbone.HasMany,
      key: 'printAreas',
      relatedModel: 'PimPrintArea',
      collectionType: 'PimPrintAreaCollection'
    }, {
      type: Backbone.HasMany,
      key: 'productCodeVariations',
      relatedModel: 'PimProductCodeVariation',
      collectionType: 'PimProductCodeVariationCollection'
    }]
  });

  app.ns.PimResellerProduct = Backbone.RelationalModel.extend({
    defaults: {
      defaultIndexPicture: null
    },
    relations: [{
      type: Backbone.HasMany,
      key: 'pimPoResellers',
      relatedModel: 'PimProductOptionCategories',
      collectionType: 'PimProductOptionCategoryCollection'
    }],
    save: function () {
      this.url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + this.get('id'));
      return Backbone.Model.prototype.save.call(this);
    },
    saveImprint: function () {
      this.url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + this.get('id') + '/imprint');
      return Backbone.Model.prototype.save.call(this);
    },
    saveOptionsCategories: function () {
      this.url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + this.get('id') + '/optionsCategories');
      return Backbone.Model.prototype.save.call(this);
    }
  });

  app.ns.PimProduct = Backbone.RelationalModel.extend({
    defaults: {
      fastDelivery: false
    },
    relations: [{
      type: Backbone.HasOne,
      key: 'data',
      relatedModel: 'PimSupplierProduct',
      collectionType: 'PimSupplierProductCollection'
    }, {
      type: Backbone.HasMany,
      key: 'resellerData',
      relatedModel: 'PimResellerProduct',
      collectionType: 'PimResellerProductCollection'
    }],

    url: function () {
      return Settings.url('compuzz2', 'v2/pim/products/' + this.get('id'));
    },
    fetch: function () {
      this.url = Settings.url('compuzz2', 'v2/pim/products/' + this.get('id'), {includeTrans: true});
      return Backbone.Model.prototype.fetch.call(this);
    },
    createCompuzzProduct: function () {
      this.url = Settings.url('compuzz2', 'v2/pim/products/' + this.get('id') + '/createInCompuzz', {includeTrans: true});
      return Backbone.Model.prototype.fetch.call(this);
    },
    setCurrentResProduct: function (resId) {

      if (!this.get('currentResProductId') || this.get('currentResProductId' !== resId)) {
        this.set('currentResProductId', resId);
      }

      if (!this.get('currentResProductId')) {
        if (this.get('resellerData').models.length > 0)
          this.set('currentResProductId', this.get('resellerData').models[0].get('id'));
      }
    },
    getCurrentResProduct: function () {
      var currentResProduct = null;

      if (this.get('currentResProductId')) {
        _.each(this.get('resellerData').models, _.bind(function (resProduct) {
          if (resProduct.get('id') === this.get('currentResProductId')) {
            currentResProduct = resProduct;
          }
        }, this));
      }

      return currentResProduct;
    },
    getResProduct: function (resId) {
      if (resId)
        return null;

      var result = null;
      _.each(this.get('resellerData').models, _.bind(function (resProduct) {
        if (resProduct.get('id') === resId) {
          result = resProduct;
        }
      }, this));

      return result;
    },
    getImage: function () {

      var resProduct = this.getCurrentResProduct();

      var catalogPictureUid = null;
      if (resProduct !== null) {
        catalogPictureUid = resProduct.get('catalogPictureUid');
      }
      if (catalogPictureUid) {
        return Settings.url('file', catalogPictureUid) + '&cmds=on';
      } else {
        if (this.get('data').get('catalogPictureUrl')) {
          return this.get('data').get('catalogPictureUrl');
        } else {
          return Settings.get('notFoundImage');
        }
      }
    },

    getDefaultImage: function () {

      var defaultResProduct = null;

      if (this.get('resellerData').models.length > 0)
        defaultResProduct = this.get('resellerData').models[0];

      var catalogPictureUid = null;
      if (defaultResProduct !== null)
        catalogPictureUid = defaultResProduct.get('catalogPictureUid');

      if (catalogPictureUid) {
        return Settings.url('file', catalogPictureUid);
      } else {
        if (this.get('data').get('catalogPictureUrl')) {
          return this.get('data').get('catalogPictureUrl');
        } else {
          return Settings.get('notFoundImage');
        }
      }
    },

    idAttribute: 'id'
  });

  var PimPrintZonePrice = Backbone.Model.extend({
    fetch: function (id, params) {
      this.url = Settings.url('compuzz2', 'v2/pim/products/' + id + '/printZones/pricesFactor', params);
      return Backbone.Collection.prototype.fetch.call(this);
    },

    findByPzIdAndQuantity: function (pzId, quantity) {
      if (pzId) {
        var prices = this.get(pzId.toString());
        if (prices) {
          for (var i = 0; i < prices.length; i++) {
            if (prices[i].quantity === Number(quantity)) {
              return prices[i].factor;
            }
          }
        }
      }
      return null;
    },

    findCustomPriceByPzIdAndQuantity: function (pzId, quantity) {
      if (pzId) {
        var prices = this.get(pzId.toString());
        if (prices) {
          for (var i = 0; i < prices.length; i++) {
            if (prices[i].quantity === Number(quantity)) {
              return prices[i];
            }
          }
        }
      }
      return null;
    },

    clear: function (pzId) {
      this.set(pzId.toString(), []);
    },

    setPriceFactorForQuantity: function (pzId, quantity, factor) {
      var prices = this.get(pzId.toString());
      prices.push({
        quantity: Number(quantity),
        factor: Number(factor)
      });
    },

    //quantity = -1 -> Setup data
    //quantity = -2 -> handling

    setCustomPzPrice: function (pzId, quantity, pzPrice, setUpPrice, factor) {
      var prices = this.get(pzId.toString());
      prices.push({
        quantity: Number(quantity),
        price: Number(pzPrice),
        setUpPrice: Number(setUpPrice),
        factor: Number(factor)
      });
    }
  });

  var PimPrice = Backbone.Model.extend({});

  var PimPriceCollection = Backbone.Collection.extend({
    fetch: function (id, params) {
      this.url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + id + '/pricesFactor', params);
      return Backbone.Collection.prototype.fetch.call(this);
    },

    saveAll: function (id) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + id + '/pricesFactor');
      $.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(this.toJSON()),
        contentType: 'application/json',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    findByQuantity: function (quantity) {
      for (var i = 0; i < this.models.length; i++) {
        if (this.models[i].get('quantity') === Number(quantity)) {
          return this.models[i];
        }
      }
      return null;
    },
    model: PimPrice
  });

  //-----------------

  var PimPrintFactorsCollection = Backbone.Collection.extend({
    model: PimPrintFactors,
    comparator: 'id'
  });

  app.ns.PimPrintZoneCollection = Backbone.Collection.extend({});

  app.ns.PimProductOptionCollection = Backbone.Collection.extend({});

  app.ns.PimProductOptionCategoryCollection = Backbone.Collection.extend({});

  app.ns.PimSupplierProductCollection = Backbone.Collection.extend({});

  app.ns.PimResellerProductCollection = Backbone.Collection.extend({});

  app.ns.PimPrintAreaCollection = Backbone.Collection.extend({});

  app.ns.PimProductCodeVariationCollection = Backbone.Collection.extend({});

  app.ns.PimProductAttributesCollection = Backbone.Collection.extend({
    model: app.ns.PimProductAttributeCustomField
  });

  //-----------------

  var API = {

    getPriceForConfiguration: function (id, configuration, buyingPrice) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + id + '/configuration/price', {buyingPrice: buyingPrice});
      $.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(configuration),
        contentType: 'application/json',
        success: function (data) {
          if (data) {
            if (!data.totalHandlingCost) {
              data.totalHandlingCost = '0';
            }
            if (!data.shippingCost) {
              data.shippingCost = '0';
            }
          }
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getProductPriceFactorSup: function (id) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/pim/products/' + id + '/pricesFactorSup');

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getPrintFactors: function (id) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/pim/products/' + id + '/printFactors'),
        success: function (resp) {
          defer.resolve(new PimPrintFactorsCollection(resp));
        }
      });

      return defer.promise();
    },

    updatePrintZone: function (id, printZone) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz2', 'v2/pim/products/' + id + '/printZones/pricesFactor');
      $.ajax({
        type: 'PUT',
        url: url,
        data: JSON.stringify(printZone),
        contentType: 'application/json',
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    postCatalogPicture: function (id, data) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + id + '/catalogPicture');
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        processData: false,
        contentType: false,
        success: function (resp) {
          defer.resolve(resp);
        },
        error: function (data) {
          defer.reject(data);
        }
      });

      return defer.promise();
    },

    postCatalogPictureFromUrl: function (id, data) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + id + '/catalogPicture/fromUrl');
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        processData: false,
        contentType: false,
        success: function (resp) {
          defer.resolve(resp);
        },
        error: function (data) {
          defer.reject(data);
        }
      });

      return defer.promise();
    },

    postVariationImage: function (productId, variationId, formData) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'v2/pim/products/' + productId + '/variation/' + variationId + '/uploadImage');
      $.ajax({
        type: 'POST',
        url: url,
        data: formData,
        processData: false,
        contentType: false,
        success: function (resp) {
          defer.resolve(resp);
        },
        error: function (data) {
          defer.reject(data);
        }
      });

      return defer.promise();
    },

    deleteVariationImage: function (productId, variationId, uid) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'v2/pim/products/' + productId + '/variation/' + variationId + '/deleteImage/' + uid);
      $.ajax({
        type: 'DELETE',
        url: url,
        processData: false,
        contentType: false,
        success: function (resp) {
          defer.resolve(resp);
        },
        error: function (data) {
          defer.reject(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('pim:products:row-count-url', function () {
    return 'v2/pim/products/rowCount';
  });

  App.reqres.setHandler('pim:products:get-collection-url', function () {
    return Settings.url('compuzz2', 'v2/pim/products');
  });

  App.reqres.setHandler('pim:products:model', function (product) {
    var tt = Backbone.Relational.store.find(app.ns.PimProduct, product.id);
    if (tt) {
      return tt;
    } else {
      return new app.ns.PimProduct(product);
    }
  });

  App.reqres.setHandler('pim:products:get-product', function (index, filters) {
    var defer = $.Deferred(),
      url;
    if (!filters) {
      filters = {};
    }
    filters.pageSize = 1;
    filters.first = index;

    url = Settings.url('compuzz2', 'v2/pim/products/', filters);
    $.ajax({
      type: 'GET',
      url: url,
      success: function (data) {
        defer.resolve(data[0]);
      }
    });
    return defer.promise();
  });

  App.reqres.setHandler('pim:products:refreshWebshopData', function (id) {
    var defer = $.Deferred(),
      url;
    url = Settings.url('compuzz2', 'v2/pim/products/' + id + '/webshopDataRefresh');
    $.ajax({
      type: 'POST',
      url: url,
      success: function (data) {
          defer.resolve(data);
      }
    });
    return defer.promise();
  });

  App.reqres.setHandler('pim:products:use', function (id) {
    var defer = $.Deferred(),
      url;

    url = Settings.url('compuzz2', 'v2/pim/products/' + id + '/use');
    $.ajax({
      type: 'POST',
      url: url,
      success: function (data, resp) {
        if (resp !== 'success')
          bootbox.confirm(_.i18n('error.addPimResellerProduct'), function () {
          });
        else
          defer.resolve(data);
      }
    });
    return defer.promise();
  });

  App.reqres.setHandler('pim:products:delete', function (id) {
    var defer = $.Deferred(),
      url;

    url = Settings.url('compuzz2', 'v2/pim/products/resellerProduct/' + id);
    $.ajax({
      type: 'DELETE',
      url: url,
      success: function () {
        defer.resolve();
      }
    });
    return defer.promise();
  });

  App.reqres.setHandler('pim:products:get-print-factors', function (id) {
    return API.getPrintFactors(id);
  });

  App.reqres.setHandler('pim:products:printzone-prices:model', function (printZonesJSON) {
    return new PimPrintZonePrice(printZonesJSON);
  });

  App.reqres.setHandler('pim:products:prices:collection', function (productsJSON) {
    return new PimPriceCollection(productsJSON);
  });

  App.reqres.setHandler('pim:products:get-price-for-configuration', function (id, configuration, buyingPrice) {
    return API.getPriceForConfiguration(id, configuration, buyingPrice);
  });

  App.reqres.setHandler('pim:products:prices-factor-sup', function (id) {
    return API.getProductPriceFactorSup(id);
  });

  App.reqres.setHandler('pim:products:update-printzone', function (secId, printZone) {
    return API.updatePrintZone(secId, printZone);
  });

  App.reqres.setHandler('pim:products:update-catalog-picture', function (productSecId, data) {
    return API.postCatalogPicture(productSecId, data);
  });

  App.reqres.setHandler('pim:products:update-catalog-picture-from-url', function (productSecId, data) {
    return API.postCatalogPictureFromUrl(productSecId, data);
  });

  App.reqres.setHandler('pim:products:upload-variation-image', function (productId, variationId, formData) {
    return API.postVariationImage(productId, variationId, formData);
  });

  App.reqres.setHandler('pim:products:delete-variation-image', function (productId, variationId, uid) {
    return API.deleteVariationImage(productId, variationId, uid);
  });

  //----------------

  App.reqres.setHandler('pim:product:attribute:custom-field:value:model', function (data) {
    return new app.ns.PimProductAttributeCustomFieldValue(data);
  });

  App.reqres.setHandler('pim:product:attribute:custom-field:model', function (data) {
    return new app.ns.PimProductAttributeCustomField(data);
  });

  App.reqres.setHandler('pim:product:attribute:model', function (supplierId) {

    var tt = Backbone.Relational.store.find(app.ns.PimProductAttribute, supplierId);
    if (tt) {
      return tt;
    } else {
      return new app.ns.PimProductAttribute();
    }
  });

  App.reqres.setHandler('pim:product:attribute:collection', function (data) {
    return new app.ns.PimProductAttributesCollection(data);
  });
});

