define('webshopCategoriesDetailBodyImagesTypeListView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'dropzone',
  'webshopCategoriesDetailBodyImagesTypeListImageView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  Dropzone,
  ImageView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'list-view',
    childView: ImageView,

    childViewOptions: function () {
        return {
            category: this.model
        };
    },

    childEvents: {
      'item:deleted': function (view) {
        this.collection.remove(view.model);
        this.model.fetch();
      }
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.fetch();
    },

    onShow: function () {
      var that = this;

      this.$el.dropzone({
        url: Settings.url('compuzz'),
        autoProcessQueue: true,
        uploadMultiple: true,

        init: function () {
          this.on('addedfile', function(file) {
            that.model.addImage(file, that.options.type).done(function (image) {
              if(image)
                that.collection.add(image);
              that.model.fetch();
            }, this);
            this.removeAllFiles();
          });
        },
        dictDefaultMessage: _.i18n('common.file.drag.drop')
      });
    },

    fetch: function () {
      this.collection.reset(this.model.getImages(this.options.type));
    },

    add: function () {
      this.$el.click();
    }
  });
});
