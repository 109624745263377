define('entities/product_rel',[
  'app',
  'jquery',
  'settings',
  'backbone',
  'colorUtils',
  'backboneRelational',
  'entities/product_translation'
], function (
  App,
  $,
  Settings,
  Backbone,
  ColorUtils
) {
  'use strict';
  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  app.ns.Color = Backbone.RelationalModel.extend({
    relations: [],
    idAttribute: 'secId',

    toHex: function () {
      return '#' + ColorUtils.rgbToHexa(this.get('r'), this.get('g'), this.get('b'));
    }
  });

  app.ns.ProductOption = Backbone.RelationalModel.extend({
    defaults: {
      code: null,
      name: new app.ns.DynamicText(),
      optionImageToSaveList: [],
      readOnly: false
    },
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasOne,
      key: 'color1',
      relatedModel: 'Color'
    }, {
      type: Backbone.HasOne,
      key: 'color2',
      relatedModel: 'Color'
    }, {
      type: Backbone.HasOne,
      key: 'color3',
      relatedModel: 'Color'
    }, {
      type: Backbone.HasOne,
      key: 'color4',
      relatedModel: 'Color'
    }],
    idAttribute: 'secId',

    destroy: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/' +
        this.get('refProductOptionCategory').get('refProduct').get('secId') + '/optioncategories/' +
        this.get('refProductOptionCategory').get('secId') + '/options/' + this.get('secId'));
      return Backbone.Model.prototype.destroy.call(this);
    },

    save: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/' +
        this.get('refProductOptionCategory').get('refProduct').get('secId') + '/optioncategories/' +
        this.get('refProductOptionCategory').get('secId') + '/options/');
      return Backbone.Model.prototype.save.call(this);
    }
  });

  app.ns.ProductOptionCollection = Backbone.Collection.extend({
    model: 'ProductOptionTrans',
    comparator: 'code'
  });

  app.ns.ProductOptionCategory = Backbone.RelationalModel.extend({
    defaults: {
      code: null,
      name: new app.ns.DynamicText(),
      displayType: null,
      productCodeVariable: false,
      mixable: false,
      readOnly: false
    },
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasMany,
      key: 'productOptions',
      relatedModel: 'ProductOption',
      collectionType: 'ProductOptionCollection',
      reverseRelation: {
        key: 'refProductOptionCategory',
        includeInJSON: false
      }
    }],
    idAttribute: 'secId',

    destroy: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/' +
        this.get('refProduct').get('secId') + '/optioncategories/' + this.get('secId'));
      return Backbone.Model.prototype.destroy.call(this);
    },

    save: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/' +
        this.get('refProduct').get('secId') + '/optioncategories');
      return Backbone.Model.prototype.save.call(this);
    }
  });

  app.ns.ProductOptionCategoryCollection = Backbone.Collection.extend({
    model: 'ProductOptionCategory',
    comparator: 'code'
  });

  app.ns.ImprintTechnology = Backbone.RelationalModel.extend({
    defaults: {},
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    }],
    idAttribute: 'secId'
  });

  app.ns.PrintZone = Backbone.RelationalModel.extend({
    defaults: {
      setupCode: null,
      unitCode: null,
      x: 0,
      y: 0,
      maxColors: 0,
      fixedFillingType: 'color',
      fixedColor: null,
      fixedProductOptionCategoryColorIndex: 0,
      fixedProductOptionCategoryColor: null,
      minStrokeWidth: 0,
      readOnly: false
    },
    relations: [{
      type: Backbone.HasOne,
      key: 'refImprintTechnology',
      relatedModel: 'ImprintTechnology'
    }, {
      type: Backbone.HasOne,
      key: 'fixedColor',
      relatedModel: 'Color'
    }],
    idAttribute: 'secId',

    destroy: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/' +
        this.get('refPrintArea').get('refProduct').get('secId') + '/printareas/' +
        this.get('refPrintArea').get('secId') + '/printzones/' + this.get('secId'));
      return Backbone.Model.prototype.destroy.call(this);
    },

    save: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/' +
        this.get('refPrintArea').get('refProduct').get('secId') + '/printareas/' +
        this.get('refPrintArea').get('secId') + '/printzones/');
      return Backbone.Model.prototype.save.call(this);
    }
  });

  app.ns.PrintZoneCollection = Backbone.Collection.extend({
    model: 'PrintZone',
    comparator: 'code'
  });

  app.ns.PrintArea = Backbone.RelationalModel.extend({

    defaults: {
      code: null,
      width: null,
      height: null,
      refBackgroundProductOptionCategory: null,
      name: new app.ns.DynamicText(),
      readOnly: false
    },

    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasMany,
      key: 'printZones',
      relatedModel: 'PrintZone',
      collectionType: 'PrintZoneCollection',
      reverseRelation: {
        key: 'refPrintArea',
        includeInJSON: false
      }
    }],
    idAttribute: 'secId',

    destroy: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/' +
        this.get('refProduct').get('secId') + '/printareas/' + this.get('secId'));
      return Backbone.Model.prototype.destroy.call(this);
    },

    save: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/' +
        this.get('refProduct').get('secId') + '/printareas');
      return Backbone.Model.prototype.save.call(this);
    }
  });

  app.ns.PrintAreaCollection = Backbone.Collection.extend({
    model: 'PrintArea',
    comparator: 'code'
  });

  app.ns.ProductCodeVariation = Backbone.RelationalModel.extend({

    defaults: {
      code: null,
      readOnly: false
    },

    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasMany,
      key: 'productOptions',
      relatedModel: 'ProductOption',
      collectionType: 'ProductOptionCollection',
      reverseRelation: {
        key: 'refProductCodeVariation',
        includeInJSON: false
      }
    }],
    idAttribute: 'secId',

    destroy: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/' +
        this.get('refProduct').get('secId') + '/productcodevariations/' + this.get('secId'));
      return Backbone.Model.prototype.destroy.call(this);
    },

    save: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/' +
        this.get('refProduct').get('secId') + '/productcodevariations');
      return Backbone.Model.prototype.save.call(this);
    }
  });

  app.ns.ProductCodeVariationCollection = Backbone.Collection.extend({
    model: 'ProductCodeVariation',
    comparator: 'code'
  });

  app.ns.Product = Backbone.RelationalModel.extend({
    defaults: {
      code: null,
      brand: '',
      comment: '',
      width: 0,
      height: 0,
      deepness: 0,
      weight: 0,
      minimumOrderQuantity: 1,
      readOnly: false
    },
    putUrl: Settings.url('compuzz2', 'v2/products/admin/'),
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasOne,
      key: 'description',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasMany,
      key: 'productOptionCategories',
      relatedModel: 'ProductOptionCategory',
      collectionType: 'ProductOptionCategoryCollection',
      reverseRelation: {
        key: 'refProduct',
        includeInJSON: false
      }
    }, {
      type: Backbone.HasMany,
      key: 'printAreas',
      relatedModel: 'PrintArea',
      collectionType: 'PrintAreaCollection',
      reverseRelation: {
        key: 'refProduct',
        includeInJSON: false
      }
    }, {
      type: Backbone.HasMany,
      key: 'productCodeVariations',
      relatedModel: 'ProductCodeVariation',
      collectionType: 'ProductCodeVariationCollection',
      reverseRelation: {
        key: 'refProduct',
        includeInJSON: false
      }
    }],

    save: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/');
      return Backbone.Model.prototype.save.call(this);
    },

    fetch: function () {
      this.url = Settings.url('compuzz2', 'v2/products/admin/' + this.get('secId'));
      return Backbone.Model.prototype.fetch.call(this);
    },

    idAttribute: 'secId'
  });

  App.reqres.setHandler('products-rel:model', function (productJson) {
    var tt = Backbone.Relational.store.find(app.ns.Product, productJson.secId);
    if (tt) {
      return tt;
    } else {
      return new app.ns.Product(productJson);
    }
  });

  App.reqres.setHandler('products-rel:printarea:model', function (printAreaJson) {
    var tt;
    if (printAreaJson) {
      tt = Backbone.Relational.store.find(app.ns.PrintArea, printAreaJson.secId);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.PrintArea(printAreaJson);
    }
  });

  App.reqres.setHandler('products-rel:printzone:model', function (printZoneJson) {
    var tt;
    if (printZoneJson) {
      tt = Backbone.Relational.store.find(app.ns.PrintZone, printZoneJson.secId);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.PrintZone(printZoneJson);
    }
  });

  App.reqres.setHandler('products-rel:color:model', function (colorJson) {
    var tt;
    if (colorJson) {
      tt = Backbone.Relational.store.find(app.ns.Color, colorJson.secId);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.Color(colorJson);
    }
  });

  App.reqres.setHandler('products-rel:optioncategory:model', function (optionCategoryJson) {
    var tt;
    if (optionCategoryJson) {
      tt = Backbone.Relational.store.find(app.ns.ProductOptionCategory, optionCategoryJson.secId);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.ProductOptionCategory(optionCategoryJson);
    }
  });

  App.reqres.setHandler('products-rel:optionvariation:model', function (optionVariationJson) {
    var tt;
    if (optionVariationJson) {
      tt = Backbone.Relational.store.find(app.ns.ProductCodeVariation, optionVariationJson.secId);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.ProductCodeVariation(optionVariationJson);
    }
  });

  App.reqres.setHandler('products-rel:option:model', function (optionJson) {
    var tt;
    if (optionJson) {
      tt = Backbone.Relational.store.find(app.ns.ProductOption, optionJson.secId);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.ProductOption(optionJson);
    }
  });
});
