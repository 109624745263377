define('webshopCategoriesDetailBodyCategoryFooterView',[
  'app',
  'module',
  'marionette',
  'buttonView',
  'underscore',
  'template!webshopCategoriesDetailBodyCategoryFooterView'
], function (
  App,
  Module,
  Marionette,
  ButtonView,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-categories-detail-body-category-footer-view',

    regions: {
      refreshButtonRegion: '.refresh-button-region',
      cleanCacheButtonRegion: '.clean-cache-button-region'
    },

    onRender: function() {
      this.refreshButtonView = new ButtonView({
        type: 'reload',
        color: 'lightgrey',
        title: _.i18n('common.refresh')
      });
      this.getRegion('refreshButtonRegion').show(this.refreshButtonView);
      this.refreshButtonView.on('click', _.bind(this.onRefresh, this));

      //---------------------------
      this.cleanCacheButtonView = new ButtonView({
        type: 'clean-cache',
        color: 'lightgrey',
        title: _.i18n('common.delete-cache')
      });
      this.getRegion('cleanCacheButtonRegion').show(this.cleanCacheButtonView);
      this.cleanCacheButtonView.on('click', _.bind(this.onCleanCache, this));
    },

    onRefresh: function() {
      this.refreshButtonView.loading(true);
      this.model.fetch().always(_.bind(function() {
        this.refreshButtonView.loading(false);
      },this));
    },

    onCleanCache: function() {
      this.cleanCacheButtonView.loading(true);
      this.model.clearCache().always(_.bind(function () {
        this.cleanCacheButtonView.loading(false);
      }, this));
    }
  });
});
