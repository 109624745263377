define('mailsMailRightCollapsedView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'dateUtils',
  'moment',
  'mailsMailRightCollapsedAttachmentsView',
  'mailsMailRightCollapsedPurchaseOrdersView',
  'template!mailsMailRightCollapsedView',
  'entities/mails'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  DateUtils,
  moment,
  AttachmentsView,
  PurchaseOrdersView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'mailRightCollapsed-view',

    ui: {
      attachmentsRegion: '.attachments-region',
      purchaseOrdersRegion: '.purchaseOrders-region'
    },

    regions: {
      attachmentsRegion: '.attachments-region',
      purchaseOrdersRegion: '.purchaseOrders-region'
    },

    onShow: function () {
      this.renderAttachments();
      this.renderPurchaseOrders();
    },

    renderPurchaseOrders: function () {
      var collection = new Backbone.Collection();

      _.each(this.model.get('purchaseOrders'), function (po) {
        collection.add(po);
      }, this);

      if (collection.models.length === 0) {
        this.ui.purchaseOrdersRegion.hide();
        return;
      }

      this.purchaseOrdersView = new PurchaseOrdersView({
        collection: collection,
        model: this.model,
        parentView: this,
        mailSecId: this.options.mailSecId,
        offerStatuses: this.options.offerStatuses,
        orderStatuses: this.options.orderStatuses
      });
      this.getRegion('purchaseOrdersRegion').show(this.purchaseOrdersView);
      this.ui.purchaseOrdersRegion.css('display', 'flex');
    },


    /*open: function (model, mailSecId) {
      require([
        'windows',
        'mailsMailView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View({
              model: model,
              mailSecId: mailSecId,
              parentView: this
            });
          }, this),
          id: 'Mail.' + model.get('id'),
          title: model.get('subject'),
          configName: 'Mail',
          width: 1200,
          height: 800,
          minWidth: 400,
          minHeight: 400
        });
      });
    }*/

    renderAttachments: function () {
      var collection = new Backbone.Collection();

      _.each(this.model.get('attachments'), function (attachment) {
        collection.add(attachment);
      }, this);

      if (collection.models.length === 0) {
        this.ui.attachmentsRegion.hide();
        return;
      }

      this.attachmentsView = new AttachmentsView({
        collection: collection,
        model: this.model,
        parentView: this,
        mailSecId: this.options.mailSecId
      });
      this.getRegion('attachmentsRegion').show(this.attachmentsView);
      this.ui.attachmentsRegion.css('display', 'flex');
    }

  });
});

