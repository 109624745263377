define('mailsListMailView',[
  'app',
  'module',
  'backbone',
  'tableFieldView',
  'dateUtils',
  'underscore',
  'mailsListMailLabelsView',
  'template!mailsListMailView',
  'notificationCenter'
], function (
  App,
  Module,
  Backbone,
  FieldView,
  DateUtils,
  _,
  LabelsView,
  Tpl,
  NotificationCenter
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'mail-view ' + FieldView.prototype.className,

    regions: {
      labelsRegion: '.labels-region'
    },

    initialize: function() {
        this._bindLabelEvents();
    },

    onDestroy: function() {
      this._removeLabelEvents();
    },

    _bindLabelEvents: function() {
        var nc = NotificationCenter.getInstance();

        nc.on(NotificationCenter.events.EMAILS_GMAIL_API_MESSAGE_LABELS_ADDED,
            this._handleLabelChange, this);

        nc.on(NotificationCenter.events.EMAILS_GMAIL_API_MESSAGE_LABELS_REMOVED,
            this._handleLabelChange, this);
    },

    _removeLabelEvents: function() {
        var nc = NotificationCenter.getInstance();

        nc.off(NotificationCenter.events.EMAILS_GMAIL_API_MESSAGE_LABELS_ADDED,
            this._handleLabelChange, this);

        nc.off(NotificationCenter.events.EMAILS_GMAIL_API_MESSAGE_LABELS_REMOVED,
            this._handleLabelChange, this);
    },

    _handleLabelChange: function(markAsRead, response) {
        if (!this._isValidResponse(response)) {
            return;
        }

        this._updateEmitterId(response);

        if (this._shouldProcessLabel(response)) {
            this.model.set('read', markAsRead);
            this.render();
        }
    },

    _isValidResponse: function(response) {
        return response &&
            response.content &&
            response.content.content &&
            response.content.content.text;
    },

    _updateEmitterId: function(response) {
        if (this.emitterId === undefined ||
            parseInt(response.content.emitterId, 10) > parseInt(this.emitterId, 10)) {
            this.emitterId = response.content.emitterId;
        }
    },

    _shouldProcessLabel: function(response) {
        return response.content.content.text === 'UNREAD' &&
            response.content.emitterId === this.emitterId &&
            response.content.content.entitySecId === this.model.get('id');
    },

    attributes: function() {
      var attributes = {};

      if(this.model.get('attachments') && this.model.get('attachments').length > 0)
        attributes['data-attachments'] = true;
      else
        attributes['data-attachments'] = false;

      return attributes;
    },

    serializeData: function() {
      var data = {};
      if(this.model) {
        data.unreadStatus = this.model.get('read') ? 'read' : 'unread';
        data.mail = this.model.getAddress();
        data.name = this.model.getName();
        data.subject = this.model.get('subject');

        data.date = DateUtils.toMDYHHMMFormat(this.model.get('sentDate'));

        data.hasAttachments = false;
        if (this.model.get('hasAttachments'))
          data.hasAttachments = true;
      }
      return data;
    },

    onRender: function() {
      this.labelsView = new LabelsView({
        model: this.model
      });
      this.getRegion('labelsRegion').show(this.labelsView);
    }
  });
});
