define('entities/orders',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore',
  'module',
  'moment',
  'notificationCenter',
  'compuzzProUtils',
  'entities/product',
  'entities/common',
  'backboneRelational'
], function (
  App,
  Backbone,
  Settings,
  $,
  _,
  module,
  moment,
  NotificationCenter,
  CompuzzProUtils
) {
  'use strict';

  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  //-----------------

  app.ns.Order = Backbone.RelationalModel.extend({

    defaults: {
      pim: false,
      agentId: '',
      userpids: [],
      proofsheetFileUid: null,
      exportFileUid: '',
      expShippingDate: null,
      nbrPackDelivered: 0,
      nbrPackSent: 0,
      toInvoice: false,
      internalComment: '',
      validationDate: null,
      paymentMethod: null,
      paymentTransactionNr: null,
      outsideEurope: false,
      difficultyLevel: null,
      deadlineDate: null,
      actionRequired: null
    },

    initialize: function () {
      this.comments = [];

      NotificationCenter.getInstance().on(
        NotificationCenter.events.CHANGE_INFO_ORDER,
        _.bind(function (response) {
          if (response && response.content && response.content.poi) {
            if (this.get('purchaseOrderId') === response.content.poi) {
              var data = API.parseListData(response.content);
              this.set(data);
            }
          }
        }, this),
        this
      );

      NotificationCenter.getInstance().on(
        NotificationCenter.events.DELETE_ORDER, _.bind(function (response) {
          if (response && response.content && response.content.poi) {
            if (this.get('purchaseOrderId') === response.content.poi)
              this.trigger('deleted');
          }
        }, this),
        this
      );

      NotificationCenter.getInstance().on(
        CompuzzProUtils.events.COMPUZZ_PRO_REFRESH_COMPOSITION, _.bind(function (response) {
          if (response && response.content && response.content.poi) {
            if (this.get('purchaseOrderId') === response.content.poi) {
              var data = API.parseListData(response.content);
              this.set(data);
            }
          }
        }, this),
        this
      );
    },

    /*relations: [{
      type: Backbone.HasMany,
      key: 'comments',
      relatedModel: 'OrderCommentModel',
      collectionType: 'OrderCommentsCollection'
    }],*/

    fetch: function () {
      this.url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId'));
      return Backbone.Model.prototype.fetch.call(this);
    },

    fetchListData: function () {
      var defer = $.Deferred();
      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/model'),
        contentType: 'application/json',
        success: function (item) {
          defer.resolve(item);
        },
        error: function (err) {
          defer.reject(err);
        }
      });
      return defer;
    },

    getModelData: function (orders) {
      var ordersPoi = '';
      for (var i = 0; i < orders.length; i++) {
        ordersPoi += orders[i].get('purchaseOrderId') + ',';
      }
      var defer = $.Deferred();
      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz2', 'commands.json/' + ordersPoi + '/model'),
        contentType: 'application/json',
        success: function (vrps) {
          defer.resolve(vrps);
        },
        error: function (err) {
          defer.reject(err);
        }
      });
      return defer;
    },

    parse: function (response, options) {
      if (response) {
        _.each(this.attributes, function (value, key) {
          if (!response[key]) {
            response[key] = null;
          }
        });

        response.poi = response.purchaseOrderId;

        if (!response.priority)
          response.priority = 0;

        if (!response.validated)
          response.validated = false;

        if (!response.archived)
          response.archived = false;

        if (!response.comments)
          response.comments = [];

        if (!response.pim)
          response.pim = false;

        //response.grouped = this.get('grouped');
        //response.groupedColor = this.get('groupedColor');

        response.grouped = this.get('grouped');
        response.minimized = this.get('minimized');
        response.position = this.get('position');
        response.trackings = this.get('trackings');
        response.attachments = this.get('attachments');
        response.supplierProductCode = this.get('supplierProductCode');
      }

      return Backbone.Model.prototype.parse.call(this, response, options);
    },

    getCode: function () {
      return this.get('endUserId');
    },

    getName: function () {
      return this.get('purchaseOrderId');
    },

    getProductName: function () {
      var productName;
      if (this.get('webShopLocale') &&
          this.get('productNameDt') &&
          this.get('productNameDt').values) {
        productName = this.get('productNameDt').values[this.get('webShopLocale').split('_')[0]];
      }
      if (!productName && this.get('productNameDt')) {
        productName = this.get('productNameDt').values[Settings.get('lang')];
      }
      if (!productName && this.get('productName')) {
        productName = this.get('productName');
      }
      if (!productName && this.get('productNameDt')) {
        productName = this.get('productNameDt').values.en;
      }

      if (productName)
        return productName;
      return '';
    },

    getClientName: function () {
      var properties = Settings.configValue('board.card.mainTitleProperty');

      var clientName = '';

      _.each(properties, function (property) {
        if (this.get(property) && clientName === '')
          clientName = this.get(property);
      }, this);

      if (clientName === '')
        clientName = this.get('endUserId');

      return clientName;
    },

    getImageUid: function () {
      return this.get('snapshotsUids');
    },

    getProofsheetFilename: function (customer) {
      var fileName = moment(this.get('createDate')).format('YYYYMMDD');
      if(this.get('companyName') && this.get('companyName') !== '')
        fileName += ' - ' + this.get('companyName');
      else {
        if(customer.get('refDeliveryAddress')) {
          if(customer.get('refDeliveryAddress').firstName &&
              customer.get('refDeliveryAddress').firstName !== '')
            fileName += ' - ' + customer.get('refDeliveryAddress').firstName;
          if(customer.get('refDeliveryAddress').lastName &&
              customer.get('refDeliveryAddress').lastName !== '')
            fileName += ' ' + customer.get('refDeliveryAddress').lastName;
        }
      }

      if(this.get('productBaseCode'))
        fileName += ' - ' + this.get('productBaseCode');

      fileName = fileName.trim();
      fileName = fileName.toLowerCase();
      fileName = fileName.replace(/[^a-z0-9]/g, ' ');
      fileName = fileName.trim();
      fileName = fileName.replace(/\s+/g, '-');

      return fileName;
    },

    getProofsheetDownloadUrl: function (fileName, customer) {
      var locale;
      if (customer.get('locales'))
        locale = customer.get('locales');

      var countryCode;
      if (customer.get('countryCode'))
        countryCode = customer.get('countryCode');

      return Settings.url('x2Ws', 'pdf', {
        param: btoa(Settings.getWebshopProofSheetUrl(locale, countryCode, this.get('purchaseOrderId'), true, true)),
        fileName: fileName
      });
    },

    getDeliveryFileUrl: function (pois, customer) {
      return Settings.url('dl', 'proforma', {
        pois: pois.join(','),
        template: 'PDF_DELIVERY_NOTE',
        fileName: this.getDeliveryFileName(pois, customer)
      });
    },

    getDeliveryFileName: function (pois, customer) {
      var fileName = _.i18n('orders.delivery-note.fileName');
      fileName += ' - ' + moment(this.get('createDate')).format('YYYYMMDD');

      if(this.get('companyName'))
        fileName += ' - ' + this.get('companyName').replace(/\u2013/g, '-');
      else {
        if(customer.get('refDeliveryAddress')) {
          if(customer.get('refDeliveryAddress').firstName &&
              customer.get('refDeliveryAddress').firstName !== '')
            fileName += ' - ' + customer.get('refDeliveryAddress').firstName;
          if(customer.get('refDeliveryAddress').lastName &&
              customer.get('refDeliveryAddress').lastName !== '')
            fileName += ' ' + customer.get('refDeliveryAddress').lastName;
        }
      }

      fileName += ' - ' + this.get('purchaseOrderId');

      return fileName;
    },

    compuzzVrps: function () {
      var vrpEndpointURL = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/compuzzVrps');
      if (this.get('pim')) {
        // For PIM products, ask the backend to also provide additional variation images.
        vrpEndpointURL = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/compuzzVrps?includePim=true');
      }
      var defer = $.Deferred();
      $.ajax({
        type: 'GET',
        url: vrpEndpointURL,
        contentType: 'application/json',
        success: function (vrps) {
          defer.resolve(vrps);
        },
        error: function (err) {
          defer.reject(err);
        }
      });
      return defer;
    },

    updateStatus: function (status) {
      return $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/status', {
          status: status
        }),
        contentType: false,
        dataType: false
      });
    },

    updateLabels: function (labels) {
      var defer = $.Deferred();
      var data = {
        purchaseOrderId: this.get('purchaseOrderId'),
        cardColor: labels
      };
      $.ajax({
        method: 'PUT',
        url: Settings.url('compuzz2', 'commands.json/update/lite'),
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    updateExpressShipping: function (value) {
      var defer = $.Deferred();
      var data = {
        purchaseOrderId: this.get('purchaseOrderId'),
        expressShip: value
      };
      $.ajax({
        method: 'PUT',
        url: Settings.url('compuzz2', 'commands.json/update/lite'),
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    addComment: function (comment) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/comments'),
        data: JSON.stringify(comment),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    deleteComment: function (comment) {
      var defer = $.Deferred();
      $.ajax({
        method: 'DELETE',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/comments'),
        data: JSON.stringify(comment),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    updateComment: function (comment) {
      var defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/comments'),
        data: JSON.stringify(comment),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    updateCustomerPayment: function (payedByCustomer) {
      var defer = $.Deferred();
      var data = {
        purchaseOrderId: this.get('purchaseOrderId'),
        payedByCustomer: payedByCustomer
      };
      $.ajax({
        method: 'PUT',
        url: Settings.url('compuzz2', 'commands.json/update/lite'),
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    changeCustomer: function (customerSecId) {
      var defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('secId') + '/changeCustomer/' + customerSecId),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    },

    updatePriority: function (priority) {
      var defer = $.Deferred();
      var data = {
        purchaseOrderId: this.get('purchaseOrderId'),
        priority: priority
      };
      $.ajax({
        method: 'PUT',
        url: Settings.url('compuzz2', 'commands.json/update/lite'),
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    updateFlag: function (flag) {
      var defer = $.Deferred();
      var data = {
        purchaseOrderId: this.get('purchaseOrderId'),
        flag: flag
      };
      $.ajax({
        method: 'PUT',
        url: Settings.url('compuzz2', 'commands.json/update/lite'),
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    updateType: function (type) {
      var defer = $.Deferred();
      var params = {
        type: type
      };
      $.ajax({
        method: 'POST',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/commandType', params),
        contentType: false,
        dataType: false,
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    saveTracking: function (tracking) {
      var defer = $.Deferred();
      var method = 'PUT';
      if (!tracking.get('secId'))
        method = 'POST';
      $.ajax({
        method: method,
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/tracking'),
        data: JSON.stringify(tracking.toJSON()),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    duplicate: function (parameter, requestParams) {
      return $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'commands.json/duplicate/' + this.get('purchaseOrderId'), requestParams),
        contentType: 'application/json',
        data: JSON.stringify(parameter)
      });
    },

    assignUser: function (userSecId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/assign', {
          user: userSecId,
          addNew: true
        }),
        type: 'PUT',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    unassignUser: function (userSecId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/unassign', {
          user: userSecId
        }),
        type: 'PUT',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    archive: function () {
      var defer = $.Deferred();
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('secId') + '/archive');
      $.ajax({
        method: 'PUT',
        url: url,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });

      return defer.promise();
    },

    unarchive: function () {
      var defer = $.Deferred();
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('secId') + '/unarchive');
      $.ajax({
        method: 'PUT',
        url: url,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });

      return defer.promise();
    },

    validate: function () {
      var defer = $.Deferred();
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('secId') + '/validate');
      $.ajax({
        method: 'POST',
        url: url,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });

      return defer.promise();
    },

    invalidate: function () {
      var defer = $.Deferred();
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('secId') + '/invalidate');
      $.ajax({
        method: 'POST',
        url: url,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });

      return defer.promise();
    },

    remove: function () {
      var defer = $.Deferred();
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('secId'));
      return $.ajax({
        method: 'DELETE',
        url: url,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function () {
          defer.reject();
        }
      });
    },

    resetPrice: function () {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2/reset');
      return $.ajax({
        method: 'POST',
        url: url
      });
    },

    resetBuyingPrice: function () {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2/reset/buyingPrice');
      return $.ajax({
        method: 'POST',
        url: url
      });
    },

    saveDeliveryAddress: function () {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/deliveryAddress');
      return $.ajax({
        method: 'PUT',
        url: url,
        contentType: 'application/json',
        data: JSON.stringify(this.get('refDeliveryAddress'))
      });
    },

    getPriceBuyingCustom: function (customPrice, forceUpdatePrice) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2/custom',
        {
          forceUpdatePrice: forceUpdatePrice,
          buyingPrice: true
        });
      return $.ajax({
        method: 'POST',
        url: url,
        data: JSON.stringify(customPrice),
        contentType: 'application/json'
      });
    },

    getPriceCustom: function (customPrice, forceUpdatePrice) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2/custom',
        {forceUpdatePrice: forceUpdatePrice});
      return $.ajax({
        method: 'POST',
        url: url,
        data: JSON.stringify(customPrice),
        contentType: 'application/json'
      });
    },

    setCustomScales: function (customScales) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2/customScales');
      return $.ajax({
        method: 'POST',
        url: url,
        data: JSON.stringify(customScales),
        contentType: 'application/json'
      });
    },

    getPrice: function (realTime, buyingPrice) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2',
        {
          buyingPrice: buyingPrice,
          realTime: realTime
        });
      return $.ajax({
        method: 'POST',
        url: url,
        contentType: 'application/json'
      });
    },

    getAttachments: function (first, pageSize) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        url: Settings.url('compuzz2', 'attachments/', {
          purchaseOrderId: this.get('purchaseOrderId'),
          first: first,
          pageSize: pageSize
        }),
        contentType: 'application/json',
        success: _.bind(function (items) {
          var attachments = [];
          _.each(items, _.bind(function (item) {
            item.purchaseOrderId = this.get('purchaseOrderId');
            attachments.push(new app.ns.OrderAttachmentModel(item));
          }, this));

          defer.resolve(attachments);
        }, this)
      });

      return defer.promise();
    },

    uploadAttachment: function (file) {
      var formData = new FormData();
      formData.append('file', file);
      formData.append('filename', file.name);

      var defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'attachments/commandAttachments/' + this.get('purchaseOrderId') + '/upload'),
        data: formData,
        processData: false,
        contentType: false,
        success: function (attachment) {
          defer.resolve(new app.ns.OrderAttachmentModel(attachment));
        },
        error: function () {
          defer.reject();
        }
      });

      return defer.promise();
    },

    deleteAttachment: function (attachment) {
      var defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: Settings.url('compuzz', 'attachments/commandAttachments/' + this.get('purchaseOrderId') + '/' + attachment.get('id')),
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    copyAttachmentToCustomer: function (attachment, customerSecId) {
      var defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'attachments/commandAttachments/' + this.get('purchaseOrderId') + '/copy/' + attachment.get('id') + '/customer/' + customerSecId), // jshint ignore:line
        success: function (attachment) {
          defer.resolve(attachment);
        }
      });

      return defer.promise();
    },

    checkPimData: function () {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/checkInPim');
      return $.ajax({
        method: 'GET',
        url: url,
        contentType: 'application/json'
      });
    },

    getImageUrls: function (product, vrps) {
      var imageUrls = [];
      if (this.get('pim')) {
        var options = '';
        _.each(this.get('options'), function (option) {
          if (options === '') {
            options += option.id;
          } else {
            options += ',' + option.id;
          }
        });
        if (vrps && vrps.length > 0) {
          _.each(vrps, _.bind(function (vrp) {
            imageUrls.push({url: Settings.url('x2png', 'orders/' + this.get('purchaseOrderId') + '/products/' +
                  this.get('productBaseCode') + '/configurator', {
              productPictureSecId: vrp.secId,
              pim: true
            }), secId: vrp.secId, ambiance: vrp.ambiance,
                // If we do have a VRP, provide the fileUid to allow to place print areas on this image
                imageUid: vrp.fileUid});
          }, this));
        } else {
          _.each(product.get('productPictures'), _.bind(function (productPicture) {
            imageUrls.push({url: Settings.url('x2png', 'product/' + this.get('productBaseCode') + '/compuLogo/pim', {
              productPictureSecId: productPicture.secId,
              options: options
            }), secId: productPicture.secId, ambiance: productPicture.ambiance});
          }, this));
        }
      } else {
        _.each(product.get('productPictures'), _.bind(function (productPicture) {
          imageUrls.push({url: Settings.url('x2png', 'orders/' + this.get('purchaseOrderId') + '/products/' + this.get('productCode') +
            '/configurator', {
            productPictureSecId: productPicture.secId
          }), secId: productPicture.secId, ambiance: productPicture.ambiance});
        }, this));
      }
      this.trigger('image:urls:loaded', imageUrls);
      return imageUrls;
    },

    changeQuantity: function (quantity) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/quantity', {
        quantity: quantity
      });
      return $.ajax({
        method: 'POST',
        url: url
      });
    },

    changeProductPim: function (productSecId) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/product/pim', {
        productSecId: productSecId
      });
      return $.ajax({
        method: 'PUT',
        url: url
      });
    },

    setDefaultVrp: function (vrpSecId) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('secId') + '/compuzzVrps/default/' + vrpSecId);
      return $.ajax({
        method: 'PUT',
        url: url
      });
    },

    hideVrp: function (vrpSecId) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('secId') + '/compuzzVrps/hide/' + vrpSecId);
      return $.ajax({
        method: 'PUT',
        url: url
      });
    },

    showVrp: function (vrpSecId) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('secId') + '/compuzzVrps/show/' + vrpSecId);
      return $.ajax({
        method: 'PUT',
        url: url
      });
    },

    changeProduct: function (productSecId) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/product', {
        productSecId: productSecId
      });
      return $.ajax({
        method: 'PUT',
        url: url
      });
    },

    prepareSupplierOrder: function() {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        url: Settings.url('compuzz2', 'v2/supplierOrders/'+this.get('secId')+'/prepareOrderToSupplier'),
        success: _.bind(function (response) {
          defer.resolve(response);
        }, this),
        error: function (err) {
          defer.reject(err);
        }
      });
      return defer.promise();
    },

    sendOrderToSupplier: function(supplierOrderModel) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        data: JSON.stringify(supplierOrderModel),
        contentType: 'application/json',
        url: Settings.url('compuzz2', 'v2/supplierOrders/'+this.get('secId')+'/sendOrderToSupplier'),
        success: _.bind(function (response) {
          defer.resolve(response);
        }, this),
        error: function (err) {
          defer.reject(err);
        }
      });
      return defer.promise();
    },

    convertPimToCompuzz: function () {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/convertPimToCompuzz'),
        success: _.bind(function (response) {
          this.set(response);
          defer.resolve();
        }, this),
        error: function (err) {
          defer.reject(err);
        }
      });
      return defer.promise();
    },

    getProofsheetHistoryUrl: function () {
      return Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/proofsheetHistory');
    },

    getProofsheetHistoryRowCountUrl: function () {
      return 'commands.json/' + this.get('purchaseOrderId') + '/proofsheetHistory/rowCount';
    },

    getInvoicesUrl: function (params) {
      return Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/invoice', params);
    },

    getInvoicesRowCountUrl: function (params) {
      return Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/invoice/rowCount', params);
    },

    getMailsUrl: function (params) {
      return Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/mails', params);
    },
    getMailsRowCountUrl: function (params) {
      return Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/mails/rowCount', params);
    },

    deleteCustomProofsheetHistory: function (secId) {
      var defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/history/' + secId),
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    idAttribute: 'purchaseOrderId'
  });

  var GroupModel = Backbone.Model.extend({

    initialize: function () {
      this.set('createDate', null);
      this.set('priority', 0);
      this.set('deadlineDate', null);
      this.set('expShippingDate', null);
      this.set('payedByCustomer', false);
    },

    add: function (order) {
      var position = this.get('orders').models.length;
      if(this.get('position') >= 0)
        position = order.get('position');
      this.set('position', position);
      this.get('orders').add(order, {at: position, sort: false});
      var payedByCustomer = true;

      _.each(this.get('orders').models, _.bind(function (order) {
        if(order.get('priority') > this.get('priority'))
          this.set('priority', order.get('priority'));
        if(new Date(order.get('deadlineDate')) < new Date(this.get('deadlineDate')))
          this.set('deadlineDate', order.get('deadlineDate'));
        if(new Date(order.get('expShippingDate')) < new Date(this.get('expShippingDate')))
          this.set('expShippingDate', order.get('expShippingDate'));
        if(new Date(order.get('createDate')) < new Date(this.get('createDate')))
          this.set('createDate', order.get('createDate'));
        if(!order.get('payedByCustomer'))
          payedByCustomer = false;
      }, this));

      this.set('payedByCustomer', payedByCustomer);
      this.trigger('change:orders');
    }
  });


  /*var OrderCommentModel = Backbone.Model.extend();

  var OrderCommentsCollection = Backbone.Collection.extend({
    model: OrderCommentModel,

    url: function () {
      return Settings.url('compuzz2', 'commands.json');
    },

    save: function (poi) {

      var url = Settings.url('compuzz2', 'commands.json/' + poi + '/comment'),
        defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        url: url,
        data: JSON.stringify(this.toJSON()),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    comparator: function (a, b) {
      if (a.get('msgDate') < b.get('msgDate')) return 1;
      else return -1;
    }
  });*/

  app.ns.OrderAttachmentsCollection = Backbone.Collection.extend({
    fetch: function () {
      //this.url = Settings.url('compuzz2', 'attachments/', params);
      return Backbone.Collection.prototype.fetch.call(this);
    }
  });

  //-----------------

  app.ns.OrderAttachmentModel = Backbone.Model.extend({
    defaults: {
      id: 0,
      downloadLink: null,
      fileLink: null,
      fileName: null
    },

    url: function () {
      return Settings.url('compuzz2', 'attachments', {purchaseOrderId: this.get('purchaseOrderId')});
    }
  });

  var API = {
    getModel: function (data) {
      if (!data)
        data = {};
      return app.ns.Order.findOrCreate(data);
    },

    parseListData: function (listData) {
      var data = {};
      data.secId = listData.secId;
      data.purchaseOrderId = listData.poi;
      data.poi = listData.poi;
      data.createDate = listData.date;
      data.status = listData.status;
      data.commandType = listData.commandType;
      if (listData.userpids)
        data.userpids = listData.userpids;
      data.snapshotsUids = listData.snapshotUid;
      data.endUserId = listData.endUserId;
      data.customerName = listData.customerName; //?
      data.companyName = listData.companyName;
      data.contactPerson = listData.contactPerson;
      data.price = listData.price;
      data.buyingPrice = listData.buyingPrice;
      data.quantity = listData.quantity;
      data.pim = false;
      if (listData.pim)
        data.pim = listData.pim;
      data.validated = false;
      if (listData.validated)
        data.validated = listData.validated;
      data.productName = listData.productName;
      data.archived = false;
      if (listData.archived)
        data.archived = listData.archived;
      data.productBaseCode = listData.productBaseCode;
      data.outsideEurope = listData.outsideEurope;
      //data.productOwner = listData.productOwner; //?
      data.supplier = listData.supplier;
      if (listData.supplier && listData.supplier.code)
        data.productOwner = listData.supplier.code;
      data.supplierProductCode = listData.supplierProductCode;
      data.supplierProductNameDt = listData.supplierProductNameDt;

      data.productSecId = listData.productSecId; //?
      data.productNameDt = listData.productNameDt;
      data.cardColor = listData.cardColor;
      data.payedByCustomer = listData.payedByCustomer;
      data.toInvoice = listData.toInvoice;
      data.expShippingDate = null;
      if(listData.expShippingDate)
        data.expShippingDate = listData.expShippingDate.replace('CET', '')
            .replace('CEST', '');

      if(listData.deadlineDate)
        data.deadlineDate = listData.deadlineDate.replace('CET', '')
            .replace('CEST', '');

      data.nbrPackSent = listData.nbrPackSent;
      data.nbrPackSentToPrinter = listData.nbrPackSentToPrinter;
      data.nbrPackDelivered = listData.nbrPackDelivered;
      data.nbrPackDeliveredToPrinter = listData.nbrPackDeliveredToPrinter;
      data.actionRequiredInternal = listData.actionRequiredInternal;
      data.actionRequiredUkr = listData.actionRequiredUkr;
      data.priority = 0;
      if (listData.priority)
        data.priority = listData.priority;
      data.comments = [];
      if (listData.comments)
        data.comments = listData.comments;
      data.nbrOrderSameCustomer = listData.nbrOrderSameCustomer;
      data.flag = listData.flag; //??
      data.attachments = [];
      if (listData.attachments)
        data.attachments = listData.attachments;
      data.webShopLocale = listData.webShopLocale;
      data.trackings = [];
      if (listData.trackings)
        data.trackings = listData.trackings;
      if(listData.refDeliveryAddress)
        data.refDeliveryAddress = listData.refDeliveryAddress;
      data.compositions = listData.compositions;
      data.options = listData.options;
      data.customer = listData.customer;
      data.expressShip = listData.expressShip;
      data.hiddenVrps = listData.hiddenVrps;
      data.defaultVrp = listData.defaultVrp;
      data.supplierNumber = listData.supplierNumber;
      data.inboxEmailCount = listData.inboxEmailCount;
      return data;
    },

    getListData: function (orders) {
      var pois = '';
      for (var i = 0; i < orders.length; i++) {
        pois += orders[i].get('purchaseOrderId') + ',';
      }
      var defer = $.Deferred();
      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz2', 'commands.json/' + pois + '/model'),
        contentType: 'application/json',
        success: function (orders) {
          defer.resolve(orders);
        },
        error: function (err) {
          defer.reject(err);
        }
      });
      return defer;
    },

    generateInvoice: function (data, paymentDelay) {
      if (!Array.isArray(data)) {
        data = [data];
      }

      var params = {
        paymentDelay: paymentDelay
      };

      var url = Settings.url('compuzz2', 'commands.json/generateInvoices', params),
        defer = $.Deferred();
      $.ajax({
        method: 'POST',
        url: url,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        complete: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        }
      });
      return defer.promise();
    },

    invoiceSent: function (secId, email) {
      var defer = $.Deferred();
      var params = {
        secId: secId,
        sentDate: moment().format('YYYY-MM-DDTHH:mm:ss') + '.000+0000',
        sentEmail: email
      };

      $.ajax({
        method: 'PUT',
        url: Settings.url('compuzz2', 'v2/enduserinvoice/update/lite'),
        data: JSON.stringify(params),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        complete: function (response) {
          defer.resolve(response);
        },
        error: function (error) {
          defer.reject(error);
        }
      });
      return defer.promise();
    },

    updateOrder: function (data) {
      var url = Settings.url('compuzz2', 'commands.json/update/lite'),
        defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        url: url,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    updateOptionsFromConfiguration: function (purchaseOrderId, options) {
      var optionsArray = $.map(options, function (value) {
        return [value];
      });

      var index = 0;
      var params = {
        optionId: optionsArray[index]
      };
      var url = Settings.url('compuzz2', 'commands.json/' + purchaseOrderId + '/option', params);
      return this.doOptionCall(purchaseOrderId, 0, params, url, optionsArray);
    },

    doOptionCall: function (poi, index, params, url, options) {
      var that = this,
        defer = $.Deferred();
      $.ajax({
        type: 'POST',
        url: url,
        complete: function (resp) {
          if (index < options.length - 1) {
            index++;
            var params = {
              optionId: options[index]
            };
            var url = Settings.url('compuzz2', 'commands.json/' + poi + '/option', params);
            that.doOptionCall(poi, index, params, url, options).done(function (response) {
              defer.resolve(response);
            });

          } else {
            defer.resolve(resp);
          }
        }
      });
      return defer.promise();
    },

    updateMixOptionsFromConfiguration: function (purchaseOrderId, mixOptions) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + purchaseOrderId + '/mixableoption');
      $.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(mixOptions),
        contentType: 'application/json',
        complete: function (resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    },

    updateFromConfiguration: function (purchaseOrderId, configuration, pim) {
      var config = {
        quantity: configuration.quantity,
        options: [],
        optionsMix: [],
        printZonesColors: {}
      };

      for (var propertyName in configuration.options) {
        if (configuration.options.hasOwnProperty(propertyName)) {
          config.options.push(configuration.options[propertyName]);
        }
      }

      for (var propertyName1 in configuration.printZones) {
        if (configuration.printZones.hasOwnProperty(propertyName1)) {
          var pzConf = configuration.printZones[propertyName1];
          config.printZonesColors[pzConf.pzId] = pzConf.nbrColor;
        }
      }

      if(configuration.optionsMix)
        config.optionsMix = configuration.optionsMix;

      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/update/offer/' + purchaseOrderId + (pim ? '/pim' : ''));
      $.ajax({
        type: 'PUT',
        url: url,
        data: JSON.stringify(config),
        contentType: 'application/json',
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getOrders: function (params) {
      return Settings.url('compuzz2', 'commands.json/board', params);
    },

    getOrdersCount: function (params) {
      return Settings.url('compuzz2', 'commands.json/board/rowCount', params);
    },

    getHistory: function (poi, params) {
      params = params || {};
      return Settings.url('compuzz2', 'commands.json/' + poi + '/history', params);
    },

    getHistoryRowCount: function (poi, params) {
      params = params || {};
      _.defaults(params, {});
      //var params = '';
      return Settings.url('compuzz2', 'commands.json/' + poi + '/history/rowCount', params);
    },

    getStatuses: function (type) {
      var defer = $.Deferred();

      if (type !== 'offer')
        type = '';

      var statuses = null;
      if (type === '')
        statuses = Settings.get('orderStatusesOffer');
      if (type === 'offer')
        statuses = Settings.get('orderStatuses');

      if (statuses) {
        defer.resolve(statuses);
        return defer.promise();
      }

      statuses = new Backbone.Collection();
      var params = {
        locale: Settings.get('lang'),
        tag: type
      };

      statuses.url = Settings.url('compuzz2', 'commands.json/command/status', params);
      statuses.fetch({
        success: _.bind(function (data) {
          if (type === '')
            Settings.set('orderStatusesOffer', data);
          if (type === 'offer')
            Settings.set('orderStatuses', data);
          defer.resolve(data);
        }, this)
      });

      return defer.promise();
    },

    getKanbanStatuses: function (type) {
      if (type !== 'offer')
        type = '';

      var statuses = new Backbone.Collection();
      var params = {
        locale: Settings.get('lang'),
        tag: type
      };
      var defer = $.Deferred();

      statuses.url = Settings.url('compuzz2', 'commands.json/command/status/kanban', params);
      statuses.fetch({
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getTypes: function () {
      var defer = $.Deferred();

      var data = [
        {code: 'command', name: _.i18n('common.command')},
        {code: 'offer', name: _.i18n('common.offer')}
      ];

      Settings.set('orderTypes', data);

      defer.resolve(data);

      return defer.promise();
    },

    uploadCustomExport: function (poi, fileInfo) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/' + poi + '/customExport/upload');
      $.ajax({
        type: 'POST',
        url: url,
        data: fileInfo.formData,
        processData: false,
        contentType: false,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    },

    deleteCustomExport: function (poi) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/' + poi + '/customExport');

      $.ajax({
        type: 'DELETE',
        url: url,
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    uploadCustomProofsheet: function (poi, fileInfo) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/' + poi + '/customProofsheet/upload');
      $.ajax({
        type: 'POST',
        url: url,
        data: fileInfo.formData,
        processData: false,
        contentType: false,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    deleteCustomProofsheet: function (poi) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/' + poi + '/customProofsheet');

      $.ajax({
        type: 'DELETE',
        url: url,
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    updateUseBackgroundColor: function (poi, compSecId, useBackgroundColor) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + poi + '/compositions/' + compSecId,
          {
            useBackgroundColor: useBackgroundColor
          });

      $.ajax({
        type: 'PUT',
        url: url,
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    updatePrintZone: function (poi, oldPzId, newPzId, nbrColor) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/' + poi + '/oldprintzone/' + oldPzId + '/newprintzone/' + newPzId, {
          numberOfColor: nbrColor
        });
      $.ajax({
        type: 'PUT',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    removePrintZone: function (poi, printZone) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/' + poi + '/removeprintzone/' + printZone);
      $.ajax({
        type: 'PUT',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        },
        success: function (response) {
          defer.resolve(response);
        },
        error: function (error) {
          defer.reject(error);
        }
      });

      return defer.promise();
    },

    addPrintZone: function (poi, printZone, nbrColors) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/' + poi + '/addprintzone/' + printZone, {
          numberOfColor: nbrColors
        });

      $.ajax({
        type: 'PUT',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    addPrintArea: function (poi, printArea) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + poi + '/addprintarea/' + printArea);
      $.ajax({
        type: 'PUT',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    changeNbrColors: function (compositionSecId, nbrColors) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'personalization.json/compositions/' + compositionSecId + '/nbrColors', {
          nbrColors: nbrColors
        });
      $.ajax({
        type: 'PUT',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    listOrderDocumentSupplier: function (purchaseOrderId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'v2/supplierOrders/list/' + purchaseOrderId);
      $.ajax({
        type: 'GET',
        url: url,
        complete: function (resp) {
          if(resp.responseJSON && resp.responseJSON.id) {
            defer.resolve(resp.responseJSON);
          } else {
            defer.resolve(null);
          }
        }
      });
      return defer.promise();
    },

    orderInvoiceSupplier: function (purchaseOrderId, invoiceId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'v2/supplierOrders/invoice/' + purchaseOrderId + '/id/' + invoiceId);
      $.ajax({
        type: 'GET',
        url: url,
        complete: function (resp) {
          if (resp.responseJSON) {
            defer.resolve(resp.responseJSON);
          } else {
            defer.resolve(null);
          }
        }
      });
      return defer.promise();
    },

    generateCreditNote: function (invoiceSecId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'v2/enduserinvoice/' + invoiceSecId + '/creditNote');
      $.ajax({
        type: 'POST',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    },

    createFromConfiguration: function (type, productSecId, endUserId, customerId, configuration, pim) {
      var currentUser =  Settings.get('currentUser');
      var userpidSecId = null;
      if (currentUser)
        userpidSecId = currentUser.secId;

      var config = {
        quantity: configuration.quantity,
        options: [],
        optionsMix: [],
        printZonesColors: {},
        printZonesImages: {},
        attachments: configuration.attachments,
        comment: configuration.comment,
        userpidSecId: userpidSecId
      };

      for (var propertyName in configuration.options) {
        if (configuration.options.hasOwnProperty(propertyName)) {
          config.options.push(configuration.options[propertyName]);
        }
      }

      for (var propertyName3 in configuration.optionsMix) {
        if (configuration.optionsMix.hasOwnProperty(propertyName3)) {
          config.optionsMix.push(configuration.optionsMix[propertyName3]);
        }
      }

      for (var propertyName1 in configuration.printZones) {
        if (configuration.printZones.hasOwnProperty(propertyName1)) {
          var pzConf = configuration.printZones[propertyName1];
          config.printZonesColors[pzConf.pzId] = pzConf.nbrColor;
        }
      }

      for (var propertyName2 in configuration.printZonesImages) {
        if (configuration.printZonesImages.hasOwnProperty(propertyName2)) {
          var pzConf2 = configuration.printZonesImages[propertyName2];
          config.printZonesImages[pzConf2.pzId] = pzConf2.fileContent;
        }
      }

      var params = {
        endUserId: endUserId,
        customerId: customerId,
        sync: true
      };
      var defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'commands.json/create/' + type + '/' + productSecId + (pim ? '/pim' : ''), params),
        data: JSON.stringify(config),
        contentType: 'application/json',
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    updateCompositionCustomData: function (purchaseOrderId, compositionSecId, customData) {
      var defer = $.Deferred(),
          url = Settings.url('compuzz2', 'commands.json/'+purchaseOrderId+'/compositions/'+compositionSecId+'/customData');
      $.ajax({
        type: 'PUT',
        url: url,
        data: JSON.stringify(customData),
        contentType: 'application/json',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    }
  };

  var OrderView = Backbone.Model.extend({

    initialize: function () {
      this.set('poi', null);
      this.set('order', App.request('order:model'));
      this.set('product', App.request('products:model'));
    },

    fetch: function (fetchProduct) {

      var defer = $.Deferred();

      var order = App.request('order:model', {purchaseOrderId: this.get('poi')});

      this.set('order', order, {silent: true});

      this.get('order').fetch().done(_.bind(function () {

        if (fetchProduct === true) {
          var product = null;

          if (this.get('order').get('pim')) {

            product = App.request('products:model', this.get('order').get('productJson'));
            this.set('product', product, {silent: true});
            defer.resolve();
            this.trigger('change');
            this.trigger('productChange');
          } else {

            product = App.request('products:model', {secId: this.get('order').get('productSecId')});
            product.fetch().done(_.bind(function () {
              this.set('product', product, {silent: true});
              defer.resolve();
              this.trigger('change');
              this.trigger('productChange');
            }, this));
          }
        } else {
          this.trigger('change');
        }

      }, this));

      return defer.promise();
    }
  });



  App.reqres.setHandler('order:update', function (orderModel) {
    return API.updateOrder(orderModel);
  });

  App.reqres.setHandler('order:update-options-from-configuration', function (purchaseOrderId, option) {
    return API.updateOptionsFromConfiguration(purchaseOrderId, option);
  });

  App.reqres.setHandler('order:update-mixoptions-from-configuration', function (purchaseOrderId, option) {
    return API.updateMixOptionsFromConfiguration(purchaseOrderId, option);
  });

  App.reqres.setHandler('order:update-from-configuration', function (purchaseOrderId, config) {
    return API.updateFromConfiguration(purchaseOrderId, config, false);
  });

  App.reqres.setHandler('order:update-from-configuration-pim', function (purchaseOrderId, config) {
    return API.updateFromConfiguration(purchaseOrderId, config, true);
  });

  App.reqres.setHandler('order:generate-invoice', function (lstPoi, paymentDelay) {
    return API.generateInvoice(lstPoi, paymentDelay);
  });

  App.reqres.setHandler('order:invoice-sent', function (secId, email) {
    return API.invoiceSent(secId, email);
  });

  App.reqres.setHandler('order:get-orders', function (params) {
    return API.getOrders(params);
  });

  App.reqres.setHandler('order:get-orders-count', function (params) {
    return API.getOrdersCount(params);
  });

  App.reqres.setHandler('order:history-get', function (purchaseOrderId, params) {
    return API.getHistory(purchaseOrderId, params);
  });
  App.reqres.setHandler('order:history-row-count', function (purchaseOrderId, params) {
    return API.getHistoryRowCount(purchaseOrderId, params);
  });

  App.reqres.setHandler('order:types', function () {
    return API.getTypes();
  });

  App.reqres.setHandler('order:model', function (data) {
    return API.getModel(data);
  });

  App.reqres.setHandler('group-model', function (data) {
    return new GroupModel(data);
  });

  App.reqres.setHandler('order:parse-list-data', function (data) {
    return API.parseListData(data);
  });

  /*App.reqres.setHandler('order:comment:model', function (modelJson) {
    if (!modelJson)
      modelJson = {};

    return new OrderCommentModel(modelJson);
  });*/

  App.reqres.setHandler('kanban:create-from-configuration', function (type, productSecId, endUserId, customerId, config) {
    return API.createFromConfiguration(type, productSecId, endUserId, customerId, config, false);
  });
  App.reqres.setHandler('kanban:create-from-configuration-pim', function (type, productSecId, endUserId, customerId, config) {
    return API.createFromConfiguration(type, productSecId, endUserId, customerId, config, true);
  });

  App.reqres.setHandler('order-view:model', function (orderJSON) {
    return new OrderView(orderJSON);
  });

  App.reqres.setHandler('order:upload-custom-export', function (poi, fileinfo) {
    return API.uploadCustomExport(poi, fileinfo);
  });

  App.reqres.setHandler('order:delete-custom-export', function (poi) {
    return API.deleteCustomExport(poi);
  });

  App.reqres.setHandler('order:upload-custom-proofsheet', function (poi, fileinfo) {
    return API.uploadCustomProofsheet(poi, fileinfo);
  });

  App.reqres.setHandler('order:delete-custom-proofsheet', function (poi) {
    return API.deleteCustomProofsheet(poi);
  });

  App.reqres.setHandler('order:update-use-background-color', function (poi, compSecId, useBackgroundColor) {
    return API.updateUseBackgroundColor(poi, compSecId, useBackgroundColor);
  });

  App.reqres.setHandler('order:update-type', function (poi, commandType) {
    return API.updateCommandType(poi, commandType);
  });

  App.reqres.setHandler('order:update-printzone', function (poi, oldpzId, newPzId, nbrColor) {
    return API.updatePrintZone(poi, oldpzId, newPzId, nbrColor);
  });

  App.reqres.setHandler('order:remove-print-zone', function (poi, pzToRemove) {
    return API.removePrintZone(poi, pzToRemove);
  });

  App.reqres.setHandler('order:add-printarea', function (poi, paToAdd) {
    return API.addPrintArea(poi, paToAdd);
  });

  App.reqres.setHandler('order:add-printzone', function (poi, pzToAdd, nbrColors) {
    return API.addPrintZone(poi, pzToAdd, nbrColors);
  });

  App.reqres.setHandler('order:change-composition-nbr-colors', function (compositionSecId, nbrColors) {
    return API.changeNbrColors(compositionSecId, nbrColors);
  });

  App.reqres.setHandler('order-invoices:credit-note', function (invoiceSecId) {
    return API.generateCreditNote(invoiceSecId);
  });

  App.reqres.setHandler('order-documents-supplier:list', function (purchaseOrderId) {
    return API.listOrderDocumentSupplier(purchaseOrderId);
  });

  App.reqres.setHandler('order-documents-supplier:invoice', function (purchaseOrderId, invoiceId) {
    return API.orderInvoiceSupplier(purchaseOrderId, invoiceId);
  });

  App.reqres.setHandler('orders:statuses', function (type) {
    return API.getStatuses(type);
  });

  App.reqres.setHandler('orders:kanban-statuses', function (type) {
    return API.getKanbanStatuses(type);
  });

  App.reqres.setHandler('orders:get-list-data', function (orders) {
    return API.getListData(orders);
  });

  App.reqres.setHandler('composition:update:customdata', function (purchaseOrderId, compositionSecId, customData) {
    return API.updateCompositionCustomData(purchaseOrderId, compositionSecId, customData);
  });

  module.exports = {
    popup: null,
    detaiView: null,
    backUri: '',

    showOrder: function (poi, tabName, updateMenu = false) {
      require([
        'contentShowController',
        'ordersDetailView',
        'itemPagesNameMixin'
      ], _.bind(function (ContentShowController, View) {
        if (tabName === '')
          tabName = 'details';

        ContentShowController.showContent(false).done(_.bind(function () {
          if (this.detaiView !== null) {
            if (poi !== this.detaiView.poi)
              this.closeOrder();
          }

          if (this.detaiView === null) {
            this.backUri = App.getCurrentRoute();
            this.detaiView = new View({
              poi: poi,
              tabName: tabName,
              updateMenu: updateMenu
            });

            var region = App.regions.getRegion('main').currentView.getRegion('orderRegion');
            region.show(this.detaiView);
            region.$el.css('display', 'flex');
          } else
            this.detaiView.setSelectedTab(tabName);

          App.navigate('#orders/' + poi + '/' + tabName, {trigger: false});
          App.currentUrl = App.getCurrentRoute();
        }, this));
      }, this));
    },

    closeOrder: function (back = true) {
      if (this.detaiView !== null) {
        //this.popup.modal('hide');
        var region = App.regions.getRegion('main').currentView.getRegion('orderRegion');
        region.$el.hide();
        var orderType = 'command';
        if (this.detaiView.model && this.detaiView.model.get('order') &&
            this.detaiView.model.get('order').get('commandType'))
          orderType = this.detaiView.model.get('order').get('commandType');

        this.detaiView.destroy();
        this.detaiView = null;

        if (back && this.backUri !== '') {
            App.navigate(this.backUri, {trigger: false});
        }
        this.backUri = '';

        require([
          'contentShowController',
          'ordersBoardView',
          'itemPagesNameMixin'
        ], _.bind(function (ContentShowController, View, ItemPagesNameMixin) {
          var currentView = App.regions.getRegion('main').currentView.getRegion('content').currentView;
          if (!currentView) {
            ContentShowController.showContent(false).done(_.bind(function () {
              var view = new View({
                parentStatus: null,
                endUserId: null,
                type: orderType
              });

              App.regions.getRegion('main').currentView.getRegion('content').show(view);

              if(orderType === 'offer')
                App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS_BOARD);
              else
                App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS_BOARD);
            }, this));
          }
        }, this));
      }
    },

    loading: function () {
      /*if (this.detaiView !== null) {
        this.detaiView.loading(value);
      }*/
    }
  };
});

