define('webshopCategoriesListToolsView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'tableFieldView',
  'buttonView',
  'template!webshopCategoriesListToolsView'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  FieldView,
  ButtonView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-categories-list-tools-view ' + FieldView.prototype.className,

    regions: {
      cleanCacheButtonRegion: '.clean-cache-button-region'
    },

    onRender: function () {
      this.cleanCacheButtonView = new ButtonView({
        type: 'clean-cache',
        color: 'lightgrey',
        title: _.i18n('common.delete-cache')
      });
      this.getRegion('cleanCacheButtonRegion').show(this.cleanCacheButtonView);
      this.cleanCacheButtonView.on('click', _.bind(this.onCleanCache, this));
    },

    onCleanCache: function () {
      this.cleanCacheButtonView.loading(true);
      this.model.clearCache().always(_.bind(function () {
        this.cleanCacheButtonView.loading(false);
      }, this));
    }
  });
});
