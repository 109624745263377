define('webshopLandingpagesDetailBodyUrlsView',[
  'module',
  'settings',
  'headerFooterView',
  'webshopLandingpagesDetailBodyUrlsBodyView',
  'webshopLandingpagesDetailBodyUrlsFooterView',
  'template!webshopLandingpagesDetailBodyUrlsView'
], function (
  Module,
  Settings,
  HeaderFooterView,
  BodyView,
  FooterView,
  Tpl
) {
  'use strict';

  Module.exports = HeaderFooterView.extend({
    template: Tpl,
    className: 'webshop-landingpages-detail-body-urls-view ' + HeaderFooterView.prototype.className,
    childView: BodyView,
    childFooterView: FooterView,

    serializeData: function () {
      var data = {};
      var url = Settings.configValue('webshop.url');
      data.adminUrl = url + '/admin/default/jqadm/get/landingpage/' + this.model.getId();
      return data;
    }
  });
});
