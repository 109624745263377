define('mailsMailRightCollapsedAttachmentsView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'marionette',
  'mailsMailRightCollapsedAttachmentView',
  'template!mailsMailRightCollapsedAttachmentsView'
], function (
  Module,
  App,
  Backbone,
  _,
  Marionette,
  AttachmentView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'attachments-view',
    childView: AttachmentView,
    childViewContainer: '.attachments',

    childViewOptions: function() {
      return {
        mailModel: this.model,
        mailSecId: this.options.mailSecId
      };
    }
  });
});
