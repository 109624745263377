
define('template!webshopProductsDetailBodyProductFooterView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="left">\n  <div class="loader-2"></div>\n</div>\n<div class="right">\n    <div class="refresh-button-region"></div>\n    <div class="clean-cache-button-region"></div>\n</div>';

}
return __p
};});

