define('webshopLandingpagesDetailBodyView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'rolesMixin',
  'tabsViewView',
  'horizontalCollapseView',
  'webshopLandingpagesDetailBodyUrlsView'
], function (
  App,
  Module,
  Marionette,
  _,
  RolesMixin,
  TabsView,
  HorizontalCollapseView
) {
  'use strict';

  Module.exports = TabsView.extend({
    selectedTab: 'urls',

    tabs: function () {
      return [{
        name: 'urls',
        url: 'urls',
        title: _.i18n('common.urls'),
        role: RolesMixin.DASHBOARD_WEBSHOP_LANDINGPAGES_URLS,
        view: 'webshopLandingpagesDetailBodyUrlsView'
      }, {
        name: 'urls2',
        url: 'urls2',
        title: _.i18n('common.urls2'),
        role: RolesMixin.DASHBOARD_WEBSHOP_LANDINGPAGES_URLS,
        view: 'webshopLandingpagesDetailBodyUrlsView'
      }];
    },

    getChildView: function () {
      return HorizontalCollapseView.extend({
        childLeftView: Marionette.LayoutView.extend({template: _.template('')}),
        childRightView: Marionette.LayoutView.extend({template: _.template('')})
      });
    },

    onSelected: function (tab) {
      this.url = 'webshop/landingpages/' + this.options.id;
      if (tab.get('url') !== '')
        this.url += '/' + tab.get('url');
      App.navigate(this.url, {trigger: false});
    }
  });
});

