define('webshopProductsFinderListView',[
  'module',
  'underscore',
  'app',
  'backbone',
  'windows',
  'tableView',
  'webshopProductsListImageView',
  'webshopProductsListInfosView',
  'webshopProductsListSupplierView',
  'webshopProductsFinderListFiltersView',
  'webshopProductsFinderListToolsView',
  'webshopProductsFinderListHeaderToolsView',
  'entities/webshop/products'
], function (
  Module,
  _,
  App,
  Backbone,
  Windows,
  TableView,
  ImageView,
  InfosView,
  SupplierView,
  FiltersView,
  ToolsView,
  HeaderToolsView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'list-view ' + TableView.prototype.className,
    pageSize: 50,
    pageMode: 'pagination',
    filtersViewClass: FiltersView,
    selectable: true,
    multiSelect: false,

    init: function() {
      this.filters = new Backbone.Model({
        code: '',
        name: ''
      });

      this.selectedProducts = new Backbone.Model({products: []});
    },

    setFields: function() {
      var fields = [];

      fields.push({
        name: 'Image',
        className: 'image',
        view: ImageView
      });

      fields.push({
        name: 'Infos',
        label: _.i18n('common.product'),
        className: 'infos',
        view: InfosView
      });

      fields.push({
        name: 'Supplier',
        label: _.i18n('product.supplier'),
        className: 'supplier',
        view: SupplierView
      });

      fields.push({
        name: 'headerTools',
        className: 'header-tools',
        view: ToolsView,
        headerView: HeaderToolsView
      });

      return fields;
    },

    fetch: function(first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.first = first;
      params.pageSize = pageSize;

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url:  App.request('webshop.products.search', params),
        success: _.bind(function (products) {
          var models = [];

          _.each(products, _.bind(function(item) {
            var model = App.request('webshop.product.model', item);
            models.push(model);
          }, this));

          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function(filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('webshop.products.count', params),
        success: _.bind(function (count) {
          defer.resolve(count);
        }, this)
      });

      return defer.promise();
    },

    getFiltersParams: function(filters) {
      var params = {};

      _.each(filters.attributes, function (value, key) {
        if (key !== 'dateFrom' &&
            key !== 'dateTo' &&
            key !== 'defaultCategory') {
          if (value !== '')
            params[key] = value;
        }
      }, this);

      if (filters.get('defaultCategory').id !== '')
        params.categoryId = filters.get('defaultCategory').id;

      return params;
    },

    onRowDoubleClick: function(view) {
      this.selectedProducts.set('products', [view.model]);

      this.onConfirm();
    },

    onSelected: function(rows) {
      var models = [];
      _.each(rows, function(row) {
        models.push(row.model);
      });
      this.selectedProducts.set('products', models);
    },

    onConfirm: function() {
      if(this.options.onSelected)
        this.options.onSelected(this.selectedProducts.get('products'));
    }
  });
});
