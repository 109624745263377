define('ordersBoardHeaderView',[
  'app',
  'module',
  'jquery',
  'backbone',
  'marionette',
  'underscore',
  'orderUtils',
  'buttonView',
  'template!ordersBoardHeaderView'
], function (
  App,
  Module,
  $,
  Backbone,
  Marionette,
  _,
  OrderUtils,
  ButtonView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'orders-board-kanban-header-view',

    regions: {
      addRegion: '.add-region',
      clearAllSortRegion: '.clear-all-sort-region',
      unselectAllRegion: '.unselect-all-region'
    },

    ui: {
      kanbanContainer: '.kanban-container',
      moveLeft: '.js-move-left',
      moveLeft2: '.js-move-left-2',
      moveRight: '.js-move-right',
      moveRight2: '.js-move-right-2',
      moveBegin: '.js-move-begin',
      moveEnd: '.js-move-end'
    },

    events: {
      'click @ui.moveLeft': 'onScrollLeft',
      'click @ui.moveLeft2': 'onScrollLeft2',
      'click @ui.moveRight': 'onScrollRight',
      'click @ui.moveRight2': 'onScrollRight2',
      'click @ui.moveBegin': 'onScrollBegin',
      'click @ui.moveEnd': 'onScrollEnd'
    },

    onShow: function () {
      var title = _.i18n('orders.createNew');
      if(this.options.type === 'offer')
        title = _.i18n('offers.new');

      this.addButtonView = new ButtonView({
        type: 'add',
        color: 'orange',
        text: title,
        title: title
      });
      this.addRegion.show(this.addButtonView);
      this.addButtonView.on('click', _.bind(this.onAdd, this));

      //-------------------
      this.clearAllSortView = new ButtonView({
        type: 'link',
        text: _.i18n('orders.board.clear-all-sort'),
        title: _.i18n('orders.board.clear-all-sort.title')
      });
      this.clearAllSortRegion.show(this.clearAllSortView);
      this.clearAllSortView.on('click', _.bind(this.onClearAllSort, this));

      //-------------------
      this.unselectAllView = new ButtonView({
        type: 'link',
        text: _.i18n('orders.board.unselect-all'),
        title: _.i18n('orders.board.unselect-all.title')
      });
      this.unselectAllRegion.show(this.unselectAllView);
      this.unselectAllView.on('click', _.bind(this.onUnselectAll, this));
      this.renderUnSelectButton([]);
    },


    renderUnSelectButton: function (selectedOrders) {
      if(selectedOrders.length > 0)
        this.unselectAllView.enabled(true);
      else
        this.unselectAllView.enabled(false);
    },

    renderUnSortButton: function (sortedViews) {
      if(sortedViews.length > 0)
        this.clearAllSortView.enabled(true);
      else
        this.clearAllSortView.enabled(false);
    },

    onAdd: function () {
      var defer = $.Deferred();
      OrderUtils.openEndUserDialog(defer, this.options.type);
      return defer.promise();
    },

    onClearAllSort: function () {
      this.options.boardView.kanbanView.clearAllSort();
    },

    onUnselectAll: function () {
      this.options.boardView.kanbanView.unSelectAll();
      this.renderUnSelectButton(this.options.boardView.kanbanView.getSelectedOrders());
    },

    onScrollLeft: function (e) {
      e.preventDefault();
      this.options.boardView.kanbanView.$el.animate({scrollLeft: '-=310'}, 300);
    },

    onScrollLeft2: function (e) {
      e.preventDefault();
      this.options.boardView.kanbanView.$el.animate({scrollLeft: '-=620'}, 300);
    },

    onScrollRight: function (e) {
      e.preventDefault();
      this.options.boardView.kanbanView.$el.animate({scrollLeft: '+=310'}, 300);
    },

    onScrollRight2: function (e) {
      e.preventDefault();
      this.options.boardView.kanbanView.$el.animate({scrollLeft: '+=620'}, 300);
    },

    onScrollBegin: function (e) {
      e.preventDefault();
      this.options.boardView.kanbanView.$el.animate({scrollLeft: '0'}, 300);
    },

    onScrollEnd: function (e) {
      e.preventDefault();
      var iWidth = this.options.boardView.kanbanView.collection.length * 310;
      this.options.boardView.kanbanView.$el.animate({scrollLeft: iWidth}, 300);
    }
  });
});
  
